.topsearch {
  background-color: #FFFFFF;
  padding: 15px 0 5px;
  margin-bottom: 5px;
  display: inline-block;
  border: 1px solid #949494;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  width: 100%;

  .init {
    width: 100%;
    display: inline-block;
    padding: 0 15px 5px 20px;

    .search_text {
      display: inline;
      font-size: 25px;
      line-height: 35px;

      .bold {
        font-weight: 400;
      }
    }

    .buttons {
      position: relative;
      display: inline-block;
      float: right;
    }
  }

  .elements {
    display: none;
  }
}
