.page_step_3_air {
    padding: 0px;
    margin-top: -15px;
  }
  .yellow-title {
    float: left;
    width: 100%;
    border-radius: 3px;
    background: #efcb01;
    line-height: normal;
    padding: 10px 0;
    font-size: 30px;
    margin: 0 0 10px 0;
  }




  .page_step_3_air .blue-title {

    width: 100%;
    margin: 20px auto 10px;
    padding: 10px 0 15px 0;
    float: none;
  }
  .blue-title {
    color: #FFF;
    background: #2311b2;
    float: left;
    width: 100%;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    line-height: normal;
    font-size: 30px;
    margin: 0 0 10px 0;
    padding: 10px 0 15px 0;
  }
  .section_dark {
    opacity: 0.1;
    filter: alpha(opacity=10);
  }
  .upgrade_div {
    border: 1px dashed #1E23B0;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    clear: left;
    background-color: #ffffff;
    width: 100%;
    display: table;
    margin-left: auto;
    margin-right: auto;
  }
  .without_upgrade {
    width: 40%;
    float: left;
    height: 100%;
    padding: 30px;
  }
  .without_upgrade .header {
    font: 22px "Helvetica Neue", Arial, Helvetica, Geneva, sans-serif;
    padding-bottom: 25px;
  }
  .without_upgrade ul li {
    padding-left: 25px;
    font: 15px "Helvetica Neue", Arial, Helvetica, Geneva, sans-serif;
    line-height: 27px;
  }
  .without_upgrade .price {
    font-weight: bold;
    margin-top: 109px;
    margin-bottom: 25px;
    font: 29px "Helvetica Neue", Arial, Helvetica, Geneva, sans-serif;
  }
  .without_upgrade .price .smalltext {
    font-size: 14px;
    padding-left: 12px;
  }
  .without_upgrade .button {
    margin-top: 35px;
  }
  .without_upgrade .button a {
    color: #000000;
    text-decoration: underline;
  }
  .with_upgrade {
    padding: 30px;
    height: 100%;
    border-left: 1px dashed #1E23B0;
    background-color: #e7f0e8;
    float: right;
    width: 60%;
  }
  .with_upgrade .spot {
    background-color: #ffffff;
    color: #16b501;
    font-family: "Helvetica Neue", Arial, Helvetica, Geneva, sans-serif;
    min-width: 150px;
    max-width: 200px;
    padding: 5px 20px;
    text-align: center;
    float: right;
    border: 1px solid #c4c4c4;
    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
    border-radius: 15px;
    opacity: 1;
    font-weight: bold;
  }
  .with_upgrade .header {
    font: bold 22px "Helvetica Neue", Arial, Helvetica, Geneva, sans-serif;
    padding-bottom: 25px;
    color: #1E23B0;
  }
  .with_upgrade ul li {
    padding-left: 28px;
    font: 15px "Helvetica Neue", Arial, Helvetica, Geneva, sans-serif;
    line-height: 27px;
  }
  .with_upgrade .price {
    font-weight: bold;
    margin-top: 25px;
    margin-bottom: 25px;
    font: 30px "Helvetica Neue", Arial, Helvetica, Geneva, sans-serif;
  }
  .with_upgrade .price .smalltext {
    font-size: 14px;
    padding-left: 12px;
  }
  .with_upgrade .button {
    padding: 0 15px;
    background: #16b501;
    border-radius: 3px;
    cursor: pointer;
    display: inline-block;
    border: 1px solid #129e00;
  }
  .with_upgrade .button:hover {
    background-color: #108900;
  }
  .with_upgrade .button a {
    float: left;
    height: 40px;
    line-height: 40px;
    font-size: 18px;
    font-weight: 400;
    color: #FFFFFF;
  }
  .with_upgrade_choosen {
    border: 1px dashed #1E23B0;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    clear: left;
    width: 100%;
    display: table;
    margin-left: auto;
    margin-right: auto;
    padding: 20px 30px 20px 200px;
    -moz-box-shadow: 0 0 1px;
    box-shadow: 0 0 1px;
  }
  .with_upgrade_choosen .section {
    float: left;
  }
  .with_upgrade_choosen .header {
    font: bold 22px "Helvetica Neue", Arial, Helvetica, Geneva, sans-serif;
    padding-bottom: 5px;
  }
  .with_upgrade_choosen .body {
    font: 14px "Helvetica Neue", Arial, Helvetica, Geneva, sans-serif;
  }
  .with_upgrade_choosen #comment {
    height: 70px;
    border: 1px solid #898989;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    background-color: #fcf8e6;
  }
  .with_upgrade_choosen .button {
    float: right;
  }
  .with_upgrade_choosen .button a {
    font-size: 14px;
    font-weight: 400;
    color: #000000;
    text-decoration: underline;
  }
  .without_upgrade_choosen {
    background: url('../general/desktop/images/site/bad_big.png') 20px no-repeat #ffffff;
    border: 1px dashed #1E23B0;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    clear: left;
    width: 100%;
    display: table;
    margin-left: auto;
    margin-right: auto;
    padding: 20px 30px 20px 140px;
    -moz-box-shadow: 0 0 1px;
    box-shadow: 0 0 1px;
    height: 120px;
  }
  .without_upgrade_choosen .section {
    float: left;
  }
  .without_upgrade_choosen .header {
    font: bold 22px "Helvetica Neue", Arial, Helvetica, Geneva, sans-serif;
    padding-bottom: 5px;
    color: #EC6769;
  }
  .without_upgrade_choosen .body {
    font: 14px "Helvetica Neue", Arial, Helvetica, Geneva, sans-serif;
    width: 520px;
  }
  .without_upgrade_choosen .button {
    float: right;
    padding: 0 15px;
    background: #16b501;
    border-radius: 3px;
    cursor: pointer;
    display: inline-block;
    border: 1px solid #129e00;
  }
  .without_upgrade_choosen .button:hover {
    background-color: #108900;
  }
  .without_upgrade_choosen .button a {
    height: 40px;
    line-height: 40px;
    font-size: 18px;
    font-weight: 400;
    color: #FFFFFF;
  }
  .step-3-auto {
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 20px;
    display: table;
    clear: left;
  }
  .step_3_buttons {
    padding-top: 20px;
    display: table;
    float: right;
  }
  #comment {
    margin-top: 10px;
    width: 100%;
    height: 100px;
    font-size: 18px;
    border: 1px solid #1E23B0;
    border-radius: 3px;
    font-family: 'Roboto Condensed', sans-serif;
    font-weight: 300;
    padding: 10px;
    color: #1E23B0;
  }
  #comment:hover {
    background-color: #fdf5ce !important
  }
  .eservice-wrap {
    float: left;
    width: 100%;
    margin: 0 0 10px 0;
  }
  .eservice-wrap .action-section {
    float: left;
    padding: 5px 10px;
    margin: 20px 0px 0px 20px;
    font-size: 16px;
    border-radius: 12px;

    &.success {
      color: #fff;
      background-color: #5cb85c;
      border-color: #4cae4c;
    }
    &.info {
      color: #fff;
      background-color: #5bc0de;
      border-color: #46b8da;
    }
    &.warning {
      color: #fff;
      background-color: #f0ad4e;
      border-color: #eea236;
    }
    &.danger {
      color: #fff;
      background-color: #d9534f;
      border-color: #d43f3a;
    }
  }
  ul.eservice {
    margin: 0;
    padding: 15px 0 0 0;
    float: left;
    width: 100%;
    text-align: center;
    font-size: 0;
  }
  ul.eservice .top {
    float: left;
  }
  ul.eservice li {
    vertical-align: top;
    padding: 1%;
    width: 100%;
    background: #FFF;
    border-radius: 3px;
    border: 1px solid #949494;
    margin-bottom: 10px;
    margin-right: auto;
    margin-left: auto;
    display: table;
  }
  ul.eservice li.basicaddonslight {
    opacity: 0.4
  }
  ul.eservice li div.title {
    font-size: 24px;
    color: #000;
    font-weight: 400;
    float: left;
    width: 100%;
    margin: 0 0 5px 0;
  }
  ul.eservice li div.title img {
    float: left;
  }
  ul.eservice li div.title span {
    float: left;
    padding: 5px 0 0 10px;
    line-height: 24px;
    text-align: left;
  }
  ul.eservice li div.no_icon span {
    padding-left: 0px;
  }
  ul.eservice li p {
    font-size: 18px;
    line-height: normal;
    text-align: left;
  }
  ul.eservice li div.price {
    font-size: 29px;
    color: #000;
    float: right;
    font-weight: 400;
    line-height: normal;
  }
  ul.eservice li div.price span.smalltext {
    font-size: 14px;
    padding-left: 3px;
  }
  ul.eservice li div.radio-section {
    float: right;
    font-size: 18px;
    line-height: 40px;
  }
  ul.eservice li div.radio-section label {
    float: left;
    padding-left: 20px;
  }
  ul.eservice li div.radio-section span {
    padding: 0 0 0 5px;
    float: right;
  }
  ul.eservice li div.readmore {
    clear: left;
    float: left;
    padding: 0 10px;
    border-radius: 3px;
    margin: 15px 0 0 0;
  }
  ul.eservice li div.readmore a {
    color: #FFF;
    font-size: 18px;
    padding: 0 40px 0 0px;
    line-height: 40px;
  }
  ul.eservice li div.personal-number-section {
    clear: left;
    font-size: 20px;
    color: #000;
    text-align:  left;
    padding-top: 20px;
  }
  div.personal-number-section div.header {
    font-weight: bold;
  }
  div.personal-number-section div.description {
    padding-top: 10px;
  }
  div.personal-number-section div.personal_numbers {
    padding-top: 20px;
  }
  div.personal-number-section div.personal_number {
    padding-top: 10px;
  }
  div.personal_number div.passenger_name {
    float: left;
    padding-top: 8px;
  }
  div.personal_number div.personal_number_input {
    margin-left: 150px;
  }
  div.personal_number div.personal_number_input input {
    border: 1px solid #a1a1a1;
    font-size: 16px;
    padding: 5px;
  }
  ul.eservice li div.address-section {
    clear: left;
    font-size: 20px;
    color: #000;
    text-align:  left;
    padding-top: 20px;
  }
  div.address-section div.header {
    font-weight: bold;
  }
  div.address-section div.address_block {
    padding-top: 10px;
  }
  div.address-section div.passenger_address {
    float: left;
    padding-top: 8px;
  }
  div.address-section div.passenger_zip_code {
    float: left;
    padding-top: 8px;
  }
  div.address-section div.passenger_city {
    float: left;
    padding-top: 8px;
  }
  div.address-section div.passenger_country_code {
    float: left;
    padding-top: 8px;
  }
  div.address_block div.address_input input{
    border: 1px solid #a1a1a1;
    font-size: 16px;
    padding: 5px;
    margin: 5px;
  }
  div.address_block div.postal_code_input input {
    border: 1px solid #a1a1a1;
    font-size: 16px;
    padding: 5px;
    margin: 5px;
  }
  div.address_block div.city_input input{
    border: 1px solid #a1a1a1;
    font-size: 16px;
    padding: 5px;
    margin: 5px;
  }
  div.address_block div.country_code_input input {
    border: 1px solid #a1a1a1;
    font-size: 16px;
    padding: 5px;
    margin: 5px;
  }

  ul.eservice li div.checkin-section {
    clear: left;
    font-size: 20px;
    color: #000;
    text-align:  left;
    padding-top: 20px;
  }
  div.checkin-section div.header {
    font-weight: bold;
  }
  div.checkin-section div.description {
    padding-top: 10px;
  }

  div.checkin-section div.footer {
    padding-top: 10px;
    font-size: 14px;
  }

  div.checkin-section div.checkin {
    padding-top: 20px;
  }
  div.checkin-section div.checkin {
    padding-top: 10px;
  }
  div.checkin div.passenger_name {
    float: left;
    padding-top: 8px;
  }
  div.checkin div.checkin_input {
  }
  div.checkin div.checkin_input input {
    border: 1px solid #a1a1a1;
    font-size: 16px;
    padding: 5px;
  }
  #checkin_info {
    height: 70px;
    border: 1px solid #898989;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    background-color: #fcf8e6;
    width: 100%;
    margin-top: 10px;
    font-family: 'Roboto Condensed', sans-serif;
    font-weight: 300;
    color: #1E23B0;
    font-size: 18px;
    padding: 10px;
  }

  #checkin_info:hover {
    background-color: #fdf5ce !important
  }

  .custom-radio-addon {
    width: 40px;
    height: 40px;
    display: inline-block;
    position: relative;
    z-index: 1;
    /*top: 25px;*/

    // float: left;
    margin: 0 0 0 5px;
    cursor: pointer;
  }
  .custom-radio-addon input[type="radio"] {
    margin: 1px;
    position: absolute;
    z-index: 2;
    cursor: pointer;
    outline: none;
    opacity: 0;
    /* CSS hacks for older browsers */

    _noFocusLine: expression(this.hideFocus=true);
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
    -khtml-opacity: 0;
    -moz-opacity: 0;
  }
  .service-popup {
    font-size: 18px;
    line-height: normal;
  }
  .service-popup .title {
    font-size: 24px;
    font-weight: 400;
  }
  .service-popup .title_blue {
    font-size: 24px;
    font-weight: 400;
  }
  .service-popup .hr {
    margin: 10px 0;
  }
  .service-popup .title-new {
    font-size: 24px;
  }
  .service-popup ul {
    margin: 20px 0 0 0;
  }
  .service-popup .buttons a {
    float: right;
    border-radius: 3px;
    height: 40px;
    line-height: 40px;
    font-size: 18px;
    font-weight: 400;
    background: #000;
    padding: 0 15px;
    color: #FFF;
    border: 1px solid #0f8c32;
    margin: 0 0 0 20px;
    text-decoration: none;
  }
  .service-popup a {
    text-decoration: underline;
    color: blue;
  }
  .page_step_3_air .y-button {
    margin: 0 0 0 20px;
  }
  .tooltip {
    display: none;
    position: absolute;
    border: 1px solid #333;
    background-color: #161616;
    border-radius: 3px;
    padding: 5px;
    color: #fff;
    max-width: 200px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 11px;
    line-height: normal;
  }
  .page_step_3_air .y-button {
    margin: 0 0 0 20px;
  }

  .service-popup .buttons a {
    border: none;
    background: #cecece;
  }
  .addon-button-yes {
    width: 200px;
    text-align: center;
    background: #0cbb3e !important;
  }

  $dark: #292929;
  $light: #efefef;
  $red: #D91E18;
  $green: #2ECC71;
  $blue: #4183D7;
  $small: 40em;

  .awesome {
    display: none;

    &.service-item-button-yes:checked + .service-item-radiobutton {
      background: $green;
      color: $light;
      &:hover {
        background: darken($green, 5%);
        color: darken($light, 5%);
      }
    }

    &.service-item-button-no:checked + .service-item-radiobutton {
      background: $red;
      color: $light;
      &:hover {
        background: darken($red, 5%);
        color: darken($light, 5%);
      }
    }
  }

  .service-item-radiobutton {
    display: inline-block;
    padding: 1em 2em;
    margin: 0.5em;
    cursor: pointer;
    color: $dark;
    border-radius: 0.25em;
    background: $light;
    transition: 0.3s;
    user-select: none;

    &:hover {
      background: darken($light, 10%);
      color: darken($dark, 10%);
    }
    &:active {
      transform: translateY(2px);
    }
  }


  //Custom section

  .yellow-title {
    color: #2311b2;
  }
  .without_upgrade ul li {
    background: url('../general/desktop/images/site/bad.png') 0 5px no-repeat;
  }
  .with_upgrade ul li {
    background: url('../general/desktop/images/site/good.png') 0 5px no-repeat;
  }
  .with_upgrade_choosen {
    background: url('../general/desktop/images/site/good_big.png') 20px no-repeat #ffffff;
  }
  .with_upgrade_choosen .header {
    color: #1E23B0;
  }
  ul.eservice li p {
    color: #2311b2;
  }
  ul.eservice li div.radio-section {
    color: #2311b2;
  }
  ul.eservice li div.readmore {
    background: #2311b2;
  }
  ul.eservice li div.readmore a {
    background: url('../general/desktop/images/site/eye-icon.png') no-repeat right center;
  }
  .custom-radio-addon {
    background: url('../general/desktop/images/site/radio2.png') no-repeat;
  }
  .custom-radio-addon:hover {
    background: url('../general/desktop/images/site/radio1-hover.png') no-repeat;
  }
  .custom-radio-addon.selected {
    background: url('../general/desktop/images/site/radio1-selected.png') no-repeat;
  }
  .service-popup .title {
    color: #2311b2;
  }
  .service-popup .title_blue {
    color: #2311b2;
  }
  .service-popup .hr {
    border-color: #2311b2;
  }

#failed_job_button {
  color: $color7;
  background-color: $color1;
  padding: 0 35px 0 10px;
  height: 38px;
  border: 1px solid $color7;
  width: 100% !important;
  font-family: 'Roboto Condensed', sans-serif;
  font-size: 18px;
  font-weight: 300;
  line-height: 38px;
  overflow: hidden;
}
