.top-box {
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    border: 1px solid #949494;
    background-color: #ffffff;
    width: 100%;
    margin-bottom: 10px;
    height: 100px;
    padding: 20px;
    display: table;
  }
  .top-box div {
    float: left;
    width: 240px;
  }
  .top-box .header {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 12px;
  }
  .top-box .subheader {
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 5px;
  }
  .spacer {
    clear: both;
    margin-top: 20px;
  }
  .booking-product + .booking-product {
    margin-top: 10px;
  }
  
  .booking-product .header {
    float: left;
    font-size: 18px;
    font-weight: bold;
    padding-bottom: 10px;
  }
  .booking-product .payment-status {
    float: right;
    font-size: 18px;
    font-weight: bold;
  }
  .booking-product .payment-status a.not-paid {
    text-decoration: underline;
  }
  .booking-product .payment-status .not-paid {
    color: #ed1d1d;
  }
  .booking-product .payment-status .paid {
    color: #1cc13b
  }
  .payment_success_str {
    width: 100%;
    display: inline-block;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    border: 1px solid #6c6c6c;
    margin-bottom: 10px;
  }
  .upgrade-to-gold {
    height: 100%;
    background-color: #e7f0e8;
    margin-bottom: 10px;
    margin-top: 0;
    display: inline-block;
    padding: 15px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    border: 1px solid #6c6c6c;
  }
  .upgrade-to-gold .header {
    font: bold 22px "Helvetica Neue", Arial, Helvetica, Geneva, sans-serif;
    padding-bottom: 10px;
    color: #1E23B0;
  }
  .upgrade-to-gold ul li {
    padding-left: 28px;
    font: 15px "Helvetica Neue", Arial, Helvetica, Geneva, sans-serif;
    line-height: 27px;
    width: 50%;
    float: left;
    background: url('../general/desktop/images/site/good.png') 0 5px no-repeat;
  }
  .upgrade-to-gold .price {
    font-weight: bold;
    font: 30px "Helvetica Neue", Arial, Helvetica, Geneva, sans-serif;
    float: left;
    margin-top: 17px;
    margin-left: 25px;
  }
  .upgrade-to-gold .price .smalltext {
    font-size: 14px;
    padding-left: 12px;
  }
  .upgrade-to-gold .upgrade_yes {
    clear: left;
  }
  .upgrade-to-gold .button {
    padding: 0 15px;
    background: #16b501;
    border-radius: 3px;
    cursor: pointer;
    border: 1px solid #129e00;
    float: left;
    margin-top: 15px;
  }
  .upgrade-to-gold .button:hover {
    background-color: #108900;
  }
  .upgrade-to-gold .button a {
    float: left;
    height: 40px;
    line-height: 40px;
    font-size: 18px;
    font-weight: 400;
    color: #FFFFFF;
  }
  .hotel-widget {
    background-color: #ffffff;
    margin-bottom: 10px;
    padding: 15px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    border: 1px solid #6c6c6c;
    display: inline-block;
    width: 100%;
  }
  .hotel-widget .banner {
    float: left;
    width: 165px;
  }
  .hotel-widget .banner img {
    width: 165px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    margin-left: 3px;
  }
  .hotel-widget .banner .dates {
    margin-top: 20px;
    text-align: center;
    font-weight: bold;
  }
  .hotel-widget .banner .hotel_count {
    font-size: 25px;
    text-align: center;
    margin-top: 30px;
  }
  .hotel-widget .banner .hotel_count span {
    font-size: 40px;
  }
  .hotel-widget .banner .see_all {
    text-align: center;
    margin-top: 29px;
  }
  .hotel-widget .banner .see_all a {
    color: #000000;
    text-decoration: underline;
    font-size: 100%;
  }
  .hotel-widget .recommended_hotels_list {
    float: right;
    padding-left: 12px;
  }
  .hotel-widget .hotel_img {
    border-radius: 3px 3px 0 0;
    position: relative;
    overflow: hidden;
    height: 90px;
  }
  .hotel-widget .hotel_review_score {
    position: absolute;
    bottom: 10px;
    right: 10px;
    color: #fff;
    padding: 4px 4px 3px 4px;
    border-radius: 3px;
    z-index: 9;
    text-align: center;
    font-size: 16px;
    display: block;
    min-width: 43px;
    text-decoration: none;
    width: 75px;
    line-height: 14px;
  }
  .hotel-widget .hotel_review_score {
    background: #1E23B0;
  }
  .hotel-widget .hotel_review_score_word {
    font-size: 12px;
  }
  .hotel-widget .hotel_list_hotel_name {
    color: #000000 !important;
    font-size: 90%;
    display: block;
    height: 29px;
    line-height: 1.1em;
    float: left;
  }
  .hotel-widget .hotel_list_hotel_name h2 {
    float: left;
    font-size: 85%;
    color: #000000;
    max-width: 190px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  
.hotel_info .image_wrap .image {
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    border: 1px solid #949494;
    margin-bottom: 12px;
  }
  
  .hotel_info .image_wrap .image:nth-child(odd) {
    float: left
  }
  
  .hotel_info .image_wrap .image:nth-child(even) {
    float: right
  }
  .hotel_result .image_wrap {
    padding: 10px;
    float: left;
    width: 270px
  }
  .hotel_result_gallery .image_wrap {
    padding: 5px 0px 5px 10px;
    float: left
  }

  .hotel-widget .hotel_info {
    margin-top: 0;
    margin-bottom: 0;
    width: 96%;
    height: 50px;
  }
  .hotel-widget .hotel_info_img {
    float: left;
    margin-top: 8px;
    margin-left: 5px;
  }
  .hotel-widget div.hotel_price_section {
    clear: left;
  }
  .hotel-widget div.hotel_room_type {
    font-size: 12px;
    float: left;
  }
  .hotel-widget .max_occ_icon {
    background-image: url('//q.bstatic.com/static/affiliate_base/img/aff_hotel_list_max_occ/3e5bac6eefc5e7f7a7d50d1b6ec6210c7bd26a5c.gif');
    background-repeat: no-repeat;
    display: inline-block;
    height: 15px;
    width: 22px;
    margin-left: 3px;
  }
  .hotel-widget .max_occ_type_single {
    background-position: 0 5px
  }
  .hotel-widget .max_occ_type_double {
    background-position: 0 -16px
  }
  .hotel-widget .max_occ_type_triple {
    background-position: 0 -35px
  }
  .hotel-widget div.hotel_price_from {
    font-size: 18px;
    float: left;
    padding-top: 1px;
    padding-left: 15px;
  }
  .hotel-widget a.b_submitButton.book_btn_position {
    color: #000000;
    text-decoration: underline;
    float: right;
    margin-top: 1px;
  }
  .car-widget {
    background-color: #ffffff;
    margin-bottom: 10px;
    padding: 15px 15px 5px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    border: 1px solid #6c6c6c;
    display: inline-block;
    width: 100%;
  }
  .car-widget .wrapper {
    width: 33%;
    float: left;
    margin-left: 4px;
  }
  .car-widget .wrapper img.carimage {
    float: left;
    margin-right: 10px;
    width: 150px;
  }
  .car-widget .wrapper .make {
    font-weight: bold;
  }
  .car-widget .wrapper .type {
    font-size: 11px;
  }
  .car-widget .wrapper .icons > div {
    display: inline-block;
    padding: 0 2px 0 22px;
    line-height: 20px;
    margin: 2px 0 0 0;
    -o-text-overflow: ellipsis;
    -ms-text-overflow: ellipsis;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    min-width: 12%;
    background-repeat: no-repeat;
    cursor: default;
    font-size: 10px;
    font-weight: normal;
  }
  .car-widget .wrapper .seats {
    background: url(https://secure.rentalcars.com/widget/affiliate_rc/public/images/widgetGlyphs.png) left -63px no-repeat;
  }
  .car-widget .wrapper .doors {
    background: url(https://secure.rentalcars.com/widget/affiliate_rc/public/images/widgetGlyphs.png) left -129px no-repeat;
  }
  .car-widget .wrapper .aircon {
    background: url(https://secure.rentalcars.com/widget/affiliate_rc/public/images/widgetGlyphs.png) left 2px no-repeat;
  }
  .car-widget .wrapper .transmission {
    background: url(https://secure.rentalcars.com/widget/affiliate_rc/public/images/widgetGlyphs.png) left -192px no-repeat;
  }
  .car-widget .wrapper .was {
    color: #ee301a;
    margin-top: 7px;
    font-size: 12px;
    text-decoration: line-through;
    float: left;
  }
  .car-widget .wrapper .now, .now2 {
    color: inherit;
    font-size: 18px;
    float: left;
    margin-left: 18px;
    margin-top: 7px;
  }
  .car-widget .wrapper .searchNowButton {
    text-decoration: underline;
    cursor: pointer;
    margin-top: 7px;
    float: right;
    margin-right: 33px;
  }
  