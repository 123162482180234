// BEGIN ui-slider

.ui-slider {
  height: 12px;
  position: relative;
  text-align: left;
  border-radius: 10px;
  width: 93%;
  margin-left: 3px;
}

.ui-slider-handle {
  cursor: pointer;
  width: 20px;
  height: 20px;
  position: absolute;
  z-index: 2;
  border-radius: 25px;
  top: -4px;
  margin-left: -5px;
}

.ui-slider-range {
  position: absolute;
  border: 0;
  top: 0;
  height: 100%;
  border-radius: 25px;
  width: 100%;
}

// END ui-slider
// BEGIN Switch

.Switch {
  position: relative;
  display: inline-block;
  font-size: 11px;
  font-weight: bold;
  height: 21px;
  border-radius: 3px;
  cursor: pointer;
  padding: 2px 3px 3px;
}

.Switch span {
  display: inline-block;
  width: 17px;
}

.Switch .Toggle {
  position: absolute;
  top: 2px;
  width: 20px;
  height: 17px;
  border-radius: 3px;
  z-index: 8;
  -webkit-transition: all 0.15s ease-in-out;
  -moz-transition: all 0.15s ease-in-out;
  -o-transition: all 0.15s ease-in-out;
  -ms-transition: all 0.15s ease-in-out;
}

.Switch.On .Toggle {
  left: 50%;
}

.Switch.Off .Toggle {
  left: 4%;
}

// END Switch
// BEGIN rangeslider

.rangeslider {
  border-radius: 0 0 3px 3px !important;
}

.rangeslider ul li {
  width: 100%;
  float: left;
  margin-bottom: 15px;
}

.rangeslider .caption {
  margin-bottom: 30px;
}

.rangeslider label {
  font-size: 14px;
  padding-right: 4px;
}

.rangeslider input:hover[type="text"],
.rangeslider input:focus[type="text"] {
  background: none !important;
}

// END rangeslider
