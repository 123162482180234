.red-title {
  color: #000;
  background: white;

  border: 4px dashed #b31111;

  float: left;
  width: 100%;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  line-height: normal;
  font-size: 30px;
  margin: 0 0 10px 0;
  padding: 10px 0 15px 0;
}

.red-title > .pdL20 > p {
  font-size: 18px;
}

.payment-wrap > ul > li {
  color: #2311b2;
}

.payment {
  background: #2311b2;
}
