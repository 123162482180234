// TODO: Is this even used?

.top_gate_access .main {
  width: 85%;
  margin-top: 100px;
  margin-left: auto;
  margin-right: auto;
  float: none;
}

.login-field {
  width: 400px;
  font-size: 20px;
  padding: 20px;
  margin-right: 20px;
}

#login-submit:hover {
  background-color: #444445 !important;
  color: #fff;
}

#login-submit {
  width: 225px;
  border: 1px solid #444445;
  padding-right: 0 !important;
  height: 64px !important;
  float: right;
}

.login_error {
  padding-top: 20px;
  color: #FE3C33;
  font-weight: 400;
  font-size: 16px;
}
