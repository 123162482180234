.offer_hotel_addon {
  width: 100%;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  background: #FFF;
  border: 1px solid #949494;
  min-height: 62px;
  float: left;
  padding: 10px;
  margin-bottom: 10px;
}

.offer_hotel_addon .body {
  float: left;
  font-size: 24px;
  line-height: 24px;
  padding: 12px 0;
  width: 85%;
}

.offer_hotel_addon .y-button {
  float: right;
  border: 1px solid #949494;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}

.hotel_info {
  width: 800px;
  font-family: "Helvetica Neue", Arial, Helvetica, Geneva, sans-serif;
  padding-right: 5px;
  padding-left: 5px;
}

.hotel_info .name_group {
  float: left;
  margin-bottom: 15px;
  width: 475px;
}

.hotel_info .name {
  float: left;
  padding-right: 10px;
  padding-top: 2px;
  font-weight: bold;
  font-size: 24px;
  padding-bottom: 5px;
}

.hotel_info .address {
  clear: left;
}

.hotel_info #map-canvas {
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  border: 1px solid #949494;
  float: right;
  margin: 10px 0 15px 15px;
}

.hotel_info a {
  color: #000000;
}

.hotel_info a:hover {
  text-decoration: underline;
}

.hotel_info .top {
  display: inline-block;
}

.hotel_info .description {
  text-align: justify;
  font-size: 12px;
}

.hotel_info .facilities {
  margin-top: 10px;
  margin-left: 20px;
}

.hotel_info .facilities ul {
  list-style-type: square;
}

.hotel_info .facilities li {
  float: left;
  padding-bottom: 6px;
  font-size: 14px;
  width: 33%;
}

.hotel_info .image_wrap .image {
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  border: 1px solid #949494;
  margin-bottom: 12px;
}

.hotel_info .image_wrap .image:nth-child(odd) {
  float: left;
}

.hotel_info .image_wrap .image:nth-child(even) {
  float: right;
}

.hotel_info hr {
  clear: left;
  margin-bottom: 10px;
}

.hotel_result .rooms {
  clear: right;
}

.hotel_result .dates {
  font-size: 14px;
  padding-top: 10px;
  font-weight: 400;
}

.hotel_result .description {
  margin-top: 5px;
  display: inline-block;
}

.hotel_result .description a {
  color: #241EAF;
}

.hotel_result .description a:hover {
  text-decoration: underline;
}

.hotel_result .price-box-hotel {
  float: right;
  padding-top: 8px;
}

.hotel_result .resurs {
  float: left;
  margin-top: -3px;
  padding-right: 15px;
  a {
    color: black;
    text-decoration: underline;
  }
  .resurs_text {
    text-align: left;
    font-size: 15px;
    font-weight: 200;
  }
  .resurs_price {
    font-size: 20px;
  }
}

.hotel_result {
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  background: #FFF;
  font-size: 16px;
  color: #000;
  margin: 0 0 15px;
  border: 1px solid #949494;
  clear: left;
  width: 100%;
  height: 225px;
  float: left;
  display: table;
  padding-bottom: 3px;
}

.hotel_result .image_wrap {
  padding: 10px;
  float: left;
  width: 270px;
}

.hotel_result .image {
  overflow: hidden;
  float: left;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  border: 1px solid #949494;
  width: 250px;
  height: 200px;
}

.hotel_result .other_images {
  clear: left;
  width: 256px;
}

.hotel_result .small_image {
  overflow: hidden;
  width: 63px;
  height: 40px;
  float: left;
  padding-top: 3px;
}

.hotel_result .information {
  padding: 10px;
  margin-left: 260px;
}

.hotel_result .name {
  font: 22px "Helvetica Neue", Arial, Helvetica, Geneva, sans-serif;
  color: #241EAF;
  float: left;
  padding-right: 8px;
  padding-bottom: 0px;
  max-width: 340px;
  cursor: pointer;
}

.hotel_result .address {
  cursor: pointer;
  clear: left;
  font-size: 12px;
  padding-top: 5px;
}

.hotel_result .address a {
  color: #000000;
}

.hotel_result .address a:hover {
  text-decoration: underline;
}

.hotel_result .address img {
  width: 18px;
  padding-left: 5px;
}

.hotel_result .rating {
  float: left;
}

.hotel_result .name_group {
  float: left;
  max-width: 285px;
}

.hotel_result .hotel_clear {
  clear: right;
}

.hotel_result .tripadvisor {
  float: right;
  padding-left: 30px;
  width: 173px;
  font-size: 14px;
  // cursor: pointer;
}

.hotel_result .price {
  font-size: 30px;
  margin-bottom: 4px;
}

.hotel_result .smallprice {
  font-size: 15px;
  float: right;
}
.hotel_result .rooms .room .headline {
  font-size: 14px;
  font-weight: bold;
  padding-top: 10px;
}

.hotel_result .rooms .room .headline .pax-text {
  font-weight: normal;
  display: inline;
  color: #a0a0a0;
  font-size: 12px;
  padding-left: 5px;
}

.hotel_result .options {
  font-size: 16px;
  line-height: normal;
  overflow: auto;
}

.hotel_result .options ul {
  display: block;
  width: 100%;
}

.hotel_result .options .table {
  display: block;
  width: 100%;
  border: 1px solid #b9b9b9;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  margin-bottom: 5px;
  cursor: pointer;
}

.hotel_result .options .table:last-child {
  margin-bottom: 0;
}

.hotel_result .options .table li {
  display: inline-block;
}

.hotel_result .options .table li.radio {
  width: 3.5%;
  padding: 8px;
}

.hotel_result .options .table li.li_1 {
  width: 46%;
  padding: 8px;
}

.hotel_result .options .table li.li_2 {
  width: 31%;
  padding: 8px;
}

.hotel_result .options .table li.li_3 {
  width: 17%;
  border-right: none;
  padding: 8px;
  text-align: right;
  white-space: nowrap;
}

.hotel_result .selected_hotel {
  background-color: #fcf8e6;
}

.hotel_result_gallery {
  width: 77%;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  background: #FFF;
  font-size: 16px;
  color: #000;
  margin: 0 0 15px;
  border: 1px solid #949494;
  clear: left;
  height: 225px;
  float: left;
  display: table;
  padding-bottom: 3px;
  padding-right: 10px;
}

.hotel_result_gallery .image_wrap {
  padding: 5px 0px 5px 10px;
  float: left;
}

.hotel_result_gallery .image {
  overflow: hidden;
  float: left;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  border: 1px solid #949494;
  margin: 1px;
  width: 235px;
  height: 200px;
}

.hotel_result_gallery .information {
  float: left;
  width: 100%;
  padding: 10px 10px 0;
}

.hotel_result_gallery .name {
  font: 22px "Helvetica Neue", Arial, Helvetica, Geneva, sans-serif;
  color: #241EAF;
  float: left;
  padding-right: 8px;
  padding-bottom: 0px;
  max-width: 340px;
  cursor: pointer;
}

.hotel_result_gallery .address {
  cursor: pointer;
  clear: left;
  font-size: 12px;
  padding-top: 5px;
}

.hotel_result_gallery .address a {
  color: #000000;
}

.hotel_result_gallery .address a:hover {
  text-decoration: underline;
}

.hotel_result_gallery .address img {
  width: 18px;
  padding-left: 5px;
}

.hotel_result_gallery .rating {
  float: left;
}

.hotel_result_gallery .name_group {
  float: left;
  max-width: 340px;
}

.hotel_result_gallery .price-box-hotel {
  float: right;
  padding-top: 8px;
}

.hotel_result_gallery .resurs {
  float: left;
  margin-top: -3px;
}

.hotel_result_gallery .resurs_text {
  font-size: 15px;
}

.hotel_result_gallery .resurs_price {
  padding-right: 30px;
  font-size: 20px;
}

.hotel_result_gallery .price {
  font-size: 30px;
  margin-bottom: 4px;
}

.hotel_result_gallery .smallprice {
  font-size: 15px;
  float: right;
}

.hotel_result_gallery .more_info {
  padding: 10px;
}

.hotel_result_gallery .rooms .room .headline {
  font-size: 14px;
  font-weight: bold;
  padding-top: 10px;
}

.hotel_result_gallery .rooms .room .headline .pax-text {
  font-weight: normal;
  display: inline;
  color: #a0a0a0;
  font-size: 12px;
  padding-left: 5px;
}

.hotel_result_gallery .description {
  clear: left;
}

.hotel_result_gallery .options {
  font-size: 16px;
  line-height: normal;
  overflow: auto;
  padding-top: 10px;
}

.hotel_result_gallery .options ul {
  margin: 0 0 5px;
  display: block;
  width: 100%;
  float: left;
}

.hotel_result_gallery .options li {
  float: left;
}

.hotel_result_gallery .options .table-header {
  background-color: #373737;
  border: 1px solid #696969;
}

.hotel_result_gallery .options .table-header li {
  color: #ffffff;
  padding: 2px 2px 2px 5px;
  font-size: 14px;
  border-right: 1px solid #717171;
}

.hotel_result_gallery .options .table-header li.empty {
  width: 4%
}

.hotel_result_gallery .options .table-header li.li_1 {
  width: 46%;
}

.hotel_result_gallery .options .table-header li.li_2 {
  width: 31%;
}

.hotel_result_gallery .options .table-header li.li_3 {
  width: 18%;
  border-right: none;
}

.hotel_result_gallery .options .table {
  width: 100%;
  border: 1px solid #b9b9b9;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  margin-bottom: 5px;
  cursor: pointer;
}

.hotel_result_gallery .options .table li.radio {
  width: 3.5%;
  padding: 8px;
}

.hotel_result_gallery .options .table li.li_1 {
  width: 46%;
  padding: 8px;
}

.hotel_result_gallery .options .table li.li_2 {
  width: 31%;
  padding: 8px;
}

.hotel_result_gallery .options .table li.li_3 {
  width: 17%;
  border-right: none;
  padding: 8px;
  white-space: nowrap;
}

.hotel_result_gallery .selected_hotel {
  background-color: #fcf8e6;
}


.hotel_info hr {
  clear: left;
  margin-bottom: 10px;
}


.hotel_info .tripadvisor {
  width: 100%;

  .tripadvisor_image {
    float: left;
  }

  .tripadvisor_image .tripadvisor_image_text {
    font-size: 14px;
    display: inline-block;
    float: right;
    padding-top: 2px;
    padding-left: 5px;
  }

  .tripadvisor_logo {
    float: right;
    width: 120px;
  }

  .tripadvisor_logo img {
    width: 100%;
  }

  .tripadvisor_logo .tripadvisor_logo_text {
    font-size: 12px;
  }

  .tripadvisor_ranking {
    clear: left;
    font-weight: bold;
    font-size: 12px;
  }

  .tripadvisor_ratings {
    padding-top: 10px;
    font-size: 14px;
    font-weight: bold;
    float: left;
  }

  .tripadvisor_ratings .ratings {
    display: table;
    padding-top: 5px;
    font-weight: normal;
    font-size: 12px;
  }

  .tripadvisor_ratings .ratings .rating {
    display: table-row;
  }

  .tripadvisor_ratings .ratings .rating_name {
    display: table-cell;
    width: 100px;
  }

  .tripadvisor_ratings .ratings .rating_value {
    display: table-cell;
  }

  .rating_counts {
    display: table;
    padding-top: 35px;
    font-size: 12px;
    float: right;
  }

  .rating_counts .rating_count {
    display: table-row;
  }

  .rating_counts .rating_count .rating_count_name {
    display: table-cell;
    width: 100px;
    float: left;
  }

  .rating_counts .rating_count .rating_box {
    display: table-cell;
  }

  .rating_counts .rating_count .rating_box .rating_box_background {
    border: 1px solid #000000;
    width: 100px;
    height: 15px;
    float: left;
  }

  .rating_counts .rating_count .rating_box .rating_box_fill {
    background-color: #5B9546;
    height: 13px;
  }

  .rating_counts .rating_count .rating_box .rating_box_value {
    float: right;
    width: 35px;
    padding-left: 5px;
    margin-top: -2px;
  }

  .latest_reviews {
    clear: left;
    font-size: 14px;
    font-weight: bold;
    padding-top: 15px;
    display: table;
  }

  .latest_reviews .review {
    font-weight: normal;
    font-size: 12px;
    clear: left;
    padding-bottom: 15px;
  }

  .latest_reviews .review:first-child {
    padding-top: 10px;
  }

  .latest_reviews .review .reviewer {
    float: left;
    width: 150px;
    min-height: 60px;
  }

  .latest_reviews .review .reviewer .reviewer_name {
    font-weight: bold;
    height: 20px;
  }

  .latest_reviews .review .reviewer .reviewer_text {
    font-weight: bold;
  }

  .latest_reviews .review .review_body {
    margin-left: 160px;
  }

  .latest_reviews .review .review_body .review_body_headline {
    font-size: 16px;
    font-weight: bold;
    font-style: italic;
  }

  .latest_reviews .review .review_body .review_body_rating {
    float: left;
    padding-right: 5px;
  }

  .latest_reviews .review .review_body .review_body_rating img {
    width: 50px;
    padding-top: 4px;
  }

  .latest_reviews .review .review_body .review_body_date {
    color: #888888;
  }

  .latest_reviews .review .review_body .review_body_text a {
    text-decoration: underline;
  }

  .tripadvisor_final_links {
    margin-left: 160px;
    font-size: 12px;
    float: left;
  }

  .tripadvisor_final_links a {
    text-decoration: underline;
  }

  .tripadvisor_final_text {
    font-size: 10px;
    color: #888888;
    text-align: right;
  }
}
