.checkbox-compoent {
  width: 20px;
  height: 20px;
  display: inline-block;
  position: relative;
  z-index: 1;
  top: 3px;
  cursor: pointer;
  background: url("./images/form/checkboxsmall.png") no-repeat;
}

.checkbox-compoent:hover {
  background: url("./images/form/checkboxsmall-hover.png") no-repeat;
}

.checkbox-compoent.selected {
  background: url("./images/form/checkboxsmall-selected.png") no-repeat;
}

.checkbox-compoent input[type="checkbox"] {
  margin: 0;
  position: absolute;
  z-index: 2;
  cursor: pointer;
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  -khtml-opacity: 0;
  -moz-opacity: 0;
}

.checkbox-compoent input[type="checkbox"]:focus {
  outline: none;
}
