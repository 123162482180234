.continue-button {
  float: right;
}

.continue-button .y-button {
  border: 1px solid #949494;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}
.continue-button-hotel {
  float: right;
  margin: 10px 0 10px 0;
}

.continue-button-hotel .y-button {
  border: 1px solid #949494;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}

.continue-button-hotel-gallery {
  float: right;
  margin: 5px 0 10px 0;
}

.continue-button-hotel-gallery .y-button {
  border: 1px solid #949494;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}

.ynew-button {
  margin-bottom: 5px;
  padding: 0 15px 0 15px;
  border: 1px solid #000;
  border-radius: 3px;
  cursor: pointer;
  display: inline-block;
  float: right;
}

.ynew-button a {
  height: 30px;
  line-height: 30px;
  font-size: 16px;
  font-weight: 400;
  color: #FFFFFF;
}

.y-button-padding-0-2 {
  padding: 0 2px !important;
}

.y-button {
  border-radius: 3px;
  cursor: pointer;
  display: inline-block;
  border: 1px solid #999;
  padding: 0 15px;

  input, a {
     //padding: 0 15px;
    //padding: 1px 22px 2px;
  }

  .add_multi_leg{
    //padding: 1px 22px 2px;
  }

  a {
    float: left;
    height: 40px;
    line-height: 40px;
    font-size: 18px;
    font-weight: 400;

    &.back {
      padding: 0 30px 0 0;
    }
  }
}
