.ls-wrapper .ls-select-box {
  // TODO: background: url('./images/site/liquid-slider/menu.png') no-repeat right #ddd;
}

.ls-wrapper .ls-nav-right-arrow {
  background: url('./images/site/liquid-slider/arrow.png') no-repeat top right;
}
.ls-wrapper .ls-nav-left-arrow {
  background: url('./images/site/liquid-slider/arrow.png') no-repeat top left;
}

.ls-wrapper .ls-nav-left-arrow:hover {
  background: url('./images/site/liquid-slider/arrow.png') no-repeat bottom left;
}

.ls-wrapper .ls-nav-right-arrow:hover {
  background: url('./images/site/liquid-slider/arrow.png') no-repeat bottom right;
}
.button-close,
.button-previous,
.button-next {
  position: absolute;
  width: 44px;
  height: 44px;
  background: #000;
  text-align: center;
  line-height: 44px;
  color: #fff;
  text-decoration: none;
  border-radius: 50%;
  font-size: 16px;
}

.button-previous,
.button-next {
  top: 50%;
  margin-top: -22px;
  line-height: 42px;
}

.button-previous {
    left: -50px;
}

.button-next {
    right: -50px;
}

.button-close {
  top: -44px;
  right: -44px;
  font-size: 22px;
  color: rgba(255,255,255,0.8);
}
// Vallenato
.active-header {
  // TODO: background: url('shared/active-header.png') #000000;
  background-repeat: no-repeat;
  background-position: right 50%;
  font-size: 18px;
  background: #ebebeb;
  margin: 5px 0 0 0;
  padding: 10px 55px 10px 15px;
  cursor: pointer;
  color: #FFFFFF;
  line-height: 22px;
  font-weight: 300;
  border-radius: 3px;
  float: left;
  width: 100%;
  box-sizing: border-box;
}

.inactive-header {
  // TODO: background: url('shared/inactive-header.png') #000000;
  background-repeat: no-repeat;
  background-position: right 50%;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
}

#accordion-container {
  font-size: 13px;
  display: none;
  padding: 15px;
  font-size: 16px;
  color: #252525;
  line-height: 22px;
  float: left;
  width: 100%;
  box-sizing: border-box;
}

.accordion-header {
  font-size: 18px;
  background: #ebebeb;
  margin: 5px 0 0 0;
  padding: 10px 55px 10px 15px;
  cursor: pointer;
  color: #FFFFFF;
  line-height: 22px;
  font-weight: 300;
  border-radius: 3px;
  float: left;
  width: 100%;
  box-sizing: border-box;
}
