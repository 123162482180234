.page_index,
.page_cars,
.page_about,
.page_conditions,
.page-static {
  margin-bottom: 15px;
  margin-top: 15px;
}
#map-canvas-all {
  width: 800px;
  height: 500px;
  margin: 0px;
  padding: 0px
}
#map-canvas-inner{
  width: 740px;
  height: 440px;
  margin: 30px 30px 30px 30px;
}
#map-canvas-hotel {
  width: 800px;
  height: 500px;
  margin: 0px;
  padding: 0px
}

.show-booking {
  float: right;
  width: 29%;
  border-radius: 3px;
  border: 1px dashed #000000;
  padding: 1%;
  margin: 1%;

  div.title {
    border-radius: 3px;
    background: #000000;
    color: #FFF;
    text-align: center;
    padding: 10px 5px;
    font-size: 20px;
    margin: 0 0 15px 0;
  }

  ul {
    margin-bottom: 5px;
    margin-top: 5px;
  }

  .topM10 {
    margin-top: 10px;
  }

  input[type="text"] {
    border-radius: 3px;
    border: 1px solid #000000;
    width: 100%;
    height: 38px;
    line-height: 38px;
    color: #000000;
    font-size: 18px;
    padding: 0 10px;
    float: left;
    margin-top: 10px;
  }
}

#alert-message {
  min-width: 200px;
  min-height: 100px;
}
