.price-cal {
  font-size: 18px;
  color: #000000;
  font-weight: 400;
}

.price-cal ul {
  float: left;
  width: 100%;
}

.price-cal ul li {
  float: left;
  text-align: right;
  padding: 8px 10px;
}

.price-cal ul.price-cal-title {
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  padding: 3px 0;
  margin: 0 0 5px;
}

.price-cal ul li.travelers {
  width: 35%;
  text-align: left;
}

.price-cal ul li.number {
  width: 10%;
}

.price-cal ul li.flights-price {
  width: 15%;
}

.price-cal ul li.tax {
  width: 20%;
}

.price-cal ul li.subtotal {
  width: 20%;
}

.price-cal-wrap .hr {
  margin: 10px 0;
}

.price-cal-wrap .total-text {
  float: left;
  font-size: 24px;
  font-weight: 400;
  color: #000000;
}

.price-cal-wrap .total {
  float: right;
  font-size: 24px;
  font-weight: 400;
  color: #000000;
}

.price-cal-wrap a.close-btn {
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  font-size: 18px;
  line-height: 40px;
  padding: 0 10px;
  float: right;
  margin: 15px 0 0;
}

.price-calculation {
  font-size: 18px;
  color: #000000;
  font-weight: 400;
}

.price-calculation ul {
  float: left;
  width: 100%;
}

.price-calculation ul li {
  float: left;
  margin: 0 20px;
  text-align: right;
  padding: 8px 0;
}

.price-calculation ul.price-cal-title {
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  padding: 3px 0;
  margin: 0 0 5px;
}

.price-calculation ul li.number {
  width: 25px;
  text-align: left;
}

.price-calculation ul li.travelers {
  text-align: left;
  width: 140px;
}

.price-calculation ul li.flights-dec {
  text-align: left;
  width: 535px;
}

.price-calculation ul li.subtotal {
  width: 90px;
  float: right;
  text-align: right;
}

.price-calculation-wrap .hr {
  margin: 10px 0;
}

.price-calculation-wrap .total-text {
  float: left;
  font-size: 24px;
  font-weight: 400;
  color: #000000;
}

.price-calculation-wrap .total {
  float: right;
  font-size: 24px;
  font-weight: 400;
  color: #000000;
}

.price-calculation-wrap a.close-btn {
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  font-size: 18px;
  line-height: 40px;
  padding: 0 10px;
  float: right;
  margin: 15px 0 0;
}
