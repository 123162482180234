.filter {
  color: #FFFFFF;

  .filter-section {
    width: 100%;
    border-radius: 3px;
    margin: 0 0 1px;
    background-color: #2520AF;

    .filter-header {
      .filter-header-text {
        display: inline-block;

        span,
        small {
          display: block;
        }

        small {
          font-size: 75%;
          color: lightgray;
        }
      }

      .filter-header-actions {
        float: right;
        display: block;
      }
    }

    .filter-content {
      padding: 0 20px;
    }

    &.filter-collapsable .filter-header {
      display: block;
      padding-left: 35px;
      color: #ECCC00;

      &:hover {
        border-radius: 3px;
        background-color: #3B36D9;
      }
    }
  }

  .filter-section.filter-section-main {
    .filter-header {
      border-radius: 3px;
      background-color: #1866AA;
      padding: 10px 10px 5px 10px;
    }

    .filter-content {
      padding: 10px 20px;
    }
  }

  .filter-closed .filter-header {
    background: url("./images/site/white-right-arrow.png") 0 no-repeat;
    background-position-x: 15px;
  }

  .filter-closed .filter-content {
    display: none;
  }

  .filter-open .filter-header {
    background: url("./images/site/white-down-arrow.png") 0 no-repeat;
    background-position-x: 15px;
  }

  .filter-open {
    padding-bottom: 15px;
  }

  .filter-header {
    padding: 10px;
  }

  .ui-slider {
    width: 100%;
    margin: 0;
  }

  .select-links {
    font-size: 12px;
    display: block;
    position: relative;
    top: -12px;
    right: 5px;
  }

  .filter-label-group > .filter-label:first-child {
    text-align: left;
  }

  .filter-label {
    text-align: right;
    font-size: 12px;
  }

  ul li {
    padding: 5px 0;
  }

  table {
    width: 100%;

    td {
      padding: 5px 0;
    }
  }

  input[type="button"] {
    border: none;
    border-radius: 3px;
    padding: 3px 8px;
    cursor: pointer;
    float: right;
  }

  .hotelname {
    background-color: #818181;
    float: left;
    border: 1px solid #D5D5D5;
    width: 100%;
    padding: 5px;
    font-size: 16px;
    text-align: left;
  }

  #filter_price_txt_max {
    float: right;
  }
}

.searchfilter #mapview .map-preview {
  overflow: hidden;
}

.tripadvisor_powered_by {
  margin-left: 10px;
  padding-top: 10px;

  text-align: right;
  font-size: 12px;

  .tripadvisor_powered_by_image,
  .tripadvisor_powered_by_text {
    display: inline-block;
  }

  .tripadvisor_powered_by_image {
    width: 60%;
    text-align: center;

    img {
      width: 100%;
    }
  }

  .tripadvisor_powered_by_text {
    width: 39%;
    float: left;
  }
}

.fake-filter {
  height: 600px;
  background-color: #2824b7;
  border-radius: 3px;
  padding-top: 20px;

  .fake-filter-header:first-child {
    margin-top: 0;
  }

  .fake-filter-header,
  .fake-filter-item {
    width: 80%;
    height: 24px;
    background-color: #2f29d1;
    margin-left: 20px;
    margin-top: 40px;
  }

  .fake-filter-header {
    margin-left: 20px;
    margin-top: 40px;
  }

  .fake-filter-item {
    margin-left: 30px;
    margin-top: 20px;
  }
}

.ui-slider-max-value .ui-slider-range {
  left: 0%;
  width: 100%;
}
.ui-slider-max-value span:first-of-type {
  left: 0%;
}
.ui-slider-max-value span:last-of-type {
  left: 100%;
}
