.cookie-message {
  z-index: 999;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.75);
  bottom: 0;
  left: 0;
  text-align: center;
  color: white;
  padding: 10px;
  height: 40px;
  overflow-wrap: break-word;
  min-width: 260px;
  line-height: 24px;
  border-radius: 0 10px 0 0;
  padding: 5px;
  -webkit-transition: opacity 0.5s ease-in-out;
  -moz-transition: opacity 0.5s ease-in-out;
  transition: opacity 0.5s ease-in-out;

  span {
    line-height: 32px;
    padding-right: 10px;
  }
}

.cookie-message-popup-content {
  width: 600px !important;
}

.cookie-message-popup-content h3:first-child {
  font-size: 30px;
  line-height: 40px;
  margin-bottom: 5px;
}

.cookie-message-popup-content h3 {
  padding-top: 5px;
}
