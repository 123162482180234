
.page-login .page-single {
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 16px 0;
  padding: 1rem 0;
}

.col-login {
  width: 24rem;
}

.col-login .card-title {
  font-size: 18px;
  font-size: 1.125rem;
  line-height: 1.2;
  font-weight: 400;
  margin-bottom: 24px;
  margin-bottom: 1.5rem;
}

.col-login .form-control {
  display: block;
  width: 100%;
  height: 38px;
  height: 2.375rem;
  padding: 6px 12px;
  padding: .375rem .75rem;
  font-size: 15px;
  font-size: .9375rem;
  font-weight: 400;
  line-height: 1.6;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0,40,100,.12);
  border-radius: 3px;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.col-login .form-group,
.col-login .form-label {
  display: block;
}

.col-login .form-group {
  margin-bottom: 16px;
  margin-bottom: 1rem;
}
