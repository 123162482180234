.payment-prodetail {
  float: left;
  width: 100%;
  color: #000000;

  a {
    text-decoration: underline;
  }

  ul {
    float: left;
    width: 65%;
  }

  table {
    float: right;
    width: 30%;

    tr {
      td:first-child {
        color: #000;
      }

      td {
        padding: 5px;
        text-align: right;
      }
    }
  }
}

.payment-prodetail ul li {
  float: left;
  line-height: 22px;
}

.payment-prodetail ul li span {
  color: #000;
}

.payment-prodetail ul li.dec {
  width: 60%;
}

.payment-prodetail ul li.dec_bottom {
  width: 100%;
  font-size: 12px;
  color: #000000;
  padding-top: 10px;
}

.payment-prodetail ul li.dec_bottom a {
  color: #000000;
  text-decoration: underline;
}

.payment-prodetail ul li.price {
  width: 20%;
  text-align: right;
  padding: 0 2%;
}

.payment-prodetail ul li.total {
  width: 20%;
  text-align: right;
}
