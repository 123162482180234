
.searchfilter p.switch {
  position: absolute;
  top: 24px;
  right: 15px;
}

.searchfilter p.switch a.open {
  width: 30px;
  height: 30px;
  font-size: 0;
  float: left;
}

.searchfilter p.switch a {
  width: 30px;
  height: 30px;
  font-size: 0;
  float: left;
}

.searchfilter {
  width: 22%;
  float: left;
  clear: right;
}

.searchfilter .select-links {
  font-size: 12px;
  display: inline;
  float: right;
}

.searchfilter .select-links a {
  font-size: 12px;
  text-decoration: underline;
  color: #FFFFFF;
}

.searchfilter .title {
  color: #FFFFFF;
  font-size: 16px;
  padding: 5px 20px;
  border-radius: 3px 3px 0 0;
  line-height: 23px;
}

.searchfilter .title span {
  font-size: 14px;
  display: block;
}
