
.page_cars {
  #container #content {
    display: inline-block;
  }
  .cars {
    .cars-content {
      iframe {
        width: 900px;
        height: 900px;
      }
    }
  }
}
