.blue-title {
  float: left;
  width: 100%;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  line-height: normal;
  font-size: 30px;
  margin: 0 0 10px;
  padding: 10px 0 15px;

  .small {
    float: left;
    width: 100%;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    line-height: normal;
    font-size: 18px;
    margin: 0 0 10px;
    padding: 5px 0 0;
  }

  .depart {
    float: left;
    padding: 0 0 0 40px;
  }

  .return {
    float: right;
    padding: 0 0 0 40px;
  }
}
