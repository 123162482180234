.fake-itinerary {
  width: 100%;
  background-color: #FFF;
  border: 1px solid #949494;
  height: 290px;
  border-radius: 3px;
  margin: 0 0 15px;
}

.fake-itinerary-airline,
.fake-itinerary-price,
.fake-itinerary-button {
  display: inline-block;
  margin: 10px;
  width: 120px;
  height: 40px;
}

.fake-itinerary-price,
.fake-itinerary-button {
  width: 200px;
  float: right;
}

.fake-itinerary-container {
  padding: 10px;
}

.fake-itinerary-itinerary {
  width: 100%;
}

.fake-itinerary-itinerary tr {
  height: 28px;
}

div.fake-itinerary-br {
  height: 20px;
}

tr.fake-itinerary-br {
  height: 5px;
}

td.fake-itinerary-br {
  width: 20px;
}
