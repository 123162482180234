.price_calculation_air_window {
  .fancybox-content {
    padding: 0px !important;
  }
}

.price-cal-wrap {
  width: 1000px !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
  padding-bottom: 0 !important;

  .price-cal {
    width: 100%;

    thead {
      background-color: #eccc00;

      th {
        padding: 14px 20px;
      }
    }

    tbody {
      td {
        padding: 10px 20px;
      }
      td.subtotal, td.summery-price {
        text-align: right;
      }
    }
  }

  div.travelers {
    padding: 20px 30px;
    text-align: center;
  }
}
