
$package-border-color: #949494;
$package-border-radius: 10px;
$package-item-height: 50px;
$package-item-action-height: 125px;
$package-item-background-color-even: #efefef;
$package-item-background-color-odd: #fff;

#step_3_form.locale_de-DE {
  background-color: white;
  padding: 10px;
  border-radius: 5px;
}

.package-container {
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  clear: left;
  width: 100%;
  height: auto;
  display: table;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 10px;
  text-align: left;

  .package-packages {
    .service-item-button {
      margin: 0px 20%;

      input[type="radio"] {
        display: none;

        &:not(:disabled) ~ label {
          cursor: pointer;
        }

        &:disabled ~ label {
          color: hsla(150, 5%, 75%, 1);
          border-color: hsla(150, 5%, 75%, 1);
          box-shadow: none;
          cursor: not-allowed;
        }
      }

      label {
        height: 100%;
        display: block;
        background: white;
        border: 2px solid hsla(150, 75%, 50%, 1);
        border-radius: 20px;
        padding: 1rem;
        margin-bottom: 1rem;
        text-align: center;
        /*box-shadow: 0px 3px 10px -2px hsla(150, 5%, 65%, 0.5);*/
        position: relative;
      }

      input[type="radio"]:checked + label {
        background: hsla(150, 75%, 50%, 1);
        color: hsla(215, 0%, 100%, 1);
        /*box-shadow: 0px 0px 20px hsla(150, 100%, 50%, 0.75);*/
      }

      input[type="radio"]#control_05:checked + label {
        background: red;
        border-color: red;
      }
    }
  }

  .package-addons, .package-item {
    display: inline-block;
    vertical-align: top;
    float: left;
  }

  .package-addons-item,
  .package-item-included, .package-item-excluded {
    height: $package-item-height;
  }

  .package-addons {
    width: 40%;
    font-size: 16px;

    .package-addons-header {
      background-color: #fff;
      font-weight: 500;
      padding: 10px;
      margin-top: 19px; // Border creates one more pixel
      border-radius: $package-border-radius 0px 0px 0px;
      border: 1px solid $package-border-color;
      border-right: 0px;
    }

    .package-addons-item {
      border-left: 1px solid $package-border-color;
      background-color: #fff;
      padding: 10px;

      .package-addons-item-icon {
        height: 26px;
        width: 32px;
        padding: 5px;
        margin-right: 5px;
        display: inline-block;

        img {
          width: 24px;
        }
      }

      .package-addons-item-info-icon {
        width: 35px;
        display: inline-block;
        float: right;
        text-align: right;
        padding-top: 5px;
        padding-bottom: 5px;
        color: #d2d2d2;

        img {
          width: 24px;
        }
      }

      &:nth-child(even) {
        background-color: $package-item-background-color-odd;
      }

      &:nth-child(odd) {
        background-color: $package-item-background-color-even;
      }

      &.package-addons-item-last {
        border-bottom: 1px solid $package-border-color;
        border-radius: 0px 0px 0px $package-border-radius;
      }
    }

    .package-addons-comments {
      height: 125px;
      padding: 5px;
      padding-top: 15px;

      .package-addons-text {
        height: 40px;
      }

      .package-addons-comments-field {
        padding: 5px;

        #comment {
          min-height: 50px;
          height: 50px;
          resize: vertical;
        }
      }
    }
  }

  @for $i from 2 through 6 {
    .packages-#{$i} .package-item {
      width: percentage(1/$i) !important;
    }
  }

  .package-item {
    width: 33%;
    border: 1px solid $package-border-color;
    border-right: 0px;

    .package-item-header {
      background-color: $package-item-background-color-even;
      text-align: center;
      font-size: 24px;
      position:relative;
      padding: 20px;
    }

    .package_stamp {
      height: 80px;
      width: 80px;
      background: url('./images/site/addon_package_highlight.png') 50px no-repeat;
      background-position: center;
      background-position: center;
      position: absolute;
      top: -25px;
      right: -20px;
      background-size: cover;
      z-index: 1;
    }

    .package-item-included,
    .package-item-excluded {
      &:nth-child(even) {
        background-color: $package-item-background-color-odd;
      }

      &:nth-child(odd) {
        background-color: $package-item-background-color-even;
      }
    }

    .package-item-included {
      background: url('./images/site/good.png') 20px no-repeat #ffffff;
      background-position: center;
    }

    .package-item-excluded {
      background: url('./images/site/bad.png') 20px no-repeat #ffffff;
      background-position: center;
    }

    .package-item-last {
      border-bottom: 1px solid $package-border-color;
    }

    .package-item-action {
      background-color: #fff;
      text-align: center;
      padding: 10px 0px;
      padding-top: 20px;
      height: $package-item-action-height;

      .price {
        font-size: 24px;
        font-weight: 500;
      }

      .person {
        font-size: 12px;
      }
    }
  }

  .package-packages .package-item:first-child {
    border-radius: $package-border-radius 0px 0px $package-border-radius;

    .package-item-header {
      border-radius: $package-border-radius 0px 0px 0px;
    }

    .package-item-action {
      border-radius: 0px 0px 0px 10px;
    }
  }

  .package-packages .package-item:last-child {
    border-right: 1px solid $package-border-color;
    border-radius: 0px $package-border-radius $package-border-radius 0px;

    .package-item-header {
      border-radius: 0px $package-border-radius 0px 0px;
    }

    .package-item-action {
      border-radius: 0px 0px $package-border-radius 0px;
    }
  }
}
