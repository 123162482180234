$color1:#eccc00;
$color2:#2311b2;
$color3:#1E23B0;
$color4:#FFFFFF;
$color5:#F0C800;
$color6:#0a9e1f;
$color7:#000;
$color8:#efcb01;
$color9:#1C23B2;
$color10:#EFC826;
$color11:#a38b00;
$color12:#2311b1;
$color13:#EEE ;
$color14:#AAA;
$color16: #2f29d1;
$color17: #2c26cf;
