.fly-schedule {
  float: left;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  background: #FFF;
  font-size: 16px;
  color: #000;
  margin: 0 0 15px;
  border: 1px solid #949494;
  width: 100%;
}

.selectdate {
  width: 30%;
  margin-left:auto;
  margin-right:auto;
  text-align: center;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.fly-schedule-inner {
  padding: 15px;
  display: inline-block;
  width: 100%;

  &.allotment {
    background: url("./images/site/bubbles.svg") no-repeat center;
    background-size: cover;

    .allotment-wrap {
      margin-top: 5px;

      p {
        font-family: 'Roboto Condensed', sans-serif !important;
        font-size: 16px !important;
        margin-bottom: 32px;

        b {
          font-weight: bold;

          &.dates-highlight {
            padding: 4px;
            border-radius: 2px;
            background: #2128a8;
            color: #fff;
            text-transform: uppercase;
          }
        }

        .price-tag {
          float: right;

          u {
            font-weight: bold;
          }
        }
      }
    }

    .flight-sc-layout {
      background: #fff;
    }
  }

  .schedule_change_warning {
    color: red;
  }
}

.fly-schedule-full {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */

  .fly-schedule-inner {
    opacity: 0.15;
    pointer-events: none;
  }
}

.fly-schedule-wide {
  width: 100%;

  .fly-schedule-inner {
    width: 100%;
  }

  .time-box {
    cursor: auto;
    width: 93.2%;

    .time-1 {
      width: 13.6%;
    }

    .time-dep_airport {
      width: 21.8%;
    }

    .time-arr_airport {
      width: 21.8%;
    }

    .time-1,
    .time-2,
    .time-carrier,
    .time-travel_time {
      cursor: default;
    }
  }

  .time-travel_time {
    font-size: 14px;
  }
}

.time-box.first .time-dep_airport,
.time-box.last .time-arr_airport {
  font-weight: bold;
}

.time-box {
  cursor: pointer;
  float: left;
  width: 92%;

  &.change-airport {
    background-color: #f0f0f0;
    width: 103%;
    margin: 5px -10px;
    text-align: center;
    padding: 5px;
    font-size: 14px;

    img {
      width: 10px;
      margin-right: 2px;
    }
  }

  &.change-airport-itinerary {
    margin-top: -10px;
  }

  .time-baggage {
    float: left;
    font-size: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 55px;

    span.not_included {
      text-decoration: underline;
      cursor: pointer;
    }
  }

  .new_time {
    color: #FF0000;
  }

  .old_time {
    color: #000000;
    text-decoration: line-through;
  }

  .time-1 {
    float: left;
    font-size: 14px;
    clear: left;
    width: 13%;
  }

  .time-dep_airport {
    float: left;
    font-size: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 22.8%;
  }

  .airport_change {
    color: #241EAF;
  }

  .time-2 {
    float: left;
    font-size: 14px;
    width: 13%;
  }

  .time-arr_airport {
    float: left;
    font-size: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 22.8%;
  }

  .time-carrier {
    float: left;
    font-size: 14px;
    width: 11.2%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .time-cabin {
    float: left;
    font-size: 14px;
    width: 7.2%;
  }

  .time-flightnumber {
    float: left;
    font-size: 14px;
    width: 5.7%;
  }
}

.step_1_air_form .time-box {
  .time-carrier {
    width: 12.3%;
  }
  .time-flightnumber {
    width: 7.4%;
  }
}

.flight-wrap {
  display: table;
  width: 100%;
  padding-bottom: 8px;

  .flight {
    float: left;
    width: 24%;
  }

  .flight .name {
    font-size: 17px;
    color: #000000;
    padding-top: 13px;
    float: left;
    padding-left: 10px;
  }

  .flight img {
    width: 30px; // height: 30px;
    margin-top: 7px;
    float: left;
  }

  .extra-links {
    float: left;
  }

  .flight-rules {
    float: right;
    margin-top: 14px;

    img {
      width: 15px;
      margin-right: 3px;
    }

    a {
      font-size: 14px;
      color: #212121;
    }
  }

  .flydetail {
    float: right;
    margin-top: 14px;
    padding-right: 8px;
  }

  .flydetail img {
    width: 15px;
    margin-right: 3px;
  }

  .flydetail a {
    font-size: 14px;
    color: #212121;
  }

  .flydetail .youth_price {
    font-size: 14px;
    color: #212121;
  }

  .spinner {
    float: right;
    margin-left: 40px;
    margin-right: 5px;
    margin-top: 5px;
    width: 80px;
  }

  .price-box {
    float: right;
    text-align: right;
    margin-top: 6px;
  }

  .resurs {
    float: left;
    margin-top: -3px;
    padding-right: 15px;
    color: black;
    text-decoration: underline;

    .resurs_text {
      text-align: left;
      font-size: 13px;
      font-weight: 200;
    }

    .resurs_price {
      font-size: 16px;
    }
  }

  .price {
    font-size: 18px;
    text-align: right;
    margin-bottom: 4px;
    float: right;
  }

  .smallprice {
    font-size: 13px;
    font-weight: 200;
    text-align: right;
    float: right;
    clear: right;
  }
}

.places-left {
  font-weight: bold;
  font-size: 14px;
  color: red;
  margin-top: -10px;
}

.price_type {
  font-weight: bold;
  font-size: 14px;
  color: blue;
  margin-top: -10px;
  float: right;
}

.airport_change_new {
  color: #000;
  font-weight: 400;
  margin: -5px 0 10px 0;
  font-size: 12px;
  padding: 3px;
  background-color: #EEE;
  text-align: center;
}

.airport_change_new_intern {
  color: #000;
  font-weight: 400;
  margin: 5px 0 5px -12px;
  font-size: 12px;
  padding: 3px;
  background-color: #EEE;
  text-align: center;
  width: 111%;
  display: inline-block;
  float: left;
  line-height: 20px;
}

.multiple_tickets {
  font-size: 13px;
  font-weight: 800;
  background: bisque;
  color: black;
  padding-left: 5px;
  width: max-content;
  min-width: 230px;
  margin-bottom: 10px;
  cursor: pointer;

  img {
    width: 10px;
    margin-right: 2px;
  }
}

.text-info {
  font-size: 13px;
  font-weight: 400;
  background: #5b2929;
  color: white;
  margin-top: 5px;
  padding: 3px;
  padding-left: 10px;
  padding-right: 10px;
  width: max-content;
  margin-bottom: 10px;
  cursor: pointer;
  border: 1px solid #000000;
  border-radius: 3px;
}

.price-box .total-price {
  float: left;
}

.price-box .pax-price {
  float: left;
  padding-right: 20px;
  margin-top: 5px;
}

.price-box .pax-price .price {
  font-size: 16px;
}

.price-box .pax-price .smallprice {
  font-size: 12px;
  margin-top: -8px;
}




.flight-sc {
  font-size: 16px;
  line-height: normal;
  overflow: auto;
  margin-top: 8px;
  margin-bottom: 5px;
}

.flight-sc-no-top-margin {
  margin-top: 0px;
}

.flight-sc-last {
  margin-top: 5px;
  margin-bottom: 0px;
}

.flight-sc ul {
  margin: 0 0 5px;
  display: block;
  width: 100%;
  float: left;
}

.flight-sc ul li {
  float: left;
  padding-bottom: 8px;
}

.flight-sc div.divider {
  float: left;
  width: 100%;
  height: 20px;
}

.flight-sc .table-header {
  border: 1px solid #696969;
}

.flight-sc .table-header li {
  color: #ffffff;
  padding: 2px 2px 2px 5px;
  font-size: 14px;
  border-right: 1px solid #717171;
}

.flight-sc .table-header li.empty {
  width: 3%
}

.flight-sc .table-header li.li_1 {
  width: 13%;
}

.flight-sc .table-header li.li_2 {
  width: 20%;
}

.flight-sc .table-header li.li_3 {
  width: 12%;
}

.flight-sc .table-header li.li_4 {
  width: 19.5%;
}

.fly-schedule-wide .flight-sc .table-header li.li_5 {
  width: 10%;
}

.flight-sc .table-header li.li_5 {
  width: 12%;
}

.fly-schedule-wide .flight-sc .table-header li.li_6 {
  width: 5%;
}

.flight-sc .table-header li.li_6 {
  width: 6.7%;
}

.fly-schedule-wide .flight-sc .table-header li.li_7 {
  width: 5.2%;
}

.flight-sc .table-header li.li_7 {
  width: 7.8%;
}

.flight-sc .table-header li.li_8 {
  border-right: none;
}

.fly-schedule-wide .flight-sc .table-header li.li_9 {
  width: 7%;
}

.flight-sc-layout {
  width: 100%;
  border: 1px solid #b9b9b9;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  margin-bottom: 5px;
  padding: 10px;
  overflow-x: hidden;
}

.flight-sc-layout .none {
  display: none;
}





.cabin-info {
  font-size: 16px;
  color: #373737;
  font-weight: bold;
}

.cabin-info span {
  font-weight: 100;
}

.cabin-info span.link {
  text-decoration: underline;
  cursor: pointer;
}

.tip_cabin {
  display: table;
}

.tip_cabin .row {
  display: table-row;
  height: 22px;
}

.tip_cabin .header {
  display: table-row;
  font-weight: 400;
  height: 30px;
}

.tip_cabin .from {
  display: table-cell;
  min-width: 200px;
}

.tip_cabin .to {
  display: table-cell;
  min-width: 200px;
}

.tip_cabin .cabin {
  display: table-cell;
}

.tip_text {
  max-width: 700px;
  white-space: pre-line;
}

.tip_text .header {
  padding-bottom: 10px;
  font-weight: bold;
}

.flight-technical-stop {
  clear: left;
  padding-left: 20px;
  padding-top: 6px;
  color: #241EAF;
  font-size: 13px;
}

#selected_flight {
  float: left;
  display: inline-block;
  width: 65%;
  padding-left: 15px;
}

.itinerary_no_bags {
  .table-header {
    li.li_1 {
      width: 13%;
    }

    li.li_2 {
      width: 22.5%;
    }

    li.li_3 {
      width: 11.9%;
    }

    li.li_4 {
      width: 22.9%;
    }

    li.li_5 {
      width: 12.5%;
    }

    li.li_6 {
      width: 7.4%;
    }
  }

  .time-box {
    .time-1 {
      width: 13.4%;
    }

    .time-dep_airport,
    .time-arr_airport {
      width: 25%;
    }

    .time-2 {
      width: 13.5%;
    }

    .time-carrier {
      width: 14.7%;
    }
  }
}

.itinerary .climate {
  background-color: #90f0b4;
  border-radius: 5px;
  font-size: 13px;
  width: max-content;
  margin-bottom: 10px;
  padding-right: 5px;

  sub {
    bottom: -0.25em;
    position: relative;
    vertical-align: baseline;
  }
}

.itinerary .cfar {
  background-color: #ffb800;
  border-radius: 5px;
  font-size: 13px;
  width: max-content;
  margin-bottom: 10px;
  padding: 2px 5px 2px 5px;

  sub {
    bottom: -0.25em;
    position: relative;
    vertical-align: baseline;
  }
}
