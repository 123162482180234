// TODO: I dont like this and we should have a more general rule for it.
.page_index,
.page_cars,
.page_about,
.page_conditions,
.page_static,
.page_show_booking {
  margin-bottom: 15px;
  margin-top: 15px;
}

.page_index {
  background: #FFF;
}
.page_contact {
  background: #FFF;
}
.page_step3{
  background: #efcb01;
}
.page_step4{
  background: #efcb01;
}
.page_about{
  background: #FFF;
}
.page_conditions,
.page_static{
  background: #FFF;
}

.page_cars {
  background: #FFF;

  #container #content {
    display: inline-block;
  }

  .cars {
    .cars-content {
      iframe {
        width: 900px;
        height: 900px;
      }
    }
  }
}

.inline-block {
  width: 100%;
  display: inline-block;
}

.li_link {
  cursor: pointer;

  a, a:hover, a:visited {
    color: #000000 !important;
  }
}
