/* show booking page */

.page_show_booking {
  background: #FFF;
}

.show_booking_div {
  text-align: center;
}

.show_booking_div>ul {
  font-size: 0;
  margin: 0 15px;
}

.show_booking_div>ul>li {
  display: inline-block;
  vertical-align: top;
  border-radius: 3px;
  border: 1px dashed #0A5AA6;
  font-size: 14px;
  color: #000000;
  line-height: 18px;
  text-align: left;
  width: 400px;
  padding: 10px;
  margin: 0 1% 15px 0;
  // min-height: 395px;
}

.show_booking_div div.title {
  border-radius: 3px;
  background: #0A5AA6;
  color: #FFF;
  text-align: center;
  padding: 10px 5px;
  font-size: 20px;
  margin: 0 0 15px 0;
}

.show_booking_div .topM10 {
  margin-top: 10px;
}

.show_booking_div div.inputs ul {
  margin-bottom: 5px;
  margin-top: 5px;
}

.show_booking_div input[type="text"] {
  border-radius: 3px;
  border: 1px solid #0A5AA6;
  width: 100%;
  height: 38px;
  line-height: 38px;
  color: #0A5AA6;
  font-size: 18px;
  padding: 0 10px;
  float: left;
  margin-top: 10px;
}
