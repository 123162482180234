.tripadvisor {
  .tripadvisor_image {
    float: left;
  }

  .tripadvisor_rating {
    float: right;
    font-weight: bold;
    font-size: 14px;
    padding-top: 1px;
  }

  .tripadvisor_reviews {
    font-size: 13px;
    clear: both;

    a {
      color: #000000;
      text-decoration: underline;
    }
  }
}

.price-box-hotel {
  .total-price {
    float: left;
  }

  .pax-price {
    display: none;
    float: left;
    padding-right: 30px;
    margin-top: 5px;

    .price {
      font-size: 20px;
    }

    .smallprice {
      font-size: 12px;
      margin-top: -8px;
    }
  }
}

.location_name {
  display: inline-block;
  padding-right: 3px;
  max-width: 79%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding-left: 10px;
  line-height: 18px;
  position: relative;
  top: 4px;
}

.location_count {
  display: inline;
}
