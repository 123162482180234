.payment-flow-container {
  width: 40%;
  margin: 0 auto;

  .card-body {
    text-align: center;
  }

  .payment-logo {
    width: 50%;
    margin-top: 20px;
  }

  .payment-loader {
    padding-top: 40px;
  }

  .payment-text {
    padding: 30px 50px;
    line-height: 24px;
  }

  .payment-back {
    width: 50%;
  }
}

::-webkit-scrollbar {
  -webkit-appearance: none;
}
::-webkit-scrollbar:vertical {
  width: 12px;
}
::-webkit-scrollbar:horizontal {
  height: 12px;
}
::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, .5);
  border-radius: 10px;
  border: 2px solid #ffffff;
}
::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: #ffffff;
}

.payment-wrap {
  float: left;
  width: 100%;
}
.payment-wrap > ul {
  float: left;
  width: 100%;
}
.payment-wrap > ul > li {
  float: left;
  width: 100%;
  background: #FFF;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  font-size: 18px;
  font-weight: 400;
  margin: 0 0 1px 0;
  display: table;
  height: 100%;
}
.payment-wrap > ul > li form {
  display: table-row
}
.payment-msec {
  display: table-cell;
  padding: 2%;
  width: 60%;
  vertical-align: top;
}
.payment-msec .title {
  font-size: 30px;
  float: left;
  width: 100%;
  color: #000;
  font-weight: 300;
  margin: 0 0 15px 0;
}
.payment-msec .title img {
  float: left;
  max-width: 140px;
}
.payment-msec .title span {
  float: left;
  padding: 3px 0 0 15px;
}
.payment-option {
  padding: 2%;
  border-left: 1px solid #efcb01;
  // width: 20%;
  color: #000;
  display: inline-table;
  height: 100%;
  display: table-cell;
  vertical-align: top
}
.payment-option div.space {
  padding: 15px 0;
  float: left;
}
.payment-option div.text {
  display: inline;
  padding-left: 5px;
  position: relative;
  top: 15px;
}
.payment-option label {
  float: left;
  width: 100%;
  margin: 0 0 10px 0;
}
.payment-option img {
  margin: 0 0 0 10px;
  float: left;
  max-height: 40px;
}
.payment-option input[type="text"] {
  background: #e5e5e5;
  color: #000;
  font-size: 18px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  padding: 5px 10px;
  margin: 10px 0 0 0;
  float: left;
  width: 180px;
}
.payment {
  -webkit-border-radius: 0 3px 3px 0;
  -moz-border-radius: 0 3px 3px 0;
  border-radius: 0 3px 3px 0;
  width: 15%;
  display: table-cell;
  padding: 0 10px;
  height: 100%;
  text-align: center;
  vertical-align: top;
}
.payment .y-button {
  margin: 15px 0;
}
.payment .y-button a.fly {
  padding: 0 58px 0 0;
}
.page_step_4_air .custom-radio-payment_type {
  float: left;
  margin-top: 10px;
}
.custom-radio-payment_type {
  width: 40px;
  height: 40px;
  display: inline-block;
  position: relative;
  z-index: 1;
  /*top: 25px;*/

  float: left;
  margin: 0 0 0 5px;
  cursor: pointer;
}
.custom-radio-payment_type input[type="radio"] {
  margin: 1px;
  position: absolute;
  z-index: 2;
  cursor: pointer;
  outline: none;
  opacity: 0;
  /* CSS hacks for older browsers */

  _noFocusLine: expression(this.hideFocus=true);
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  -khtml-opacity: 0;
  -moz-opacity: 0;
}

.custom-radio-payment_type {
  background: url('./images/site/radio2.png') no-repeat;
}
.custom-radio-payment_type:hover {
  background: url('./images/site/radio1-hover.png') no-repeat;
}
.custom-radio-payment_type.selected {
  background: url('./images/site/radio1-selected.png') no-repeat;
}

.payment_failure_alert {
  padding-top: 36px;
  padding-bottom: 36px;
}

.klarna-container {
  padding: 10px;
  background-color: whitesmoke;
  border-radius: 5px;
}
