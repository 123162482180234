.page_about .about {
  padding: 0 15px;

  &__content {
    display: inline-block;

    h1 {
      font-size: 36px;
      margin-bottom: 20px;
    }

    p {
      margin-bottom: 20px;
      line-height: 22px;
    }
  }
}
