.error-page {
  margin: 2em auto;
  padding: 0;
  text-align: left;
  width: 650px;
  margin: 2em auto;
  padding: 0;
  text-align: left;
  width: 650px;
  border: 5px solid #c4c4c4;
  background-color: #FFF;
  border-bottom-style: solid;
  border-bottom-color: #9f9f9f;
  #spacing {
    height: 100px;
  }
  h1 {
    padding: .65em 15px;
    color: #453;
    font: bold 20px "Helvetica Neue", Arial, Helvetica, Tahoma, sans-serif;
    background-color: #e9ded5;
    margin-top: 0;
    border-bottom: 1px solid #b8b8b8;
  }
  .content {
    padding: 15px;
    text-align: center;
    img {
      width: 100px;
      display: inline-block;
    }
  }
}
