.step_2_form_old {
  // TODO: This shouldn't be a loop...
  @for $i from 0 through 9 {
    #trav_#{$i}_birthday_day {
      width: 50%;
      margin: 0 5px;
    }
    #trav_#{$i}_birthday_month {
      width: 50%;
      margin: 0 5px;
    }
    #trav_#{$i}_birthday_year {
      width: 50%;
      margin: 0 5px;
    }
    #trav_#{$i}_birthday_day-menu>.ui-menu-item {
      background: #e4e4e4;
      color: #2311b2;
      font-size: 14px;
      border-bottom: 1px solid #b9b9b9;
      font-family: 'Roboto Condensed', sans-serif;
    }
    #trav_#{$i}_birthday_month-menu>.ui-menu-item {
      background: #e4e4e4;
      color: #2311b2;
      font-size: 14px;
      border-bottom: 1px solid #b9b9b9;
      font-family: 'Roboto Condensed', sans-serif;
    }
    #trav_#{$i}_birthday_year-menu>.ui-menu-item {
      background: #e4e4e4;
      color: #2311b2;
      font-size: 14px;
      border-bottom: 1px solid #b9b9b9;
      font-family: 'Roboto Condensed', sans-serif;
    }
    #trav_#{$i}_birthday_month-menu {
      height: 242.333px;
      width: auto;
      padding: 0;
      margin: 0;
      list-style: none;
      position: relative;
      overflow: auto;
      overflow-y: auto;
      overflow-x: hidden;
    }
    #trav_#{$i}_birthday_year-menu {
      height: 242.333px;
      width: auto;
      padding: 0;
      margin: 0;
      list-style: none;
      position: relative;
      overflow: auto;
      overflow-y: auto;
      overflow-x: hidden;
    }
    #trav_#{$i}_birthday_day-menu {
      height: 242.333px;
      width: auto;
      padding: 0;
      margin: 0;
      list-style: none;
      position: relative;
      overflow: auto;
      overflow-y: auto;
      overflow-x: hidden;
    }
  }

  span.bottom-newsletter-text {
    float: left;
    line-height: 40px;
    font-size: 18px;
    margin: 0 0px 0 20px;
    text-decoration: none;
  }

  .toggle_bonuscard_link {
    color: #000;
    text-decoration: underline;
    padding-top: 5px;
    display: inline-block;
    padding-left: 110px;
  }

  #toggle_seating_link {
    color: #000;
    text-decoration: underline;
    padding-top: 5px;
    display: inline-block;
    padding-left: 110px;
  }

  .bottom-condition {
    float: right;
    margin: 20px 0 0 0;
  }

  .bottom-condition label {
    float: left;
    margin: 0 0 0 50px;
  }

  .bottom-condition span {
    float: left;
    line-height: 40px;
    font-size: 18px;
    margin: 0 0px 0 20px;
  }

  .bottom-condition-text a {
    color: #000;
    text-decoration: underline !important;
  }

  .birthday_info {
    width: 400px !important;
    display: flex; //margin: 0 -5px ;
  }
}
