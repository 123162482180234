.page_step_1_air_small {
  margin-top: -15px;
}

.overlay {
  opacity: 0.3;
  filter: alpha(opacity=30);
  pointer-events: none;
}

.clicked_hotel {
  margin-top: 10px;
}

div.fancyclose a.fancybox-close,
div.fancybox-type-image a.fancybox-close {
  display: inherit;
}

.filter ul li span.filter_value {
  padding-left: 0px;
}

.map_frame {
  width: 77%;
}

#map_canvas {
  height: 600px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  border: 1px solid #949494;
}

.map_info {
  width: 300px;
  height: 40px;
  line-height: 1.35;
  overflow: hidden;
  white-space: nowrap;
}

.map_info .image {
  float: left;
  padding-right: 5px;
}

.map_info .image img {
  max-width: 60px;
  max-height: 60px;
}

.map_info .stars img {
  width: 10px;
  height: 10px;
  float: left;
}

.map_info .name {
  white-space: nowrap;
  font-weight: bold;
}

.map_info .price {
  float: left;
  padding-left: 10px;
  line-height: 11px;
}

.blue-title {
  float: left;
  width: 100%;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  line-height: normal;
  font-size: 30px;
  margin: 0 0 10px 0;
  padding: 10px 0 15px 0
}

.blue-title .small {
  float: left;
  width: 100%;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  line-height: normal;
  font-size: 18px;
  margin: 0 0 10px 0;
  padding: 5px 0 0px 0
}

.yellow-title .small {
  float: left;
  width: 100%;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  line-height: normal;
  font-size: 18px;
  margin: 0 0 10px 0;
  padding: 5px 0 0px 0
}

.blue-title .depart {
  float: left;
  padding: 0 0 0 40px;
}

.blue-title .return {
  float: right;
  padding: 0 0 0 40px;
}

.right_banner {
  float: right;
  padding-bottom: 15px;
  img {
    border-radius: 5px;
  }
}

.missing_meta {
  text-align: center;
  font-weight: 500;
  font-size: 18px;
  line-height: 30px;
  color: #000;
}

.fly-schedule-remove {
  opacity: 0.2;
  display: none;
}

.fly-schedule-removed_reason {
  background-color: bisque;
  text-align: center;
  font-weight: 400;
  font-size: 15px;
  padding: 4px;
  float: left;
  width: 100%;
  display: none;
}

.fly-schedule-meta {
  border: 1px solid #241EAF;
}

.fly-schedule .flight-message {
  background-color: #241EAF;
  padding: 10px 10px 10px 14px;
  color: #ffffff;
  font-size: 25px;
  font-weight: bold;
}

div.more-search-options {
  float: left;
  width: 45%;
  text-align: right;
  margin-top: 2px;
}

div.more-search-options a {
  color: #000000;
  text-decoration: underline;
}

.time-technical-stop {
  color: #241EAF;
}

.top-field {
  display: inline-block;
  width: 100%;
}

.tripadvisor .tripadvisor_image {
  float: left;
}

.tripadvisor .tripadvisor_rating {
  float: right;
  font-weight: bold;
  font-size: 14px;
  padding-top: 1px;
}

.tripadvisor .tripadvisor_reviews {
  font-size: 13px;
  clear: both;
}

.tripadvisor .tripadvisor_reviews a {
  color: #000000;
  text-decoration: underline;
}

.price-box-hotel .total-price {
  float: left;
}

.price-box-hotel .pax-price {
  display: none;
  float: left;
  padding-right: 30px;
  margin-top: 5px;
}

.price-box-hotel .pax-price .price {
  font-size: 20px;
}

.price-box-hotel .pax-price .smallprice {
  font-size: 12px;
  margin-top: -8px;
}

.departure-title {
  padding: 0;
  float: left;
}

.arrival-title {
  padding: 0;
}

div.fastest {
  color: #000000;
}

.time-radio {
  float: left;
  margin-right: 5px;
  margin-top: 2px;
}

// .time-new_day {
//   color: #ef0000;
// }
.time-extra_info {
  color: #939393;
  font-size: 12px;
  float: left;
  padding-top: 4px;
}

.long_connection_icon {
  width: 10px;
  margin-left: 2px;
}

.departure-airline {
  color: #000000;
  font-size: 14px;
  float: left;
  padding-left: 10px;
  padding-top: 2px;
}

.your_pick {
  color: #ffffff;
  font-weight: bold;
  font-size: 25px;
  padding-top: 5px;
}

.bag-text {
  color: #212121;
  font-size: 14px;
  float: left;
  padding-left: 15px;
  margin-top: 8px;
  width: 50%;
}

.bag-text-bottom {
  color: #212121;
  font-size: 14px;
  float: left;
  padding-left: 15px;
  margin-top: 0px;
  width: 65%;
}

.selected_flight {
  background-color: #fcf8e6;
}

div.baggage_text {
  float: left;
  font-weight: 400;
  padding-bottom: 5px;
}

div.fly-schedule-wide div.baggage div.passenger div.leg {
  width: 280px;
}

#flight_rules_loading {
  text-align: center;
  margin-top: 100px;
  background-repeat: no-repeat;
  height: 125px;
  font-size: 20px;
}

a.rules-close-btn {
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  font-size: 18px;
  line-height: 40px;
  padding: 0 10px;
  float: left;
  position: absolute;
  top: 1px;
  right: 20px;
}

.price-cal {
  font-size: 18px;
  color: #000;
  font-weight: 400;
}

.price-cal ul {
  float: left;
  width: 100%;
}

.price-cal ul li {
  float: left;
  text-align: right;
  padding: 8px 10px;
}

.price-cal ul.price-cal-title {
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  padding: 3px 0;
  margin: 0 0 5px 0
}

.price-cal ul li.travelers {
  width: 35%;
  text-align: left
}

.price-cal ul li.number {
  width: 10%;
}

.price-cal ul li.flights-price {
  width: 15%;
}

.price-cal ul li.tax {
  width: 20%;
}

.price-cal ul li.subtotal {
  width: 20%;
}

.price-cal-wrap .hr {
  margin: 10px 0;
}

.price-cal-wrap .total-text {
  float: left;
  font-size: 24px;
  font-weight: 400;
  color: #000
}

.price-cal-wrap .total {
  float: right;
  font-size: 24px;
  font-weight: 400;
  color: #000
}

.price-cal-wrap a.close-btn {
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  font-size: 18px;
  line-height: 40px;
  padding: 0 10px;
  float: right;
  margin: 15px 0 0 0;
}

.flight-rules-wrap {
  font-family: Arial, Verdana, Helvetica, sans-serif;
  font-size: 12px;
  color: #666666;
}

.flight-rules-wrap strong {
  font-weight: 600;
}

.flight-rules-wrap .rules_box {
  border: 1px solid #CCC;
  width: 500px;
  display: table;
  margin-bottom: 15px;
  padding: 5px;
}

.flight-rules-wrap h1 {
  font-size: 24px;
  font-weight: bold;
  color: #0f1f24;
}

.flight-rules-wrap p {
  font-size: 14px;
  font-weight: normal;
  color: #0f1f24;
}

.flight-rules-wrap a.close-btn {
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  font-size: 18px;
  line-height: 40px;
  padding: 0 10px;
  float: right;
  margin: 15px 0 0 0;
}

#error_screen {
  width: auto;
}

#error_screen a.close-btn {
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  font-size: 18px;
  line-height: 40px;
  padding: 0 10px;
  float: right;
  margin: 15px 0 0 0;
}

.spinner {
  padding: 4px 0;
}

.seat-info {
  color: #dc0000;
  font-weight: bold;
}

.price-calculation {
  font-size: 18px;
  color: #000;
  font-weight: 400;
}

.price-calculation ul {
  float: left;
  width: 100%;
}

.price-calculation ul li {
  float: left;
  margin: 0 20px;
  text-align: right;
  padding: 8px 0;
}

.price-calculation ul.price-cal-title {
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  padding: 3px 0;
  margin: 0 0 5px 0
}

.price-calculation ul li.number {
  width: 25px;
  text-align: left;
}

.price-calculation ul li.travelers {
  text-align: left;
  width: 140px;
}

.price-calculation ul li.flights-dec {
  text-align: left;
  width: 535px;
}

.price-calculation ul li.subtotal {
  width: 90px;
  float: right;
  text-align: right;
}

.price-calculation-wrap .hr {
  margin: 10px 0;
}

.price-calculation-wrap .total-text {
  float: left;
  font-size: 24px;
  font-weight: 400;
  color: #000
}

.price-calculation-wrap .total {
  float: right;
  font-size: 24px;
  font-weight: 400;
  color: #000
}

.price-calculation-wrap a.close-btn {
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  font-size: 18px;
  line-height: 40px;
  padding: 0 10px;
  float: right;
  margin: 15px 0 0 0;
}

.ui-slider {
  height: 12px;
  position: relative;
  text-align: left;
  border-radius: 10px;
}

.ui-slider-handle {
  cursor: pointer;
  width: 20px;
  height: 20px;
  position: absolute;
  z-index: 2;
  border-radius: 25px;
  top: -4px;
  margin-left: -5px;
}

.ui-slider-range {
  position: absolute;
  border: 0;
  top: 0;
  height: 100%;
  border-radius: 25px;
  width: 100%;
}

.switchfilter {
  border-radius: 0 0 3px 3px;
}

.switchfilter ul li {
  float: left;
  margin: 0 0px 10px 0;
  width: 100%;
}

.switchfilter ul li .caption {
  display: block;
  margin: 0 10px 0 0;
  width: 160px;
  float: left;
  padding: 2px 0;
}

.sponsor {
  float: left;
  padding-right: 5px;
  padding-bottom: 15px;
}

.ui-slider {
  width: 93%;
  margin-left: 3px;
}

.airlinefilter {
  padding-bottom: 30px;
}

.airlinefilter ul {
  width: 100%;
  float: left;
}

.airlinefilter ul li {
  float: left;
  font-size: 16px;
  margin: 7px 0 0;
  width: 100%;
}

.airlinefilter ul li span {
  padding-left: 10px;
}

.connectionfilter {
  padding-bottom: 30px;
}

.connectionfilter ul {
  width: 100%;
  float: left;
}

.connectionfilter ul li {
  float: left;
  font-size: 16px;
  margin: 7px 0 0;
  width: 100%;
}

.connectionfilter ul li span {
  padding-left: 10px;
}

.location_name {
  display: inline-block;
  padding-right: 3px;
  max-width: 79%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding-left: 10px;
  line-height: 14px;
  position: relative;
  top: 4px;
  line-height: 18px;
}

.location_count {
  display: inline;
}

/*=== Dropdown Menu */

a.paxmenu {
  font-size: 18px;
  border-radius: 3px;
  line-height: normal;
  padding: 0 10px;
  width: 100%;
  height: 38px;
  float: left;
  cursor: pointer;
  line-height: 36px;
  text-transform: uppercase;
  font-weight: 400;
}

a.paxmenu img {
  float: right;
  margin: 10px 0 0 0;
}

ul.paxsection {
  position: absolute;
  top: 40px;
  right: 15px;
  width: 120%;
  z-index: 1;
  display: none;
}

ul.paxsection li {
  border-radius: 3px;
  padding: 5px 10px;
  float: left;
  width: 100%;
  height: 38px;
  line-height: 25px;
  font-size: 18px;
  font-weight: 400;
  text-transform: uppercase;
}

ul.paxsection li img {
  float: left;
  margin: 0 15px 0 0;
}

ul.paxsection li .countersec img {
  cursor: pointer;
  float: left;
  margin: 3px 0;
}

ul.paxsection li span {
  float: left;
}

ul.paxsection li .countersec {
  float: right
}

ul.paxsection li span {
  width: 30px;
  text-align: center;
}

/*=== Dropdown Menu */

.view-buttons {
  clear: left;
}

.y-img-button a.list_view {
  padding: 0 35px 0 0;
}

.y-img-button a.gallery_view {
  padding: 0 35px 0 0;
}

.y-img-button a.map_view {
  padding: 0 35px 0 0;
}

.y-img-button {
  cursor: pointer;
  padding: 0 15px;
  border-radius: 3px;
  cursor: pointer;
  display: inline-block;
  border: 1px solid #949494;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  margin-bottom: 10px;
  margin-right: 5px;
}

.y-img-button a {
  float: left;
  height: 40px;
  line-height: 40px;
  font-size: 18px;
  font-weight: 400;
  color: #2311b2;
}

.sponsor {
  float: left;
  padding-right: 5px;
  line-height: 40px;
  padding-bottom: 15px;
  font-weight: 400;
}
