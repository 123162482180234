.sort_by_group .sort_by li {
  float: left;
  width: 33.33%;
  border: 1px solid #949494;
  border-right: none;
  padding: 5px;
  text-align: center;
  margin-bottom: 10px;
  font-weight: 300;
  line-height: 20px;
  cursor: pointer;

  &.ui-btn-active {
    font-weight: 400;
  }

  &:first-of-type {
    -webkit-border-right-radius: 3px 0px 0px 3px;
    -moz-border-right-radius: 3px 0px 0px 3px;
    border-radius: 3px 0px 0px 3px;
  }

  &:last-of-type {
    border-right: 1px solid #949494;
    -webkit-border-right-radius: 0px 3px 3px 0px;
    -moz-border-right-radius: 0px 3px 3px 0px;
    border-radius: 0px 3px 3px 0px;
  }
}

.sort_by_group .sort_by .small_text {
  font-size: 10px;
  line-height: 10px;
}

@for $i from 1 through 6 {
  .sort_by_group .sort_by li:nth-last-child(#{$i}),
  .sort_by_group .sort_by li:nth-last-child(#{$i}) ~ li {
    width: percentage(1/$i);
  }
}

.fake-sort_by_group-container {
  height: 55px;
  margin-bottom: 20px;
}

.fake-sort_by_group-container .sort_by_group .sort_by li {
  height: 62px;
  cursor: default;
}

.fake-sort_by_group-container .sort_by_group .sort_by li:hover {
  background-color: #E2E2E6;
}

.fake-sort_by_group-container .sort_by_group .sort_by li div {
  margin: auto;
  width: 100px;
  height: 24px;
  
  animation: fakeSortButtonBgKeyFrame 1s infinite;
}

@keyframes fakeSortButtonBgKeyFrame {
  0% {
    background-color: #d9d9de;
  }
  50% {
    background-color: #d8d8de;
  }
  100% {
    background-color: #d9d9de;
  }
}
