.page_contact {
  margin-bottom: 15px;
  margin-top: 15px;

  .contact {
    padding: 0 15px;
    display: flex;

    &__content {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .addressmap {
        border-top: 1px solid #2012aa;
        height: 500px;
        padding-top: 16px;
        width: calc(100% - 16px);
      }

      h1 {
        font-size: 36px;
        margin: 16px 0 24px;
      }

      p {
        line-height: 22px;
        margin-bottom: 24px;
        max-width: 70%;
      }

      a {
        color: #2012aa;
      }
    }

    &__sidebar {
      flex: 1;
      flex-basis: 256px;
      flex-grow: 0;
      flex-shrink: 0;
    }
  }
}
