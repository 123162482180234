/* Base code for tabs with bootstrap */
.flight-rules-wrap {
  .nav-link.active {
    color: #495057;
    background-color: #fff;
    border-color: #dee2e6 #dee2e6 #fff;
  }

  .nav-tabs .nav-link {
    border: 1px solid transparent;
    border-top-left-radius: .25rem;
    border-top-right-radius: .25rem;
  }

  .nav-tabs .nav-item {
    margin-bottom: -1px;
  }

  .nav-link {
    display: block;
    padding: .5rem 1rem;
  }

  .tab-content > .tab-pane.active {
    display: block;
  }

  .tab-content > .tab-pane {
    display: none;
  }


  /* Lets just get the ticket rules working */
  .nav {
    margin-bottom: 10px;
  }

  .nav-item {
    width: 49%;
    display: inline-block;
    border: 1px solid black;
    text-align: center;
    font-size: 18px;
  }

  .nav-link {
    color: #495057;
  }

  .nav-item .active {
    background-color: lightgray;
  }
}
