//body of step2
@for $i from 1 through 8 {
  #trav_#{$i}_titel-menu > .ui-menu-item{
    background: #e4e4e4;
    color: #2311b2;
    font-size: 14px;
    border-bottom: 1px solid #b9b9b9;
    font-family: 'Roboto Condensed', sans-serif;
    z-index: 0;
  }

}

.flighttab {
  margin-top: 20px;
  cursor: pointer;
  padding: 6px 10px 6px 10px;
  float: left;
  border-width: 2px;
  border-color: #545183;
  background-color: #bcd0e7;
  color:white;
}

.selectedtab {
  //background-color: #aeabcf;
  color: white;
  text-decoration: underline;
  text-underline-offset: 2px;
  text-decoration-style: dotted;
  background-color: #8fbacf;
}

.ui-button:active  {
    color: #2311b2;
    padding: 0 35px 0 10px;
    height: 38px;
    border: 1px solid #2311b2;
    width: 100% !important;
    font-family: 'Roboto Condensed', sans-serif;
    font-size: 18px;
    font-weight: 300;
    background: #FFF;
    line-height: 38px;
    overflow: hidden;
}
.datepicker--cell.-selected- {
  background: #1C23B2 !important;
}
.datepicker--day-name{
  color: #1C23B2 !important;
}
.page_step_2_air {
  margin-top: -15px;
}
.page_step_2_air h1 {
  color: #000;
  line-height: normal;
  padding-bottom: 10px;
}
.pax_element{
  border-bottom: 1px solid #e4e4e4;
}

.name_text_package {
  display: inline;
  padding-left: 52px;
}
.name_text_flight {
  display: inline;
  padding-left: 27px;
}
.hotel_result_wide {
  width: 100%;
  height: 205px;
}
.hotel_result_wide .information {
  width: 77.5%;
}
.hotel_result_wide .image {
  width: 240px;
  height: 180px;
}
.hotel_result_wide .options .table li.li_1 {
  width: 44.5%;
}
.hotel_result_wide .options .table li.li_2 {
  width: 30%;
}
.hotel_result_wide .options .table {
  cursor: auto;
}
.booking-mform {
  background: #FFF;
  float: left;
  width: 100%;
  border-radius: 3px;
  margin: 2px 0 0 0;
  padding-bottom: 10px;
  border: 1px solid #949494;
}
.booking-mform .title {
  color: #FFFFFF;
  border-radius: 3px;
  line-height: 30px;
  font-size: 18px;
  margin: 10px 0 0 0;
  float: left;
  width: 100%;
}


.booking-mform input[type="text"] {
  border-radius: 3px;
  width: 100%;
  height: 38px;
  line-height: 38px;
  font-size: 18px;
  padding: 0 10px;
  float: left;
}

.booking-mform textarea {
  font-weight: 300;
  border-radius: 3px;
  width: 100%;
  font-size: 18px;
  padding: 10px 10px;
  float: left;
}
.booking-mform input[type="text"].error {
  border-color: #FF0000
}
.booking-mform ul {
  float: left;
  width: 100%;
}
.booking-mform ul li {
  margin: 5px 10px 5px 0;
  float: left;
  text-align: left;
  width: 20%;
}
.booking-mform ul.you li {
  width: 225px;
}

#company_address {
  width: 460px;
}

.booking-mform ul.pax_headers li {
  font-size: 16px;
  font-weight: 400;
  height: 10px;
}
.booking-mform ul li.pax_number {
  padding-top: 9px;
  width: 100px;
}
.booking-mform ul li.trav_titel {
  width: 150px;
}
.booking-mform ul li.bonuscard_info {
  padding-left: 105px;
  width: 350px;
  clear: left;
  padding-bottom: 10px;
}
.booking-mform ul li.bonuscard_info+li.bonuscard_info {
  padding-left: 0px;
  width: 200px;
  clear: none;
}
.booking-mform ul li.show_bonuscard_fields {
  width: 120px;
  padding-top: 9px;
  float: right;
}

.booking-mform .add-baggage {
  cursor: pointer;
  padding-left: 110px;
  width: 700px;
  clear: left;
  padding-top: 4px;
  padding-bottom: 10px;
  display: inline-block;
}

.booking-mform .add-baggage .description {
  text-decoration: underline;
}

.booking-mform .add-baggage .information {
  padding-left: 10px;
  font-size: 14px;
}
.booking-mform ul li.you_mail {
  clear: left;
}
.ui-datepicker .ui-datepicker-title select {
  width: 60px !important;
}
.custom-checkbox-error {
  width: 40px;
  height: 40px;
  display: inline-block;
  position: relative;
  z-index: 1;
  top: 3px;
  cursor: pointer;
}
.booking-product {
  background: #FFFFFF;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  float: left;
  width: 100%;
  line-height: normal;
  color: #000;
  font-size: 16px;
  border: 1px solid #949494;
}
.booking-product .pd {
  float: left;
  width: 100%;
}
.booking-product .pd .table-header {
  border: 1px solid #696969;
  margin-bottom: 5px;
}
.booking-product .pd .table-header span {
  color: #ffffff;
  padding: 2px 5px 2px 5px;
  font-size: 14px;
  border-right: 1px solid #717171;
  margin: 0;
}
.booking-product .pd .table-header .total span {
  border-right: none;
}
.booking-product ul {
  float: left;
  width: 100%;
}
.booking-product ul li {
  float: left;
}
.booking-product ul li span {
  color: #000;
  margin: 0 0 20px 0;
  display: block;
}
.booking-product ul li.product {
  width: 70%;
}
.booking-product ul li.product img {
  width: 10px;
  cursor: pointer;
}
.booking-product ul li.top {
  color: #0F5AA4;
  font-weight: 400;
}
.booking-product ul li.product-wide {
  width: 70%;
  padding-left: 10px;
}
.booking-product ul li.price {
  text-align: right;
  width: 10%;
  margin: 0 5%;
  white-space: nowrap;
}
.booking-product ul li.total {
  text-align: right;
  width: 10%;
  white-space: nowrap;
}
.booking-product .total-amount {
  text-align: right;
  float: left;
  width: 100%;
  padding: 5px 0 0 0;
  margin: 5px 0 0 0;
  color: #000;
  font-size: 16px;
}
.booking-product .total-amount span {
  font-size: 20px;
  font-weight: 400;
  padding: 0 0 0 20px;
}
.booking-product .extended_price {
  display: none;
}
#step_2_check_names .header {
  font-weight: bold;
  font-size: 28px;
  margin-top: 5px;
  margin-bottom: 10px;
  white-space: nowrap;
}
#step_2_check_names .description {
  font-size: 14px;
}
#step_2_check_names #names {
  margin-top: 50px;
  margin-bottom: 50px;
}
#step_2_check_names .yd-button-pop {
  margin-left: 10px;
}
#step_2_check_names .names .passenger_header .name {
  float: left;
  font-weight: bold;
  font-size: 16px;
  padding-top: 20px;
  width: 200px;
  padding-bottom: 10px;
}
#step_2_check_names .names .passenger .name {
  float: left;
  width: 200px;
  margin-bottom: 10px;
}
#step_2_check_names .names .passenger .name:last-child {
  margin-bottom: 20px;
}

#step_2_check_names .names .passenger {
  padding-bottom: 2px;
  clear: both;
}
#step_2_check_names .buttons {
  margin-top: 20px;
}
.flight-sc .table-header {
  background-color: #373737;
  display: flex;
}

.yd-button a.back {
  background: url('../general/desktop/images/site/back-white.png') no-repeat right 9px;
}

#org_number {
  clear:both;
}

// look in code (maybe need to move)
.page_step_2_air p {
  color: #2311b2;
}
.booking-mform .title {
  background: #2311b2;
}
.booking-mform input[type="text"] {
  border: 1px solid #2311b2;
  color: #2311b2;
}

.booking-mform textarea {
  resize: none;
  border: 1px solid #2311b2;
  color: #2311b2;
}

.booking-product .pd .table-header {
  background-color: #373737;
}
.booking-product .total-amount {
  border-top: 1px solid #373737;
}
