#selected_flight {
  float: left;
  display: inline-block;
  width: 65%;
  padding-left: 15px;
}

.tip_cabin {
  display: table;
}

.tip_cabin .row {
  display: table-row;
  height: 22px;
}

.tip_cabin .header {
  display: table-row;
  font-weight: 400;
  height: 30px;
}

.tip_cabin .from {
  display: table-cell;
  min-width: 200px;
}

.tip_cabin .to {
  display: table-cell;
  min-width: 200px;
}

.tip_cabin .cabin {
  display: table-cell;
}

.tip_text {
  max-width: 700px;
  white-space: pre-line;
}

.tip_text .header {
  padding-bottom: 10px;
  font-weight: bold;
}

ul.sort_by li.ui-btn-active {
  background-color: #FFFFFF !important;
}

.selected_flight {
  background-color: #FCF8E6;
}

div.baggage_text {
  float: left;
  font-weight: 400;
  padding-bottom: 5px;
}

div.fly-schedule div.baggage {
  float: left;
  clear: left;
}

div.fly-schedule div.baggage div.headline {
  font-weight: bold;
  font-size: 13px;
  float: left;
}

div.fly-schedule div.baggage div.passenger div.leg {
  font-size: 12px;
  width: 170px;
  float: left;
  white-space: nowrap;
  text-wrap: balance;
}

div.fly-schedule div.baggage div.passenger div.leg label {
  white-space: nowrap;
}

div.fly-schedule div.baggage div.passenger div.leg div.ancillary_item {
  display: block;
}

div.fly-schedule-wide div.baggage div.passenger div.leg {
  width: 280px;
}

.fly-schedule-overlay {
  text-align: center;
  position: relative;
  margin: 0 5%;

  .fly-schedule-overlay-content {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 120px;
    left: 0;
    font-weight: 500;
    font-size: 24px;
    line-height: 30px;
    text-shadow: 1px 1px grey;
  }
}

.fly-schedule-remove {
  opacity: 0.2;
  display: none;
}

.fly-schedule-removed_reason {
  background-color: bisque;
  text-align: center;
  font-weight: 400;
  font-size: 15px;
  padding: 4px;
  float: left;
  width: 100%;
  display: none;
}

.fly-schedule-meta {
  border: 1px solid #241EAF;
}

.fly-schedule .flight-message {
  background-color: #241EAF;
  padding: 10px 10px 10px 14px;
  color: #FFFFFF;
  font-size: 25px;
  font-weight: bold;
}

.time-technical-stop {
  color: #241EAF;
}

.flight-technical-stop {
  clear: left;
  padding-left: 0px;
  color: #241EAF;
  font-size: 13px;
}

#flight_rules_loading {
  text-align: center;
  margin-top: 100px;
  background-repeat: no-repeat;
  height: 125px;
  font-size: 20px;
}

a.rules-close-btn {
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  font-size: 18px;
  line-height: 40px;
  padding: 0 10px;
  float: left;
  position: absolute;
  top: 1px;
  right: 20px;
}

.flight-rules-wrap {
  font-family: "Arial", "Verdana", "Helvetica", sans-serif;
  font-size: 12px;
  color: #666666;
}

.flight-rules-wrap strong {
  font-weight: 600;
}

.flight-rules-wrap .rules_box {
  border: 1px solid #CCCCCC;
  width: 500px;
  display: table;
  margin-bottom: 15px;
  padding: 5px;
}

.flight-rules-wrap h1 {
  font-size: 24px;
  font-weight: bold;
  color: #0F1F24;
}

.flight-rules-wrap p {
  font-size: 14px;
  font-weight: normal;
  color: #0F1F24;
}

.flight-rules-wrap a.close-btn {
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  font-size: 18px;
  line-height: 40px;
  padding: 0 10px;
  float: right;
  margin: 15px 0 0;
}

.missing_meta {
  text-align: center;
  font-weight: 500;
  font-size: 18px;
  line-height: 30px;
  color: #000000;
}

.cabin-info {
  font-size: 16px;
  color: #373737;
  font-weight: bold;
}

.cabin-info span {
  font-weight: 100;
}

.cabin-info span.link {
  text-decoration: underline;
  cursor: pointer;
}
