@charset "UTF-8";
.btn {
  display: inline-block;
  font-weight: 400;
  color: #495057;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 0.9375rem;
  line-height: 1.84615;
  border-radius: 3px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .btn {
      transition: none; } }
  .btn:hover {
    color: #495057;
    text-decoration: none; }
  .btn:focus, .btn.focus {
    outline: 0;
    box-shadow: 0 0 0 2px rgba(70, 127, 207, 0.25); }
  .btn.disabled, .btn:disabled {
    opacity: 0.65; }

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none; }

.btn-primary {
  color: #fff;
  background-color: #467fcf;
  border-color: #467fcf; }
  .btn-primary:hover {
    color: #fff;
    background-color: #316cbe;
    border-color: #2f66b3; }
  .btn-primary:focus, .btn-primary.focus {
    box-shadow: 0 0 0 2px rgba(98, 146, 214, 0.5); }
  .btn-primary.disabled, .btn-primary:disabled {
    color: #fff;
    background-color: #467fcf;
    border-color: #467fcf; }
  .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
  .show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #2f66b3;
    border-color: #2c60a9; }
    .btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 2px rgba(98, 146, 214, 0.5); }

.btn-secondary {
  color: #fff;
  background-color: #868e96;
  border-color: #868e96; }
  .btn-secondary:hover {
    color: #fff;
    background-color: #727b84;
    border-color: #6c757d; }
  .btn-secondary:focus, .btn-secondary.focus {
    box-shadow: 0 0 0 2px rgba(152, 159, 166, 0.5); }
  .btn-secondary.disabled, .btn-secondary:disabled {
    color: #fff;
    background-color: #868e96;
    border-color: #868e96; }
  .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #6c757d;
    border-color: #666e76; }
    .btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 2px rgba(152, 159, 166, 0.5); }

.btn-success {
  color: #fff;
  background-color: #5eba00;
  border-color: #5eba00; }
  .btn-success:hover {
    color: #fff;
    background-color: #4b9400;
    border-color: #448700; }
  .btn-success:focus, .btn-success.focus {
    box-shadow: 0 0 0 2px rgba(118, 196, 38, 0.5); }
  .btn-success.disabled, .btn-success:disabled {
    color: #fff;
    background-color: #5eba00;
    border-color: #5eba00; }
  .btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
  .show > .btn-success.dropdown-toggle {
    color: #fff;
    background-color: #448700;
    border-color: #3e7a00; }
    .btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 2px rgba(118, 196, 38, 0.5); }

.btn-info {
  color: #fff;
  background-color: #45aaf2;
  border-color: #45aaf2; }
  .btn-info:hover {
    color: #fff;
    background-color: #219af0;
    border-color: #1594ef; }
  .btn-info:focus, .btn-info.focus {
    box-shadow: 0 0 0 2px rgba(97, 183, 244, 0.5); }
  .btn-info.disabled, .btn-info:disabled {
    color: #fff;
    background-color: #45aaf2;
    border-color: #45aaf2; }
  .btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
  .show > .btn-info.dropdown-toggle {
    color: #fff;
    background-color: #1594ef;
    border-color: #108ee7; }
    .btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 2px rgba(97, 183, 244, 0.5); }

.btn-warning {
  color: #fff;
  background-color: #f1c40f;
  border-color: #f1c40f; }
  .btn-warning:hover {
    color: #fff;
    background-color: #cea70c;
    border-color: #c29d0b; }
  .btn-warning:focus, .btn-warning.focus {
    box-shadow: 0 0 0 2px rgba(243, 205, 51, 0.5); }
  .btn-warning.disabled, .btn-warning:disabled {
    color: #fff;
    background-color: #f1c40f;
    border-color: #f1c40f; }
  .btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
  .show > .btn-warning.dropdown-toggle {
    color: #fff;
    background-color: #c29d0b;
    border-color: #b6940b; }
    .btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 2px rgba(243, 205, 51, 0.5); }

.btn-danger {
  color: #fff;
  background-color: #cd201f;
  border-color: #cd201f; }
  .btn-danger:hover {
    color: #fff;
    background-color: #ac1b1a;
    border-color: #a11918; }
  .btn-danger:focus, .btn-danger.focus {
    box-shadow: 0 0 0 2px rgba(213, 65, 65, 0.5); }
  .btn-danger.disabled, .btn-danger:disabled {
    color: #fff;
    background-color: #cd201f;
    border-color: #cd201f; }
  .btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
  .show > .btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #a11918;
    border-color: #961717; }
    .btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 2px rgba(213, 65, 65, 0.5); }

.btn-light {
  color: #495057;
  background-color: #f8f9fa;
  border-color: #f8f9fa; }
  .btn-light:hover {
    color: #495057;
    background-color: #e2e6ea;
    border-color: #dae0e5; }
  .btn-light:focus, .btn-light.focus {
    box-shadow: 0 0 0 2px rgba(222, 224, 226, 0.5); }
  .btn-light.disabled, .btn-light:disabled {
    color: #495057;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
  .show > .btn-light.dropdown-toggle {
    color: #495057;
    background-color: #dae0e5;
    border-color: #d3d9df; }
    .btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 2px rgba(222, 224, 226, 0.5); }

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40; }
  .btn-dark:hover {
    color: #fff;
    background-color: #23272b;
    border-color: #1d2124; }
  .btn-dark:focus, .btn-dark.focus {
    box-shadow: 0 0 0 2px rgba(82, 88, 93, 0.5); }
  .btn-dark.disabled, .btn-dark:disabled {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
  .show > .btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #1d2124;
    border-color: #171a1d; }
    .btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 2px rgba(82, 88, 93, 0.5); }

.btn-outline-primary {
  color: #467fcf;
  border-color: #467fcf; }
  .btn-outline-primary:hover {
    color: #fff;
    background-color: #467fcf;
    border-color: #467fcf; }
  .btn-outline-primary:focus, .btn-outline-primary.focus {
    box-shadow: 0 0 0 2px rgba(70, 127, 207, 0.5); }
  .btn-outline-primary.disabled, .btn-outline-primary:disabled {
    color: #467fcf;
    background-color: transparent; }
  .btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: #467fcf;
    border-color: #467fcf; }
    .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 2px rgba(70, 127, 207, 0.5); }

.btn-outline-secondary {
  color: #868e96;
  border-color: #868e96; }
  .btn-outline-secondary:hover {
    color: #fff;
    background-color: #868e96;
    border-color: #868e96; }
  .btn-outline-secondary:focus, .btn-outline-secondary.focus {
    box-shadow: 0 0 0 2px rgba(134, 142, 150, 0.5); }
  .btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
    color: #868e96;
    background-color: transparent; }
  .btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-secondary.dropdown-toggle {
    color: #fff;
    background-color: #868e96;
    border-color: #868e96; }
    .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 2px rgba(134, 142, 150, 0.5); }

.btn-outline-success {
  color: #5eba00;
  border-color: #5eba00; }
  .btn-outline-success:hover {
    color: #fff;
    background-color: #5eba00;
    border-color: #5eba00; }
  .btn-outline-success:focus, .btn-outline-success.focus {
    box-shadow: 0 0 0 2px rgba(94, 186, 0, 0.5); }
  .btn-outline-success.disabled, .btn-outline-success:disabled {
    color: #5eba00;
    background-color: transparent; }
  .btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
  .show > .btn-outline-success.dropdown-toggle {
    color: #fff;
    background-color: #5eba00;
    border-color: #5eba00; }
    .btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 2px rgba(94, 186, 0, 0.5); }

.btn-outline-info {
  color: #45aaf2;
  border-color: #45aaf2; }
  .btn-outline-info:hover {
    color: #fff;
    background-color: #45aaf2;
    border-color: #45aaf2; }
  .btn-outline-info:focus, .btn-outline-info.focus {
    box-shadow: 0 0 0 2px rgba(69, 170, 242, 0.5); }
  .btn-outline-info.disabled, .btn-outline-info:disabled {
    color: #45aaf2;
    background-color: transparent; }
  .btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
  .show > .btn-outline-info.dropdown-toggle {
    color: #fff;
    background-color: #45aaf2;
    border-color: #45aaf2; }
    .btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 2px rgba(69, 170, 242, 0.5); }

.btn-outline-warning {
  color: #f1c40f;
  border-color: #f1c40f; }
  .btn-outline-warning:hover {
    color: #fff;
    background-color: #f1c40f;
    border-color: #f1c40f; }
  .btn-outline-warning:focus, .btn-outline-warning.focus {
    box-shadow: 0 0 0 2px rgba(241, 196, 15, 0.5); }
  .btn-outline-warning.disabled, .btn-outline-warning:disabled {
    color: #f1c40f;
    background-color: transparent; }
  .btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
  .show > .btn-outline-warning.dropdown-toggle {
    color: #fff;
    background-color: #f1c40f;
    border-color: #f1c40f; }
    .btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 2px rgba(241, 196, 15, 0.5); }

.btn-outline-danger {
  color: #cd201f;
  border-color: #cd201f; }
  .btn-outline-danger:hover {
    color: #fff;
    background-color: #cd201f;
    border-color: #cd201f; }
  .btn-outline-danger:focus, .btn-outline-danger.focus {
    box-shadow: 0 0 0 2px rgba(205, 32, 31, 0.5); }
  .btn-outline-danger.disabled, .btn-outline-danger:disabled {
    color: #cd201f;
    background-color: transparent; }
  .btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
  .show > .btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #cd201f;
    border-color: #cd201f; }
    .btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 2px rgba(205, 32, 31, 0.5); }

.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa; }
  .btn-outline-light:hover {
    color: #495057;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-outline-light:focus, .btn-outline-light.focus {
    box-shadow: 0 0 0 2px rgba(248, 249, 250, 0.5); }
  .btn-outline-light.disabled, .btn-outline-light:disabled {
    color: #f8f9fa;
    background-color: transparent; }
  .btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
  .show > .btn-outline-light.dropdown-toggle {
    color: #495057;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
    .btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 2px rgba(248, 249, 250, 0.5); }

.btn-outline-dark {
  color: #343a40;
  border-color: #343a40; }
  .btn-outline-dark:hover {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .btn-outline-dark:focus, .btn-outline-dark.focus {
    box-shadow: 0 0 0 2px rgba(52, 58, 64, 0.5); }
  .btn-outline-dark.disabled, .btn-outline-dark:disabled {
    color: #343a40;
    background-color: transparent; }
  .btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
  .show > .btn-outline-dark.dropdown-toggle {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
    .btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 2px rgba(52, 58, 64, 0.5); }

.btn-link {
  font-weight: 400;
  color: #467fcf;
  text-decoration: none; }
  .btn-link:hover {
    color: #295a9f;
    text-decoration: underline; }
  .btn-link:focus, .btn-link.focus {
    text-decoration: underline;
    box-shadow: none; }
  .btn-link:disabled, .btn-link.disabled {
    color: #868e96;
    pointer-events: none; }

.btn-lg {
  padding: 0.5rem 1rem;
  font-size: 1.125rem;
  line-height: 1.625;
  border-radius: 3px; }

.btn-sm {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.33333;
  border-radius: 3px; }

.btn-block {
  display: block;
  width: 100%; }
  .btn-block + .btn-block {
    margin-top: 0.5rem; }

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%; }

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 40, 100, 0.12);
  border-radius: 3px; }
  .card > hr {
    margin-right: 0;
    margin-left: 0; }
  .card > .list-group:first-child .list-group-item:first-child {
    border-top-left-radius: 3px;
    border-top-right-radius: 3px; }
  .card > .list-group:last-child .list-group-item:last-child {
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px; }

.card-body {
  flex: 1 1 auto;
  padding: 1.5rem; }

.card-title {
  margin-bottom: 1.5rem; }

.card-subtitle {
  margin-top: -0.75rem;
  margin-bottom: 0; }

.card-text:last-child {
  margin-bottom: 0; }

.card-link:hover {
  text-decoration: none; }

.card-link + .card-link {
  margin-left: 1.5rem; }

.card-header {
  padding: 1.5rem 1.5rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 40, 100, 0.12); }
  .card-header:first-child {
    border-radius: calc(3px - 1px) calc(3px - 1px) 0 0; }
  .card-header + .list-group .list-group-item:first-child {
    border-top: 0; }

.card-footer {
  padding: 1.5rem 1.5rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 40, 100, 0.12); }
  .card-footer:last-child {
    border-radius: 0 0 calc(3px - 1px) calc(3px - 1px); }

.card-header-tabs {
  margin-right: -0.75rem;
  margin-bottom: -1.5rem;
  margin-left: -0.75rem;
  border-bottom: 0; }

.card-header-pills {
  margin-right: -0.75rem;
  margin-left: -0.75rem; }

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem; }

.card-img {
  width: 100%;
  border-radius: calc(3px - 1px); }

.card-img-top {
  width: 100%;
  border-top-left-radius: calc(3px - 1px);
  border-top-right-radius: calc(3px - 1px); }

.card-img-bottom {
  width: 100%;
  border-bottom-right-radius: calc(3px - 1px);
  border-bottom-left-radius: calc(3px - 1px); }

.card-deck {
  display: flex;
  flex-direction: column; }
  .card-deck .card {
    margin-bottom: 0.75rem; }
  @media (min-width: 576px) {
    .card-deck {
      flex-flow: row wrap;
      margin-right: -0.75rem;
      margin-left: -0.75rem; }
      .card-deck .card {
        display: flex;
        flex: 1 0 0%;
        flex-direction: column;
        margin-right: 0.75rem;
        margin-bottom: 0;
        margin-left: 0.75rem; } }

.card-group {
  display: flex;
  flex-direction: column; }
  .card-group > .card {
    margin-bottom: 0.75rem; }
  @media (min-width: 576px) {
    .card-group {
      flex-flow: row wrap; }
      .card-group > .card {
        flex: 1 0 0%;
        margin-bottom: 0; }
        .card-group > .card + .card {
          margin-left: 0;
          border-left: 0; }
        .card-group > .card:not(:last-child) {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0; }
          .card-group > .card:not(:last-child) .card-img-top,
          .card-group > .card:not(:last-child) .card-header {
            border-top-right-radius: 0; }
          .card-group > .card:not(:last-child) .card-img-bottom,
          .card-group > .card:not(:last-child) .card-footer {
            border-bottom-right-radius: 0; }
        .card-group > .card:not(:first-child) {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0; }
          .card-group > .card:not(:first-child) .card-img-top,
          .card-group > .card:not(:first-child) .card-header {
            border-top-left-radius: 0; }
          .card-group > .card:not(:first-child) .card-img-bottom,
          .card-group > .card:not(:first-child) .card-footer {
            border-bottom-left-radius: 0; } }

.card-columns .card {
  margin-bottom: 1.5rem; }

@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1; }
    .card-columns .card {
      display: inline-block;
      width: 100%; } }

.accordion > .card {
  overflow: hidden; }
  .accordion > .card:not(:first-of-type) .card-header:first-child {
    border-radius: 0; }
  .accordion > .card:not(:first-of-type):not(:last-of-type) {
    border-bottom: 0;
    border-radius: 0; }
  .accordion > .card:first-of-type {
    border-bottom: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .accordion > .card:last-of-type {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }
  .accordion > .card .card-header {
    margin-bottom: -1px; }

.checkbox-compoent {
  width: 20px;
  height: 20px;
  display: inline-block;
  position: relative;
  z-index: 1;
  top: 3px;
  cursor: pointer;
  background: url("./images/form/checkboxsmall.png") no-repeat; }

.checkbox-compoent:hover {
  background: url("./images/form/checkboxsmall-hover.png") no-repeat; }

.checkbox-compoent.selected {
  background: url("./images/form/checkboxsmall-selected.png") no-repeat; }

.checkbox-compoent input[type="checkbox"] {
  margin: 0;
  position: absolute;
  z-index: 2;
  cursor: pointer;
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  -khtml-opacity: 0;
  -moz-opacity: 0; }

.checkbox-compoent input[type="checkbox"]:focus {
  outline: none; }

/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

.fancybox-container #content {
  float: none; }

.ui-datepicker {
  width: 240px;
  padding: 0 2.5px;
  display: none;
  background: #FFFFFF;
  border: 1px solid #a2a2a2;
  font-size: 18px;
  color: #000;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  font-weight: 400;
  z-index: 100 !important; }

.ui-datepicker-inline {
  width: 100%; }

.ui-datepicker .ui-datepicker-header {
  position: relative;
  padding: .2em 0; }

.ui-datepicker .ui-datepicker-prev, .ui-datepicker .ui-datepicker-next {
  position: absolute;
  width: 30px;
  height: 30px;
  cursor: pointer; }

.ui-datepicker .ui-datepicker-prev-hover, .ui-datepicker .ui-datepicker-next-hover {
  background: #d4d4d4; }

.ui-datepicker .ui-datepicker-prev {
  left: 2px;
  -webkit-border-radius: 3px 0 0 3px;
  -moz-border-radius: 3px 0 0 3px;
  border-radius: 3px 0 0 3px; }

.ui-datepicker .ui-datepicker-next {
  right: 2px;
  -webkit-border-radius: 0px 3px 3px 0px;
  -moz-border-radius: 0px 3px 3px 0px;
  border-radius: 0px 3px 3px 0px; }

.ui-datepicker .ui-datepicker-prev span, .ui-datepicker .ui-datepicker-next span {
  display: block;
  position: absolute;
  text-indent: -99999px;
  width: 8px;
  height: 15px; }

.ui-datepicker .ui-datepicker-prev span {
  margin: 8px 0px 0 10px; }

.ui-datepicker .ui-datepicker-next span {
  margin: 8px 0px 0 12px; }

.ui-datepicker .ui-datepicker-title {
  margin: 0 2em;
  line-height: 1.8em;
  text-align: center; }

.ui-datepicker .ui-datepicker-title select {
  background: transparent;
  width: 60px !important;
  padding: 4px;
  font-size: 16px;
  line-height: normal;
  font-weight: 400;
  border: 0;
  border-radius: 0;
  height: 30px;
  -webkit-appearance: none; }

.ui-datepicker .ui-datepicker-title div {
  width: 74px !important;
  height: 30px;
  overflow: hidden;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  float: left;
  margin: 0 3px; }

.ui-datepicker select.ui-datepicker-month-year {
  width: 100%; }

.ui-datepicker select.ui-datepicker-month, .ui-datepicker select.ui-datepicker-year {
  width: 49%; }

.ui-datepicker table {
  width: 100%;
  border-collapse: collapse;
  margin: 0 0 2.5px 0; }

.ui-datepicker th {
  padding: .2em .3em;
  text-align: center;
  border: 0; }

.ui-datepicker td {
  border: 0;
  padding: 2.5px; }

.ui-datepicker-trigger {
  position: absolute;
  margin: 10px 0 0 -30px; }

.ui-datepicker td span, .ui-datepicker td a {
  display: block;
  padding: .2em;
  text-align: center;
  text-decoration: none;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px; }

.ui-datepicker td span, .ui-datepicker td a:hover {
  background: #d4d4d4; }

.ui-datepicker td span, .ui-datepicker td a.ui-state-highlight {
  color: #FFF; }

.ui-datepicker .ui-datepicker-unselectable .ui-state-default {
  background: #c3c3c3; }

.ui-datepicker .ui-datepicker-buttonpane {
  background-image: none;
  margin: .7em 0 0 0;
  padding: 0 .2em;
  border-left: 0;
  border-right: 0;
  border-bottom: 0; }

.ui-datepicker .ui-datepicker-buttonpane button {
  float: right;
  margin: .5em .2em .4em;
  cursor: pointer;
  padding: .2em .6em .3em .6em;
  width: auto;
  overflow: visible; }

.ui-datepicker .ui-datepicker-buttonpane button.ui-datepicker-current {
  float: left; }

/* with multiple calendars */
.ui-datepicker.ui-datepicker-multi {
  width: auto; }

.ui-datepicker-multi .ui-datepicker-group {
  float: left; }

.ui-datepicker-multi .ui-datepicker-group table {
  width: 95%;
  margin: 0 auto .4em; }

.ui-datepicker-multi-2 .ui-datepicker-group {
  width: 50%; }

.ui-datepicker-multi-3 .ui-datepicker-group {
  width: 33.3%; }

.ui-datepicker-multi-4 .ui-datepicker-group {
  width: 25%; }

.ui-datepicker-multi .ui-datepicker-group-last .ui-datepicker-header {
  border-left-width: 0; }

.ui-datepicker-multi .ui-datepicker-group-middle .ui-datepicker-header {
  border-left-width: 0; }

.ui-datepicker-multi .ui-datepicker-buttonpane {
  clear: left; }

.ui-datepicker-row-break {
  clear: both;
  width: 100%;
  font-size: 0em; }

/* RTL support */
.ui-datepicker-rtl {
  direction: rtl; }

.ui-datepicker-rtl .ui-datepicker-prev {
  right: 2px;
  left: auto; }

.ui-datepicker-rtl .ui-datepicker-next {
  left: 2px;
  right: auto; }

.ui-datepicker-rtl .ui-datepicker-prev:hover {
  right: 1px;
  left: auto; }

.ui-datepicker-rtl .ui-datepicker-next:hover {
  left: 1px;
  right: auto; }

.ui-datepicker-rtl .ui-datepicker-buttonpane {
  clear: right; }

.ui-datepicker-rtl .ui-datepicker-buttonpane button {
  float: left; }

.ui-datepicker-rtl .ui-datepicker-buttonpane button.ui-datepicker-current {
  float: right; }

.ui-datepicker-rtl .ui-datepicker-group {
  float: right; }

.ui-datepicker-rtl .ui-datepicker-group-last .ui-datepicker-header {
  border-right-width: 0;
  border-left-width: 1px; }

.ui-datepicker-rtl .ui-datepicker-group-middle .ui-datepicker-header {
  border-right-width: 0;
  border-left-width: 1px; }

/* IE6 IFRAME FIX (taken from datepicker 1.5.3 */
.ui-datepicker-cover {
  display: none;
  /*sorry for IE5*/
  display/**/: block;
  /*sorry for IE5*/
  position: absolute;
  /*must have*/
  z-index: -1;
  /*must have*/
  filter: mask();
  /*must have*/
  top: -4px;
  /*must have*/
  left: -4px;
  /*must have*/
  width: 200px;
  /*must have*/
  height: 200px;
  /*must have*/ }

.datepicker1 {
  cursor: pointer; }

.continue-button {
  float: right; }

.continue-button .y-button {
  border: 1px solid #949494;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px; }

.continue-button-hotel {
  float: right;
  margin: 10px 0 10px 0; }

.continue-button-hotel .y-button {
  border: 1px solid #949494;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px; }

.continue-button-hotel-gallery {
  float: right;
  margin: 5px 0 10px 0; }

.continue-button-hotel-gallery .y-button {
  border: 1px solid #949494;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px; }

.ynew-button {
  margin-bottom: 5px;
  padding: 0 15px 0 15px;
  border: 1px solid #000;
  border-radius: 3px;
  cursor: pointer;
  display: inline-block;
  float: right; }

.ynew-button a {
  height: 30px;
  line-height: 30px;
  font-size: 16px;
  font-weight: 400;
  color: #FFFFFF; }

.y-button-padding-0-2 {
  padding: 0 2px !important; }

.y-button {
  border-radius: 3px;
  cursor: pointer;
  display: inline-block;
  border: 1px solid #999;
  padding: 0 15px; }
  .y-button a {
    float: left;
    height: 40px;
    line-height: 40px;
    font-size: 18px;
    font-weight: 400; }
    .y-button a.back {
      padding: 0 30px 0 0; }

.cookie-message {
  z-index: 999;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.75);
  bottom: 0;
  left: 0;
  text-align: center;
  color: white;
  padding: 10px;
  height: 40px;
  overflow-wrap: break-word;
  min-width: 260px;
  line-height: 24px;
  border-radius: 0 10px 0 0;
  padding: 5px;
  -webkit-transition: opacity 0.5s ease-in-out;
  -moz-transition: opacity 0.5s ease-in-out;
  transition: opacity 0.5s ease-in-out; }
  .cookie-message span {
    line-height: 32px;
    padding-right: 10px; }

.cookie-message-popup-content {
  width: 600px !important; }

.cookie-message-popup-content h3:first-child {
  font-size: 30px;
  line-height: 40px;
  margin-bottom: 5px; }

.cookie-message-popup-content h3 {
  padding-top: 5px; }

.error-page {
  margin: 2em auto;
  padding: 0;
  text-align: left;
  width: 650px;
  margin: 2em auto;
  padding: 0;
  text-align: left;
  width: 650px;
  border: 5px solid #c4c4c4;
  background-color: #FFF;
  border-bottom-style: solid;
  border-bottom-color: #9f9f9f; }
  .error-page #spacing {
    height: 100px; }
  .error-page h1 {
    padding: .65em 15px;
    color: #453;
    font: bold 20px "Helvetica Neue", Arial, Helvetica, Tahoma, sans-serif;
    background-color: #e9ded5;
    margin-top: 0;
    border-bottom: 1px solid #b8b8b8; }
  .error-page .content {
    padding: 15px;
    text-align: center; }
    .error-page .content img {
      width: 100px;
      display: inline-block; }

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

body {
  min-width: 1260px;
  line-height: 18px;
  font-family: 'Roboto Condensed', sans-serif;
  font-weight: 300;
  color: #000;
  height: 100%;
  font-size: 16px; }

.clear {
  margin: 0;
  padding: 0;
  clear: both; }

.last {
  margin-right: 0 !important; }

a {
  text-decoration: none; }

sub {
  vertical-align: text-bottom;
  font-size: 60%; }

sup {
  vertical-align: text-top;
  font-size: 60%; }

strong,
b {
  font-weight: 400; }

.left {
  float: left; }

.right {
  float: right; }

.wd {
  width: 100%; }

.pd {
  padding: 10px; }

.mrR20 {
  margin-right: 20px; }

.mrL20 {
  margin-left: 20px; }

.pdL10 {
  padding-left: 10px; }

.pdR10 {
  padding-right: 10px; }

.pdL20 {
  padding-left: 20px; }

.pdR20 {
  padding-right: 20px; }

.position-relative {
  position: relative; }

.sectionspace {
  padding-top: 40px; }

.mr0 {
  margin: 0 !important; }

::-ms-clear {
  display: none; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 400;
  line-height: 30px; }

input,
select {
  border: 0;
  border-radius: 3px;
  font-family: 'Roboto Condensed', sans-serif;
  font-weight: 300; }

input:focus,
input:hover {
  background-color: #fdf5ce !important; }

input[type="submit"]:focus,
input[type="submit"]:hover {
  background-color: transparent !important; }

.fancysupport .fancybox-close {
  display: inline; }

.fancyconditions .fancybox-close {
  display: inline; }

.show_as_mobile {
  text-align: center;
  padding: 15px 10px 10px 10px; }

.show_as_mobile img {
  position: relative;
  top: 7px;
  padding-right: 5px; }

.show_as_mobile a {
  color: #000;
  font-size: 20px;
  font-weight: 400; }

.hr {
  height: 1px;
  margin: 0px 1% 0px 1%; }

.back_btn {
  padding: 0 5px 0 0;
  border-radius: 3px;
  cursor: pointer;
  display: inline-block;
  margin-top: -10px; }

.button-end {
  float: right;
  width: 100%;
  margin: 30px 0 0 0; }

.yd-button {
  padding: 0 15px;
  border-radius: 3px;
  cursor: pointer;
  display: inline-block; }

.yd-button a {
  float: left;
  height: 40px;
  line-height: 40px;
  font-size: 18px;
  font-weight: 400;
  color: #FFFFFF; }

.yd-button .back {
  padding: 0 30px 0 0; }

.yd-button .fly {
  padding: 0 40px 0 0;
  color: #FFFFFF; }

.yd-button-pop {
  padding: 0 15px;
  border-radius: 3px;
  cursor: pointer;
  display: inline-block; }

.yd-button-pop a {
  float: left;
  height: 40px;
  line-height: 40px;
  font-size: 18px;
  font-weight: 400;
  color: #FFFFFF; }

.yd-button-pop .back {
  padding: 0 30px 0 0; }

.yd-button-pop .fly {
  padding: 0 0px 0 0;
  color: #FFFFFF; }

.yd-button-pop .hotel {
  padding: 0 40px 0 0;
  color: #FFFFFF; }

/*========== Custom ==============*/
.layout {
  max-width: 1260px;
  width: 98%;
  margin: 0 auto; }

/*========== Navigation ==============*/
nav.mainnav {
  position: relative;
  background-color: #fdf5ce !important;
  top: 20px; }

ul.topnav {
  float: right; }

ul.topnav li {
  float: left;
  margin: 0 0 0 5px; }

ul.topnav li a {
  font-size: 16px;
  text-transform: uppercase;
  padding: 5px 10px;
  border-radius: 3px; }

ul.topnav li a:hover,
ul.topnav li a.active {
  color: #FFF; }

/*========== Navigation ==============*/
#wrap {
  float: left;
  width: 100%; }

.top20 {
  padding-top: 20px; }

.main {
  float: left;
  width: 100%;
  border-radius: 3px;
  padding: 15px 0px 5px 0px; }

.white-bg {
  background: #FFF; }

.service-package-wrap {
  float: left;
  width: 100%; }

.service-package {
  padding: 0 15px; }

.service-package label {
  float: left;
  margin: 0 15px 15px 0px;
  cursor: pointer; }

.service-package label span {
  float: left;
  line-height: 40px;
  padding: 0 0 0 5px;
  font-size: 22px;
  color: #000; }

.custom-radio-search_type {
  width: 40px;
  height: 40px;
  display: inline-block;
  position: relative;
  z-index: 1;
  /*top: 25px;*/
  margin: 0 0 0 5px;
  cursor: pointer;
  float: left; }

.custom-radio-search_type input[type="radio"] {
  margin: 1px;
  position: absolute;
  z-index: 2;
  cursor: pointer;
  outline: none;
  opacity: 0;
  /* CSS hacks for older browsers */
  _noFocusLine: expression(this.hideFocus=true);
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  -khtml-opacity: 0;
  -moz-opacity: 0; }

.booking_information {
  padding: 10px; }

.booking_information img {
  float: right; }

.booking_information .text {
  font-size: 15px; }

.inline-picker {
  width: 100%; }

.search_box {
  position: relative;
  overflow: hidden; }

.search_box_small {
  max-height: 200px;
  overflow: hidden; }

.search_box_overlay {
  height: 100%;
  width: 100%;
  top: 0;
  left: 0px;
  position: absolute;
  opacity: 0.9;
  filter: alpha(opacity=90);
  z-index: 1000;
  background-color: #FFF;
  display: none; }

.search_box_overlay_top {
  height: 100%;
  width: 100%;
  top: 0;
  left: 0px;
  position: absolute;
  z-index: 1001;
  margin-top: 15px;
  font-weight: 400;
  display: none; }

.search_box_overlay_top .title {
  color: #000;
  font-size: 30px;
  line-height: 35px;
  padding: 0 0 30px 0; }

.search_box_overlay_top .title span {
  font-size: 22px;
  display: block;
  padding: 10px 0 0 0; }

.search_box_overlay_top .package {
  display: none; }

.search_box_overlay_top .hotel {
  display: none; }

.search_box_overlay_top .air {
  display: none; }

.tooltipster-red .tooltipster-box {
  background: #FC575E !important;
  border: 2px solid #444 !important;
  border-radius: 6px;
  box-shadow: 2px 2px 2px 0 rgba(0, 0, 0, 0.4); }

.tooltipster-red .tooltipster-content {
  color: #FFF !important;
  padding: 8px; }

.tooltipster-red.tooltipster-top .tooltipster-arrow-background {
  border-top-color: #FC575E; }

.tooltipster-red.tooltipster-left .tooltipster-arrow-background {
  border-left-color: #FC575E; }

.tooltipster-content sub {
  bottom: -0.25em;
  position: relative;
  vertical-align: baseline; }

.main_btn_wide {
  width: 311px; }

.custom-checkbox-search_only_direct_flights,
.custom-checkbox-search_advanced_hotel_search,
.custom-checkbox-search_only_with_bags,
.custom-checkbox-search_max_one_stop,
.custom-checkbox-search_only_private_fares,
.custom-checkbox-search_only_public_fares,
.custom-checkbox-search_no_ndc,
.custom-checkbox-search_only_rebookable {
  width: 40px;
  height: 40px;
  display: inline-block;
  position: relative;
  z-index: 1;
  top: 3px;
  cursor: pointer; }

.custom-checkbox-search_only_direct_flights input[type="checkbox"],
.custom-checkbox-search_advanced_hotel_search input[type="checkbox"],
.custom-checkbox-search_only_with_bags input[type="checkbox"],
.custom-checkbox-search_max_one_stop input[type="checkbox"],
.custom-checkbox-search_only_private_fares input[type="checkbox"],
.custom-checkbox-search_only_public_fares input[type="checkbox"],
.custom-checkbox-search_no_ndc input[type="checkbox"],
.custom-checkbox-search_only_rebookable input[type="checkbox"] {
  margin: 0;
  position: absolute;
  z-index: 2;
  cursor: pointer;
  outline: none;
  opacity: 0;
  /* CSS hacks for older browsers */
  _noFocusLine: expression(this.hideFocus=true);
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  -khtml-opacity: 0;
  -moz-opacity: 0; }

.fly-form ul li.direct-flight span {
  float: left;
  line-height: 40px;
  padding: 0 0 0 5px;
  cursor: pointer; }

button[type="submit"], input[type="submit"] {
  height: 40px;
  font-size: 18px;
  cursor: pointer;
  font-weight: 400; }

.disable {
  opacity: 0.5; }

div.yellow-submit {
  padding: 0 10px;
  border-radius: 3px;
  border: 1px solid #999; }

.f-scroll-wrap {
  border-radius: 3px;
  float: right;
  width: 24%;
  padding: 0 0 10px 0;
  margin: 0 15px 15px 0; }

.f-scroll-title {
  border-radius: 3px 3px 0 0;
  font-size: 20px;
  text-transform: uppercase;
  font-weight: 400;
  display: block;
  padding: 10px;
  margin: 0 0 10px 0; }

.f-scroll-title span {
  font-size: 14px;
  font-weight: 300;
  text-transform: none;
  display: block; }

.f-scroll {
  width: 100%;
  float: left; }

.f-scroll ul {
  padding: 0 6px; }

.f-scroll ul li {
  list-type: none;
  margin: 0;
  float: left;
  width: 100%; }

.f-scroll ul li a {
  border-radius: 3px;
  margin: 5px 0;
  float: left;
  width: 100%; }

.f-scroll ul li div {
  font-size: 16px;
  padding: 8px 10px; }

.f-scroll ul li div strong {
  font-weight: 400; }

.f-scroll ul li div span {
  color: #c2c2c2;
  font-size: 12px;
  display: block; }

.f-scroll ul li div.left {
  width: 60%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.f-scroll ul li div.right {
  width: 40%;
  text-align: right;
  padding-top: 10px; }

.youth-age {
  width: 100%;
  text-align: center;
  padding-top: 6px;
  font-size: 14px; }

#youth-tickets-info {
  width: 100%;
  padding: 6px 0 8px 4px;
  font-size: 16px;
  display: none; }

.covid-container {
  display: block;
  font-size: 16px;
  margin: 0 30px; }
  .covid-container .bottom-text {
    text-align: right; }
  .covid-container .top-paragraph,
  .covid-container .bottom-text {
    margin-top: 20px; }

.destination-container {
  display: block;
  font-size: 16px;
  margin: 0 30px;
  margin-bottom: 60px; }
  .destination-container p {
    margin-bottom: 10px; }

.about-sec {
  float: left;
  width: 69%;
  padding: 20px 0; }

.about-sec .image {
  float: left;
  margin: 0 20px 0 0;
  padding: 0 0 0 15px;
  width: 30%; }

.about-sec img {
  border-radius: 3px;
  width: 100%; }

.about-sec div.con {
  font-size: 16px;
  padding: 0 15px 0 0;
  float: right;
  width: 66%; }

.about-sec div.con .title,
.covid-container .title {
  color: #000;
  font-size: 30px;
  padding: 0 0 30px 0; }

.about-sec div.con .title span {
  font-size: 22px;
  display: block;
  padding: 10px 0 0 0; }

.shortcon {
  float: left;
  width: 100%; }

.shortcon ul {
  font-size: 0;
  text-align: center;
  margin: 20px 15px 0 15px; }

.shortcon ul li {
  display: inline-block;
  vertical-align: top;
  border-radius: 3px;
  font-size: 14px;
  color: #000000;
  line-height: 18px;
  text-align: left;
  width: 24.2%;
  padding: 1%;
  /* padding percentage height doesnt work in firefox.... */
  padding-top: 10px;
  padding-bottom: 10px;
  margin: 0 1% 20px 0;
  min-height: 125px; }

.shortcon ul li + li + li + li {
  margin-right: 0; }

.shortcon ul li div.title {
  font-size: 18px;
  font-weight: 400;
  float: left;
  width: 100%;
  margin: 0 0 10px 0; }

.mainbanner {
  float: left;
  width: 100%;
  text-align: center; }

.mainbanner div.space {
  padding: 0 15px; }

.mainbanner img {
  border-radius: 3px;
  width: 100%; }

.cheapest-destinations {
  float: left;
  width: 100%;
  margin: 20px 0 0 0; }

.cheapest-destinations > ul {
  font-size: 0;
  text-align: center;
  margin: 0 15px; }

.cheapest-destinations > ul > li {
  display: inline-block;
  vertical-align: top;
  border-radius: 3px;
  font-size: 14px;
  color: #000000;
  line-height: 18px;
  text-align: left;
  width: 24.2%;
  padding: 1%;
  margin: 0 1% 15px 0;
  min-height: 395px; }

.cheapest-destinations > ul > li + li + li + li {
  margin-right: 0; }

.cheapest-destinations > ul > li div.title {
  border-radius: 3px;
  color: #FFF;
  text-align: center;
  padding: 10px 5px;
  font-size: 20px;
  margin: 0 0 15px 0; }

ul.cd-list {
  float: left;
  width: 100%; }

ul.cd-list li {
  float: left;
  width: 100%;
  border-bottom: 1px dashed #d8f2ff; }

ul.cd-list li:last-child {
  border: 0; }

ul.cd-list li a {
  display: block;
  color: #000;
  padding: 1px 0; }

ul.cd-list li a:hover {
  background: #f4f4f4; }

ul.cd-list li span {
  text-align: right;
  float: right; }

.footer-logo {
  display: block;
  padding: 15px; }
  .footer-logo ul {
    border-radius: 3px;
    text-align: center; }
    .footer-logo ul li {
      display: inline-block;
      margin: 10px 15px; }

.footer-bottom {
  float: left;
  width: 100%;
  font-size: 12px;
  font-weight: 300 !important;
  color: #000;
  text-transform: uppercase; }

ul.footer-menu {
  float: left;
  margin: 0 0 0 15px; }

ul.footer-menu li {
  float: left;
  margin: 0 5px 0 0;
  padding: 0 5px 0 0; }

ul.footer-menu li:last-child {
  background: none;
  margin: 0;
  padding: 0; }

ul.footer-menu li a {
  color: #000; }

ul.footer-menu li a:hover {
  text-decoration: underline; }

div.sidebyside {
  display: inline-block;
  width: 135px; }

.copyright {
  float: right;
  margin: 0 15px 0 0; }

#searching {
  margin-top: 15px;
  border: 1px solid #949494;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px; }

#searching .title {
  color: #000;
  font-size: 30px;
  line-height: 35px;
  padding: 0 0 30px 0; }

#searching .title span {
  font-size: 22px;
  display: block;
  padding: 10px 0 0 0; }

.flight {
  text-align: center; }

.fly-form ul li label.room-icon span {
  height: 44px;
  line-height: 44px;
  padding: 0 0 0 45px;
  margin-bottom: 12px;
  display: block; }

.fly-form ul.smallrow li {
  width: 21.2%; }

.fly-form ul.smallrow li.xsmall {
  width: 12.7%; }

.fly-form ul.smallrow li.xsmall-first {
  width: 12.7%; }

.fly-form ul.smallrow li.xmed {
  min-width: 155px; }

.fly-form ul.smallrow li.xbig {
  min-width: 206px; }

.fly-form ul.smallrow li.roomlist {
  width: 13%;
  margin-right: 2%; }

.fly-form ul.smallrow li.roomlist select {
  width: 30%;
  float: left; }

.fly-form ul.smallrow li.roomlist label {
  float: left; }

.fly-form ul.smallrow li + li + li {
  margin: 0 5% 0 0; }

.fly-form ul.smallrow li + li + li + li {
  margin-right: 0; }

.room-number {
  padding: 10px;
  display: block; }

.clr:before,
.clr:after {
  content: "";
  display: table; }

.clr:after {
  clear: both; }

/* Contact Page
--------------------------------------*/
#container {
  padding: 0 15px; }

#content {
  width: 944px; }

#sidebar {
  width: 256px;
  float: right; }

#content h1 {
  font-size: 36px;
  margin-bottom: 20px; }

#content h4 {
  font-size: 18px;
  color: #000;
  text-decoration: underline; }

#content p {
  margin-bottom: 20px;
  line-height: 22px; }

#content .addressmap {
  height: 494px;
  width: 100%;
  float: left;
  margin: 0; }

#content ul.list {
  margin-bottom: 20px;
  margin-left: 15px;
  line-height: 22px; }

#content ul.list {
  list-style-type: disc; }

#content ul.list ul.sublist {
  padding-left: 10px;
  list-style-type: circle; }

ul.condition > li {
  border-bottom: 2px solid #f7f7f7;
  margin-bottom: 20px; }

ul.condition > li:last-child {
  border: none;
  margin-bottom: 0; }

.ui-state-focus {
  background: #d4d4d4; }

/*-------------------------------------- Sidebar */
#sidebar .searchflyform {
  border-radius: 3px;
  background: #f1f1f1;
  padding: 12px;
  width: 100%;
  float: left; }

#sidebar .title {
  border-radius: 3px;
  color: #FFF;
  font-size: 18px;
  padding: 10px; }

#sidebar .searchflyform ul li {
  width: 100%;
  margin: 12px 0 0 0;
  float: left; }

#sidebar .searchflyform input[type="text"] {
  width: 100%;
  height: 38px;
  float: left;
  background: #FFF;
  font-size: 18px;
  text-transform: uppercase;
  padding: 0 15px 0 40px;
  line-height: 38px; }

#sidebar .searchflyform a {
  font-size: 18px;
  text-decoration: underline; }

#sidebar .searchflyform a:hover {
  text-decoration: none; }

#sidebar .ui-selectmenu-menu li a,
#sidebar .ui-selectmenu-status {
  padding-left: 40px; }

#sidebar .addressbox {
  width: 100%;
  color: #fff;
  padding: 10px 15px 5px 15px;
  float: right;
  font-size: 18px;
  margin: 20px 0 0 0;
  border-radius: 3px; }

#sidebar .addressbox p {
  margin-bottom: 10px;
  padding: 0 0 0 26px;
  line-height: normal;
  color: #FFF; }

#sidebar .addressbox p.email a {
  color: #fff; }

#sidebar .addressbox p.phone a[href^=tel] {
  color: inherit;
  text-decoration: none; }

#sidebar .addressbox b {
  color: #eccc00; }

.ui-selectmenu-menu .select-airline {
  width: 302px !important; }

.select-airline .ui-selectmenu-status {
  background: url("./images/site/blue-fly.png") #FFF no-repeat 10px center; }

/*-------------------------------------- beforetrip */
.secureuserimg {
  float: left;
  width: 10%; }

.secureusercontent {
  float: right;
  width: 85%; }

.aeimg {
  float: left;
  width: 30%; }

.aeimg img {
  margin-bottom: 25px; }

.aecontent {
  float: right;
  width: 65%; }

/*-------------------------------------- Warning MSG */
.warningmsg {
  text-align: center;
  float: left;
  width: 100%;
  border: 4px solid #CCC;
  padding: 40px 20px;
  background: #FFF; }

.warningmsg h1 {
  font-size: 36px;
  margin: 20px 0; }

.warningmsg h3 {
  font-size: 20px;
  color: #666; }

.warningmsg ul {
  width: 100%;
  display: block;
  font-size: 0;
  margin: 50px 0 0 0; }

.warningmsg ul li {
  display: inline-block;
  vertical-align: top;
  padding: 0 30px;
  font-size: 20px;
  font-weight: 400;
  line-height: 20px;
  border-right: 1px solid #ececec; }

.warningmsg ul li:last-child {
  border: none; }

.warningmsg ul li .version {
  color: #666;
  font-weight: 300;
  font-size: 14px; }

/*-------------------------------------- Top Search */
.ui-autocomplete {
  z-index: 11; }

.message-panel {
  z-index: 9999;
  position: fixed;
  background-color: rgba(255, 0, 0, 0.75);
  bottom: 0;
  left: 0;
  text-align: center;
  color: white;
  padding: 10px;
  height: 125px;
  overflow-wrap: break-word;
  width: 400px;
  line-height: 24px;
  border-radius: 0 10px 0 0;
  -webkit-transition: opacity 0.5s ease-in-out;
  -moz-transition: opacity 0.5s ease-in-out;
  transition: opacity 0.5s ease-in-out; }

.autocomplete-container.ui-menu-item-wrapper {
  padding: 5px 10px; }

.ui-menu .ui-widget .ui-widget-content .ui-autocomplete .ui-front {
  height: auto; }

.autocomplete-container.ui-menu-item-wrapper.ui-state-active {
  color: #000000;
  margin: 0; }

.searchfield .container {
  font-family: 'Roboto Condensed', sans-serif;
  display: inline-block; }

.searchfield .name {
  font-family: 'Roboto Condensed', sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px; }

.searchfield .country {
  font-family: 'Roboto Condensed', sans-serif;
  display: block;
  font-size: 14px;
  line-height: 18px;
  font-weight: 300; }

.searchfield .iata {
  font-family: 'Roboto Condensed', sans-serif;
  float: right;
  padding-right: 10px;
  padding-top: 10px;
  padding-bottom: 14px;
  vertical-align: middle;
  font-size: 16px;
  line-height: 18px;
  font-weight: 300;
  font-style: normal; }

.searchfield.cityairport .container {
  padding-left: 20px !important; }

.noselect {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                  supported by Chrome and Opera */ }

.inline-block {
  width: 100%;
  display: inline-block; }

.inline-block-no-width {
  display: inline-block; }

.price_calculation_air_window .fancybox-content {
  padding: 0px !important; }

.price-cal-wrap {
  width: 1000px !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
  padding-bottom: 0 !important; }
  .price-cal-wrap .price-cal {
    width: 100%; }
    .price-cal-wrap .price-cal thead {
      background-color: #eccc00; }
      .price-cal-wrap .price-cal thead th {
        padding: 14px 20px; }
    .price-cal-wrap .price-cal tbody td {
      padding: 10px 20px; }
    .price-cal-wrap .price-cal tbody td.subtotal, .price-cal-wrap .price-cal tbody td.summery-price {
      text-align: right; }
  .price-cal-wrap div.travelers {
    padding: 20px 30px;
    text-align: center; }

.page_about .about {
  padding: 0 15px; }
  .page_about .about__content {
    display: inline-block; }
    .page_about .about__content h1 {
      font-size: 36px;
      margin-bottom: 20px; }
    .page_about .about__content p {
      margin-bottom: 20px;
      line-height: 22px; }

.page_contact {
  margin-bottom: 15px;
  margin-top: 15px; }
  .page_contact .contact {
    padding: 0 15px;
    display: flex; }
    .page_contact .contact__content {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between; }
      .page_contact .contact__content .addressmap {
        border-top: 1px solid #2012aa;
        height: 500px;
        padding-top: 16px;
        width: calc(100% - 16px); }
      .page_contact .contact__content h1 {
        font-size: 36px;
        margin: 16px 0 24px; }
      .page_contact .contact__content p {
        line-height: 22px;
        margin-bottom: 24px;
        max-width: 70%; }
      .page_contact .contact__content a {
        color: #2012aa; }
    .page_contact .contact__sidebar {
      flex: 1;
      flex-basis: 256px;
      flex-grow: 0;
      flex-shrink: 0; }

.banners_horizontal {
  padding-bottom: 15px; }

.banners_horizontal img {
  border-radius: 5px; }

.right_banner {
  float: right;
  padding-bottom: 15px; }
  .right_banner img {
    border-radius: 5px; }

.filter_banner {
  background: url("./images/site/filter__sv-SE.png") no-repeat;
  width: 278px;
  height: 358px;
  clear: both;
  border-radius: 3px; }
  .filter_banner.sv-SE {
    background: url("./images/site/filter__sv-SE.png") no-repeat; }
  .filter_banner.da-DK {
    background: url("./images/site/filter__da-DK.png") no-repeat; }
  .filter_banner.nb-NO {
    background: url("./images/site/filter__nb-NO.png") no-repeat; }

.sponsor {
  float: left;
  padding-right: 5px;
  padding-bottom: 15px;
  line-height: 40px;
  font-weight: 400; }

.ui-slider {
  height: 12px;
  position: relative;
  text-align: left;
  border-radius: 10px;
  width: 93%;
  margin-left: 3px; }

.ui-slider-handle {
  cursor: pointer;
  width: 20px;
  height: 20px;
  position: absolute;
  z-index: 2;
  border-radius: 25px;
  top: -4px;
  margin-left: -5px; }

.ui-slider-range {
  position: absolute;
  border: 0;
  top: 0;
  height: 100%;
  border-radius: 25px;
  width: 100%; }

.Switch {
  position: relative;
  display: inline-block;
  font-size: 11px;
  font-weight: bold;
  height: 21px;
  border-radius: 3px;
  cursor: pointer;
  padding: 2px 3px 3px; }

.Switch span {
  display: inline-block;
  width: 17px; }

.Switch .Toggle {
  position: absolute;
  top: 2px;
  width: 20px;
  height: 17px;
  border-radius: 3px;
  z-index: 8;
  -webkit-transition: all 0.15s ease-in-out;
  -moz-transition: all 0.15s ease-in-out;
  -o-transition: all 0.15s ease-in-out;
  -ms-transition: all 0.15s ease-in-out; }

.Switch.On .Toggle {
  left: 50%; }

.Switch.Off .Toggle {
  left: 4%; }

.rangeslider {
  border-radius: 0 0 3px 3px !important; }

.rangeslider ul li {
  width: 100%;
  float: left;
  margin-bottom: 15px; }

.rangeslider .caption {
  margin-bottom: 30px; }

.rangeslider label {
  font-size: 14px;
  padding-right: 4px; }

.rangeslider input:hover[type="text"],
.rangeslider input:focus[type="text"] {
  background: none !important; }

.filter {
  color: #FFFFFF; }
  .filter .filter-section {
    width: 100%;
    border-radius: 3px;
    margin: 0 0 1px;
    background-color: #2520AF; }
    .filter .filter-section .filter-header .filter-header-text {
      display: inline-block; }
      .filter .filter-section .filter-header .filter-header-text span,
      .filter .filter-section .filter-header .filter-header-text small {
        display: block; }
      .filter .filter-section .filter-header .filter-header-text small {
        font-size: 75%;
        color: lightgray; }
    .filter .filter-section .filter-header .filter-header-actions {
      float: right;
      display: block; }
    .filter .filter-section .filter-content {
      padding: 0 20px; }
    .filter .filter-section.filter-collapsable .filter-header {
      display: block;
      padding-left: 35px;
      color: #ECCC00; }
      .filter .filter-section.filter-collapsable .filter-header:hover {
        border-radius: 3px;
        background-color: #3B36D9; }
  .filter .filter-section.filter-section-main .filter-header {
    border-radius: 3px;
    background-color: #1866AA;
    padding: 10px 10px 5px 10px; }
  .filter .filter-section.filter-section-main .filter-content {
    padding: 10px 20px; }
  .filter .filter-closed .filter-header {
    background: url("./images/site/white-right-arrow.png") 0 no-repeat;
    background-position-x: 15px; }
  .filter .filter-closed .filter-content {
    display: none; }
  .filter .filter-open .filter-header {
    background: url("./images/site/white-down-arrow.png") 0 no-repeat;
    background-position-x: 15px; }
  .filter .filter-open {
    padding-bottom: 15px; }
  .filter .filter-header {
    padding: 10px; }
  .filter .ui-slider {
    width: 100%;
    margin: 0; }
  .filter .select-links {
    font-size: 12px;
    display: block;
    position: relative;
    top: -12px;
    right: 5px; }
  .filter .filter-label-group > .filter-label:first-child {
    text-align: left; }
  .filter .filter-label {
    text-align: right;
    font-size: 12px; }
  .filter ul li {
    padding: 5px 0; }
  .filter table {
    width: 100%; }
    .filter table td {
      padding: 5px 0; }
  .filter input[type="button"] {
    border: none;
    border-radius: 3px;
    padding: 3px 8px;
    cursor: pointer;
    float: right; }
  .filter .hotelname {
    background-color: #818181;
    float: left;
    border: 1px solid #D5D5D5;
    width: 100%;
    padding: 5px;
    font-size: 16px;
    text-align: left; }
  .filter #filter_price_txt_max {
    float: right; }

.searchfilter #mapview .map-preview {
  overflow: hidden; }

.tripadvisor_powered_by {
  margin-left: 10px;
  padding-top: 10px;
  text-align: right;
  font-size: 12px; }
  .tripadvisor_powered_by .tripadvisor_powered_by_image,
  .tripadvisor_powered_by .tripadvisor_powered_by_text {
    display: inline-block; }
  .tripadvisor_powered_by .tripadvisor_powered_by_image {
    width: 60%;
    text-align: center; }
    .tripadvisor_powered_by .tripadvisor_powered_by_image img {
      width: 100%; }
  .tripadvisor_powered_by .tripadvisor_powered_by_text {
    width: 39%;
    float: left; }

.fake-filter {
  height: 600px;
  background-color: #2824b7;
  border-radius: 3px;
  padding-top: 20px; }
  .fake-filter .fake-filter-header:first-child {
    margin-top: 0; }
  .fake-filter .fake-filter-header,
  .fake-filter .fake-filter-item {
    width: 80%;
    height: 24px;
    background-color: #2f29d1;
    margin-left: 20px;
    margin-top: 40px; }
  .fake-filter .fake-filter-header {
    margin-left: 20px;
    margin-top: 40px; }
  .fake-filter .fake-filter-item {
    margin-left: 30px;
    margin-top: 20px; }

.ui-slider-max-value .ui-slider-range {
  left: 0%;
  width: 100%; }

.ui-slider-max-value span:first-of-type {
  left: 0%; }

.ui-slider-max-value span:last-of-type {
  left: 100%; }

/* Base code for tabs with bootstrap */
.flight-rules-wrap {
  /* Lets just get the ticket rules working */ }
  .flight-rules-wrap .nav-link.active {
    color: #495057;
    background-color: #fff;
    border-color: #dee2e6 #dee2e6 #fff; }
  .flight-rules-wrap .nav-tabs .nav-link {
    border: 1px solid transparent;
    border-top-left-radius: .25rem;
    border-top-right-radius: .25rem; }
  .flight-rules-wrap .nav-tabs .nav-item {
    margin-bottom: -1px; }
  .flight-rules-wrap .nav-link {
    display: block;
    padding: .5rem 1rem; }
  .flight-rules-wrap .tab-content > .tab-pane.active {
    display: block; }
  .flight-rules-wrap .tab-content > .tab-pane {
    display: none; }
  .flight-rules-wrap .nav {
    margin-bottom: 10px; }
  .flight-rules-wrap .nav-item {
    width: 49%;
    display: inline-block;
    border: 1px solid black;
    text-align: center;
    font-size: 18px; }
  .flight-rules-wrap .nav-link {
    color: #495057; }
  .flight-rules-wrap .nav-item .active {
    background-color: lightgray; }

div.fly-schedule div.baggage {
  float: left;
  clear: left; }

div.fly-schedule div.baggage div.headline {
  font-weight: bold;
  font-size: 13px;
  float: left; }

div.fly-schedule div.baggage div.passengers {
  margin-left: 15px;
  display: inline-block; }

div.fly-schedule div.baggage div.passenger {
  padding-bottom: 2px;
  clear: left; }

div.fly-schedule div.baggage div.passenger_number {
  float: left;
  font-size: 12px;
  width: 95px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden; }

div.fly-schedule div.baggage div.passenger div.leg {
  font-size: 12px;
  float: left;
  white-space: nowrap;
  text-wrap: balance;
  margin-right: 10px; }

div.fly-schedule div.baggage div.passenger div.leg label {
  white-space: nowrap; }

div.fly-schedule div.baggage div.passenger div.leg div.ancillary_item {
  display: block; }

.fly-schedule .baggage .passenger .leg {
  user-select: none; }

.fly-schedule {
  float: left;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  background: #FFF;
  font-size: 16px;
  color: #000;
  margin: 0 0 15px;
  border: 1px solid #949494;
  width: 100%; }

.selectdate {
  width: 30%;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between; }

.fly-schedule-inner {
  padding: 15px;
  display: inline-block;
  width: 100%; }
  .fly-schedule-inner.allotment {
    background: url("./images/site/bubbles.svg") no-repeat center;
    background-size: cover; }
    .fly-schedule-inner.allotment .allotment-wrap {
      margin-top: 5px; }
      .fly-schedule-inner.allotment .allotment-wrap p {
        font-family: 'Roboto Condensed', sans-serif !important;
        font-size: 16px !important;
        margin-bottom: 32px; }
        .fly-schedule-inner.allotment .allotment-wrap p b {
          font-weight: bold; }
          .fly-schedule-inner.allotment .allotment-wrap p b.dates-highlight {
            padding: 4px;
            border-radius: 2px;
            background: #2128a8;
            color: #fff;
            text-transform: uppercase; }
        .fly-schedule-inner.allotment .allotment-wrap p .price-tag {
          float: right; }
          .fly-schedule-inner.allotment .allotment-wrap p .price-tag u {
            font-weight: bold; }
    .fly-schedule-inner.allotment .flight-sc-layout {
      background: #fff; }
  .fly-schedule-inner .schedule_change_warning {
    color: red; }

.fly-schedule-full {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                  supported by Chrome and Opera */ }
  .fly-schedule-full .fly-schedule-inner {
    opacity: 0.15;
    pointer-events: none; }

.fly-schedule-wide {
  width: 100%; }
  .fly-schedule-wide .fly-schedule-inner {
    width: 100%; }
  .fly-schedule-wide .time-box {
    cursor: auto;
    width: 93.2%; }
    .fly-schedule-wide .time-box .time-1 {
      width: 13.6%; }
    .fly-schedule-wide .time-box .time-dep_airport {
      width: 21.8%; }
    .fly-schedule-wide .time-box .time-arr_airport {
      width: 21.8%; }
    .fly-schedule-wide .time-box .time-1,
    .fly-schedule-wide .time-box .time-2,
    .fly-schedule-wide .time-box .time-carrier,
    .fly-schedule-wide .time-box .time-travel_time {
      cursor: default; }
  .fly-schedule-wide .time-travel_time {
    font-size: 14px; }

.time-box.first .time-dep_airport,
.time-box.last .time-arr_airport {
  font-weight: bold; }

.time-box {
  cursor: pointer;
  float: left;
  width: 92%; }
  .time-box.change-airport {
    background-color: #f0f0f0;
    width: 103%;
    margin: 5px -10px;
    text-align: center;
    padding: 5px;
    font-size: 14px; }
    .time-box.change-airport img {
      width: 10px;
      margin-right: 2px; }
  .time-box.change-airport-itinerary {
    margin-top: -10px; }
  .time-box .time-baggage {
    float: left;
    font-size: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 55px; }
    .time-box .time-baggage span.not_included {
      text-decoration: underline;
      cursor: pointer; }
  .time-box .new_time {
    color: #FF0000; }
  .time-box .old_time {
    color: #000000;
    text-decoration: line-through; }
  .time-box .time-1 {
    float: left;
    font-size: 14px;
    clear: left;
    width: 13%; }
  .time-box .time-dep_airport {
    float: left;
    font-size: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 22.8%; }
  .time-box .airport_change {
    color: #241EAF; }
  .time-box .time-2 {
    float: left;
    font-size: 14px;
    width: 13%; }
  .time-box .time-arr_airport {
    float: left;
    font-size: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 22.8%; }
  .time-box .time-carrier {
    float: left;
    font-size: 14px;
    width: 11.2%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; }
  .time-box .time-cabin {
    float: left;
    font-size: 14px;
    width: 7.2%; }
  .time-box .time-flightnumber {
    float: left;
    font-size: 14px;
    width: 5.7%; }

.step_1_air_form .time-box .time-carrier {
  width: 12.3%; }

.step_1_air_form .time-box .time-flightnumber {
  width: 7.4%; }

.flight-wrap {
  display: table;
  width: 100%;
  padding-bottom: 8px; }
  .flight-wrap .flight {
    float: left;
    width: 24%; }
  .flight-wrap .flight .name {
    font-size: 17px;
    color: #000000;
    padding-top: 13px;
    float: left;
    padding-left: 10px; }
  .flight-wrap .flight img {
    width: 30px;
    margin-top: 7px;
    float: left; }
  .flight-wrap .extra-links {
    float: left; }
  .flight-wrap .flight-rules {
    float: right;
    margin-top: 14px; }
    .flight-wrap .flight-rules img {
      width: 15px;
      margin-right: 3px; }
    .flight-wrap .flight-rules a {
      font-size: 14px;
      color: #212121; }
  .flight-wrap .flydetail {
    float: right;
    margin-top: 14px;
    padding-right: 8px; }
  .flight-wrap .flydetail img {
    width: 15px;
    margin-right: 3px; }
  .flight-wrap .flydetail a {
    font-size: 14px;
    color: #212121; }
  .flight-wrap .flydetail .youth_price {
    font-size: 14px;
    color: #212121; }
  .flight-wrap .spinner {
    float: right;
    margin-left: 40px;
    margin-right: 5px;
    margin-top: 5px;
    width: 80px; }
  .flight-wrap .price-box {
    float: right;
    text-align: right;
    margin-top: 6px; }
  .flight-wrap .resurs {
    float: left;
    margin-top: -3px;
    padding-right: 15px;
    color: black;
    text-decoration: underline; }
    .flight-wrap .resurs .resurs_text {
      text-align: left;
      font-size: 13px;
      font-weight: 200; }
    .flight-wrap .resurs .resurs_price {
      font-size: 16px; }
  .flight-wrap .price {
    font-size: 18px;
    text-align: right;
    margin-bottom: 4px;
    float: right; }
  .flight-wrap .smallprice {
    font-size: 13px;
    font-weight: 200;
    text-align: right;
    float: right;
    clear: right; }

.places-left {
  font-weight: bold;
  font-size: 14px;
  color: red;
  margin-top: -10px; }

.price_type {
  font-weight: bold;
  font-size: 14px;
  color: blue;
  margin-top: -10px;
  float: right; }

.airport_change_new {
  color: #000;
  font-weight: 400;
  margin: -5px 0 10px 0;
  font-size: 12px;
  padding: 3px;
  background-color: #EEE;
  text-align: center; }

.airport_change_new_intern {
  color: #000;
  font-weight: 400;
  margin: 5px 0 5px -12px;
  font-size: 12px;
  padding: 3px;
  background-color: #EEE;
  text-align: center;
  width: 111%;
  display: inline-block;
  float: left;
  line-height: 20px; }

.multiple_tickets {
  font-size: 13px;
  font-weight: 800;
  background: bisque;
  color: black;
  padding-left: 5px;
  width: max-content;
  min-width: 230px;
  margin-bottom: 10px;
  cursor: pointer; }
  .multiple_tickets img {
    width: 10px;
    margin-right: 2px; }

.text-info {
  font-size: 13px;
  font-weight: 400;
  background: #5b2929;
  color: white;
  margin-top: 5px;
  padding: 3px;
  padding-left: 10px;
  padding-right: 10px;
  width: max-content;
  margin-bottom: 10px;
  cursor: pointer;
  border: 1px solid #000000;
  border-radius: 3px; }

.price-box .total-price {
  float: left; }

.price-box .pax-price {
  float: left;
  padding-right: 20px;
  margin-top: 5px; }

.price-box .pax-price .price {
  font-size: 16px; }

.price-box .pax-price .smallprice {
  font-size: 12px;
  margin-top: -8px; }

.flight-sc {
  font-size: 16px;
  line-height: normal;
  overflow: auto;
  margin-top: 8px;
  margin-bottom: 5px; }

.flight-sc-no-top-margin {
  margin-top: 0px; }

.flight-sc-last {
  margin-top: 5px;
  margin-bottom: 0px; }

.flight-sc ul {
  margin: 0 0 5px;
  display: block;
  width: 100%;
  float: left; }

.flight-sc ul li {
  float: left;
  padding-bottom: 8px; }

.flight-sc div.divider {
  float: left;
  width: 100%;
  height: 20px; }

.flight-sc .table-header {
  border: 1px solid #696969; }

.flight-sc .table-header li {
  color: #ffffff;
  padding: 2px 2px 2px 5px;
  font-size: 14px;
  border-right: 1px solid #717171; }

.flight-sc .table-header li.empty {
  width: 3%; }

.flight-sc .table-header li.li_1 {
  width: 13%; }

.flight-sc .table-header li.li_2 {
  width: 20%; }

.flight-sc .table-header li.li_3 {
  width: 12%; }

.flight-sc .table-header li.li_4 {
  width: 19.5%; }

.fly-schedule-wide .flight-sc .table-header li.li_5 {
  width: 10%; }

.flight-sc .table-header li.li_5 {
  width: 12%; }

.fly-schedule-wide .flight-sc .table-header li.li_6 {
  width: 5%; }

.flight-sc .table-header li.li_6 {
  width: 6.7%; }

.fly-schedule-wide .flight-sc .table-header li.li_7 {
  width: 5.2%; }

.flight-sc .table-header li.li_7 {
  width: 7.8%; }

.flight-sc .table-header li.li_8 {
  border-right: none; }

.fly-schedule-wide .flight-sc .table-header li.li_9 {
  width: 7%; }

.flight-sc-layout {
  width: 100%;
  border: 1px solid #b9b9b9;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  margin-bottom: 5px;
  padding: 10px;
  overflow-x: hidden; }

.flight-sc-layout .none {
  display: none; }

.cabin-info {
  font-size: 16px;
  color: #373737;
  font-weight: bold; }

.cabin-info span {
  font-weight: 100; }

.cabin-info span.link {
  text-decoration: underline;
  cursor: pointer; }

.tip_cabin {
  display: table; }

.tip_cabin .row {
  display: table-row;
  height: 22px; }

.tip_cabin .header {
  display: table-row;
  font-weight: 400;
  height: 30px; }

.tip_cabin .from {
  display: table-cell;
  min-width: 200px; }

.tip_cabin .to {
  display: table-cell;
  min-width: 200px; }

.tip_cabin .cabin {
  display: table-cell; }

.tip_text {
  max-width: 700px;
  white-space: pre-line; }

.tip_text .header {
  padding-bottom: 10px;
  font-weight: bold; }

.flight-technical-stop {
  clear: left;
  padding-left: 20px;
  padding-top: 6px;
  color: #241EAF;
  font-size: 13px; }

#selected_flight {
  float: left;
  display: inline-block;
  width: 65%;
  padding-left: 15px; }

.itinerary_no_bags .table-header li.li_1 {
  width: 13%; }

.itinerary_no_bags .table-header li.li_2 {
  width: 22.5%; }

.itinerary_no_bags .table-header li.li_3 {
  width: 11.9%; }

.itinerary_no_bags .table-header li.li_4 {
  width: 22.9%; }

.itinerary_no_bags .table-header li.li_5 {
  width: 12.5%; }

.itinerary_no_bags .table-header li.li_6 {
  width: 7.4%; }

.itinerary_no_bags .time-box .time-1 {
  width: 13.4%; }

.itinerary_no_bags .time-box .time-dep_airport,
.itinerary_no_bags .time-box .time-arr_airport {
  width: 25%; }

.itinerary_no_bags .time-box .time-2 {
  width: 13.5%; }

.itinerary_no_bags .time-box .time-carrier {
  width: 14.7%; }

.itinerary .climate {
  background-color: #90f0b4;
  border-radius: 5px;
  font-size: 13px;
  width: max-content;
  margin-bottom: 10px;
  padding-right: 5px; }
  .itinerary .climate sub {
    bottom: -0.25em;
    position: relative;
    vertical-align: baseline; }

.itinerary .cfar {
  background-color: #ffb800;
  border-radius: 5px;
  font-size: 13px;
  width: max-content;
  margin-bottom: 10px;
  padding: 2px 5px 2px 5px; }
  .itinerary .cfar sub {
    bottom: -0.25em;
    position: relative;
    vertical-align: baseline; }

.offer_hotel_addon {
  width: 100%;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  background: #FFF;
  border: 1px solid #949494;
  min-height: 62px;
  float: left;
  padding: 10px;
  margin-bottom: 10px; }

.offer_hotel_addon .body {
  float: left;
  font-size: 24px;
  line-height: 24px;
  padding: 12px 0;
  width: 85%; }

.offer_hotel_addon .y-button {
  float: right;
  border: 1px solid #949494;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px; }

.hotel_info {
  width: 800px;
  font-family: "Helvetica Neue", Arial, Helvetica, Geneva, sans-serif;
  padding-right: 5px;
  padding-left: 5px; }

.hotel_info .name_group {
  float: left;
  margin-bottom: 15px;
  width: 475px; }

.hotel_info .name {
  float: left;
  padding-right: 10px;
  padding-top: 2px;
  font-weight: bold;
  font-size: 24px;
  padding-bottom: 5px; }

.hotel_info .address {
  clear: left; }

.hotel_info #map-canvas {
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  border: 1px solid #949494;
  float: right;
  margin: 10px 0 15px 15px; }

.hotel_info a {
  color: #000000; }

.hotel_info a:hover {
  text-decoration: underline; }

.hotel_info .top {
  display: inline-block; }

.hotel_info .description {
  text-align: justify;
  font-size: 12px; }

.hotel_info .facilities {
  margin-top: 10px;
  margin-left: 20px; }

.hotel_info .facilities ul {
  list-style-type: square; }

.hotel_info .facilities li {
  float: left;
  padding-bottom: 6px;
  font-size: 14px;
  width: 33%; }

.hotel_info .image_wrap .image {
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  border: 1px solid #949494;
  margin-bottom: 12px; }

.hotel_info .image_wrap .image:nth-child(odd) {
  float: left; }

.hotel_info .image_wrap .image:nth-child(even) {
  float: right; }

.hotel_info hr {
  clear: left;
  margin-bottom: 10px; }

.hotel_result .rooms {
  clear: right; }

.hotel_result .dates {
  font-size: 14px;
  padding-top: 10px;
  font-weight: 400; }

.hotel_result .description {
  margin-top: 5px;
  display: inline-block; }

.hotel_result .description a {
  color: #241EAF; }

.hotel_result .description a:hover {
  text-decoration: underline; }

.hotel_result .price-box-hotel {
  float: right;
  padding-top: 8px; }

.hotel_result .resurs {
  float: left;
  margin-top: -3px;
  padding-right: 15px; }
  .hotel_result .resurs a {
    color: black;
    text-decoration: underline; }
  .hotel_result .resurs .resurs_text {
    text-align: left;
    font-size: 15px;
    font-weight: 200; }
  .hotel_result .resurs .resurs_price {
    font-size: 20px; }

.hotel_result {
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  background: #FFF;
  font-size: 16px;
  color: #000;
  margin: 0 0 15px;
  border: 1px solid #949494;
  clear: left;
  width: 100%;
  height: 225px;
  float: left;
  display: table;
  padding-bottom: 3px; }

.hotel_result .image_wrap {
  padding: 10px;
  float: left;
  width: 270px; }

.hotel_result .image {
  overflow: hidden;
  float: left;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  border: 1px solid #949494;
  width: 250px;
  height: 200px; }

.hotel_result .other_images {
  clear: left;
  width: 256px; }

.hotel_result .small_image {
  overflow: hidden;
  width: 63px;
  height: 40px;
  float: left;
  padding-top: 3px; }

.hotel_result .information {
  padding: 10px;
  margin-left: 260px; }

.hotel_result .name {
  font: 22px "Helvetica Neue", Arial, Helvetica, Geneva, sans-serif;
  color: #241EAF;
  float: left;
  padding-right: 8px;
  padding-bottom: 0px;
  max-width: 340px;
  cursor: pointer; }

.hotel_result .address {
  cursor: pointer;
  clear: left;
  font-size: 12px;
  padding-top: 5px; }

.hotel_result .address a {
  color: #000000; }

.hotel_result .address a:hover {
  text-decoration: underline; }

.hotel_result .address img {
  width: 18px;
  padding-left: 5px; }

.hotel_result .rating {
  float: left; }

.hotel_result .name_group {
  float: left;
  max-width: 285px; }

.hotel_result .hotel_clear {
  clear: right; }

.hotel_result .tripadvisor {
  float: right;
  padding-left: 30px;
  width: 173px;
  font-size: 14px; }

.hotel_result .price {
  font-size: 30px;
  margin-bottom: 4px; }

.hotel_result .smallprice {
  font-size: 15px;
  float: right; }

.hotel_result .rooms .room .headline {
  font-size: 14px;
  font-weight: bold;
  padding-top: 10px; }

.hotel_result .rooms .room .headline .pax-text {
  font-weight: normal;
  display: inline;
  color: #a0a0a0;
  font-size: 12px;
  padding-left: 5px; }

.hotel_result .options {
  font-size: 16px;
  line-height: normal;
  overflow: auto; }

.hotel_result .options ul {
  display: block;
  width: 100%; }

.hotel_result .options .table {
  display: block;
  width: 100%;
  border: 1px solid #b9b9b9;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  margin-bottom: 5px;
  cursor: pointer; }

.hotel_result .options .table:last-child {
  margin-bottom: 0; }

.hotel_result .options .table li {
  display: inline-block; }

.hotel_result .options .table li.radio {
  width: 3.5%;
  padding: 8px; }

.hotel_result .options .table li.li_1 {
  width: 46%;
  padding: 8px; }

.hotel_result .options .table li.li_2 {
  width: 31%;
  padding: 8px; }

.hotel_result .options .table li.li_3 {
  width: 17%;
  border-right: none;
  padding: 8px;
  text-align: right;
  white-space: nowrap; }

.hotel_result .selected_hotel {
  background-color: #fcf8e6; }

.hotel_result_gallery {
  width: 77%;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  background: #FFF;
  font-size: 16px;
  color: #000;
  margin: 0 0 15px;
  border: 1px solid #949494;
  clear: left;
  height: 225px;
  float: left;
  display: table;
  padding-bottom: 3px;
  padding-right: 10px; }

.hotel_result_gallery .image_wrap {
  padding: 5px 0px 5px 10px;
  float: left; }

.hotel_result_gallery .image {
  overflow: hidden;
  float: left;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  border: 1px solid #949494;
  margin: 1px;
  width: 235px;
  height: 200px; }

.hotel_result_gallery .information {
  float: left;
  width: 100%;
  padding: 10px 10px 0; }

.hotel_result_gallery .name {
  font: 22px "Helvetica Neue", Arial, Helvetica, Geneva, sans-serif;
  color: #241EAF;
  float: left;
  padding-right: 8px;
  padding-bottom: 0px;
  max-width: 340px;
  cursor: pointer; }

.hotel_result_gallery .address {
  cursor: pointer;
  clear: left;
  font-size: 12px;
  padding-top: 5px; }

.hotel_result_gallery .address a {
  color: #000000; }

.hotel_result_gallery .address a:hover {
  text-decoration: underline; }

.hotel_result_gallery .address img {
  width: 18px;
  padding-left: 5px; }

.hotel_result_gallery .rating {
  float: left; }

.hotel_result_gallery .name_group {
  float: left;
  max-width: 340px; }

.hotel_result_gallery .price-box-hotel {
  float: right;
  padding-top: 8px; }

.hotel_result_gallery .resurs {
  float: left;
  margin-top: -3px; }

.hotel_result_gallery .resurs_text {
  font-size: 15px; }

.hotel_result_gallery .resurs_price {
  padding-right: 30px;
  font-size: 20px; }

.hotel_result_gallery .price {
  font-size: 30px;
  margin-bottom: 4px; }

.hotel_result_gallery .smallprice {
  font-size: 15px;
  float: right; }

.hotel_result_gallery .more_info {
  padding: 10px; }

.hotel_result_gallery .rooms .room .headline {
  font-size: 14px;
  font-weight: bold;
  padding-top: 10px; }

.hotel_result_gallery .rooms .room .headline .pax-text {
  font-weight: normal;
  display: inline;
  color: #a0a0a0;
  font-size: 12px;
  padding-left: 5px; }

.hotel_result_gallery .description {
  clear: left; }

.hotel_result_gallery .options {
  font-size: 16px;
  line-height: normal;
  overflow: auto;
  padding-top: 10px; }

.hotel_result_gallery .options ul {
  margin: 0 0 5px;
  display: block;
  width: 100%;
  float: left; }

.hotel_result_gallery .options li {
  float: left; }

.hotel_result_gallery .options .table-header {
  background-color: #373737;
  border: 1px solid #696969; }

.hotel_result_gallery .options .table-header li {
  color: #ffffff;
  padding: 2px 2px 2px 5px;
  font-size: 14px;
  border-right: 1px solid #717171; }

.hotel_result_gallery .options .table-header li.empty {
  width: 4%; }

.hotel_result_gallery .options .table-header li.li_1 {
  width: 46%; }

.hotel_result_gallery .options .table-header li.li_2 {
  width: 31%; }

.hotel_result_gallery .options .table-header li.li_3 {
  width: 18%;
  border-right: none; }

.hotel_result_gallery .options .table {
  width: 100%;
  border: 1px solid #b9b9b9;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  margin-bottom: 5px;
  cursor: pointer; }

.hotel_result_gallery .options .table li.radio  {
  width: 3.5%;
  padding: 8px; }

.hotel_result_gallery .options .table li.li_1 {
  width: 46%;
  padding: 8px; }

.hotel_result_gallery .options .table li.li_2 {
  width: 31%;
  padding: 8px; }

.hotel_result_gallery .options .table li.li_3 {
  width: 17%;
  border-right: none;
  padding: 8px;
  white-space: nowrap; }

.hotel_result_gallery .selected_hotel {
  background-color: #fcf8e6; }

.hotel_info hr {
  clear: left;
  margin-bottom: 10px; }

.hotel_info .tripadvisor {
  width: 100%; }
  .hotel_info .tripadvisor .tripadvisor_image {
    float: left; }
  .hotel_info .tripadvisor .tripadvisor_image .tripadvisor_image_text {
    font-size: 14px;
    display: inline-block;
    float: right;
    padding-top: 2px;
    padding-left: 5px; }
  .hotel_info .tripadvisor .tripadvisor_logo {
    float: right;
    width: 120px; }
  .hotel_info .tripadvisor .tripadvisor_logo img {
    width: 100%; }
  .hotel_info .tripadvisor .tripadvisor_logo .tripadvisor_logo_text {
    font-size: 12px; }
  .hotel_info .tripadvisor .tripadvisor_ranking {
    clear: left;
    font-weight: bold;
    font-size: 12px; }
  .hotel_info .tripadvisor .tripadvisor_ratings {
    padding-top: 10px;
    font-size: 14px;
    font-weight: bold;
    float: left; }
  .hotel_info .tripadvisor .tripadvisor_ratings .ratings {
    display: table;
    padding-top: 5px;
    font-weight: normal;
    font-size: 12px; }
  .hotel_info .tripadvisor .tripadvisor_ratings .ratings .rating {
    display: table-row; }
  .hotel_info .tripadvisor .tripadvisor_ratings .ratings .rating_name {
    display: table-cell;
    width: 100px; }
  .hotel_info .tripadvisor .tripadvisor_ratings .ratings .rating_value {
    display: table-cell; }
  .hotel_info .tripadvisor .rating_counts {
    display: table;
    padding-top: 35px;
    font-size: 12px;
    float: right; }
  .hotel_info .tripadvisor .rating_counts .rating_count {
    display: table-row; }
  .hotel_info .tripadvisor .rating_counts .rating_count .rating_count_name {
    display: table-cell;
    width: 100px;
    float: left; }
  .hotel_info .tripadvisor .rating_counts .rating_count .rating_box {
    display: table-cell; }
  .hotel_info .tripadvisor .rating_counts .rating_count .rating_box .rating_box_background {
    border: 1px solid #000000;
    width: 100px;
    height: 15px;
    float: left; }
  .hotel_info .tripadvisor .rating_counts .rating_count .rating_box .rating_box_fill {
    background-color: #5B9546;
    height: 13px; }
  .hotel_info .tripadvisor .rating_counts .rating_count .rating_box .rating_box_value {
    float: right;
    width: 35px;
    padding-left: 5px;
    margin-top: -2px; }
  .hotel_info .tripadvisor .latest_reviews {
    clear: left;
    font-size: 14px;
    font-weight: bold;
    padding-top: 15px;
    display: table; }
  .hotel_info .tripadvisor .latest_reviews .review {
    font-weight: normal;
    font-size: 12px;
    clear: left;
    padding-bottom: 15px; }
  .hotel_info .tripadvisor .latest_reviews .review:first-child {
    padding-top: 10px; }
  .hotel_info .tripadvisor .latest_reviews .review .reviewer {
    float: left;
    width: 150px;
    min-height: 60px; }
  .hotel_info .tripadvisor .latest_reviews .review .reviewer .reviewer_name {
    font-weight: bold;
    height: 20px; }
  .hotel_info .tripadvisor .latest_reviews .review .reviewer .reviewer_text {
    font-weight: bold; }
  .hotel_info .tripadvisor .latest_reviews .review .review_body {
    margin-left: 160px; }
  .hotel_info .tripadvisor .latest_reviews .review .review_body .review_body_headline {
    font-size: 16px;
    font-weight: bold;
    font-style: italic; }
  .hotel_info .tripadvisor .latest_reviews .review .review_body .review_body_rating {
    float: left;
    padding-right: 5px; }
  .hotel_info .tripadvisor .latest_reviews .review .review_body .review_body_rating img {
    width: 50px;
    padding-top: 4px; }
  .hotel_info .tripadvisor .latest_reviews .review .review_body .review_body_date {
    color: #888888; }
  .hotel_info .tripadvisor .latest_reviews .review .review_body .review_body_text a {
    text-decoration: underline; }
  .hotel_info .tripadvisor .tripadvisor_final_links {
    margin-left: 160px;
    font-size: 12px;
    float: left; }
  .hotel_info .tripadvisor .tripadvisor_final_links a {
    text-decoration: underline; }
  .hotel_info .tripadvisor .tripadvisor_final_text {
    font-size: 10px;
    color: #888888;
    text-align: right; }

.fake-itinerary {
  width: 100%;
  background-color: #FFF;
  border: 1px solid #949494;
  height: 290px;
  border-radius: 3px;
  margin: 0 0 15px; }

.fake-itinerary-airline,
.fake-itinerary-price,
.fake-itinerary-button {
  display: inline-block;
  margin: 10px;
  width: 120px;
  height: 40px; }

.fake-itinerary-price,
.fake-itinerary-button {
  width: 200px;
  float: right; }

.fake-itinerary-container {
  padding: 10px; }

.fake-itinerary-itinerary {
  width: 100%; }

.fake-itinerary-itinerary tr {
  height: 28px; }

div.fake-itinerary-br {
  height: 20px; }

tr.fake-itinerary-br {
  height: 5px; }

td.fake-itinerary-br {
  width: 20px; }

.topsearch {
  background-color: #FFFFFF;
  padding: 15px 0 5px;
  margin-bottom: 5px;
  display: inline-block;
  border: 1px solid #949494;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  width: 100%; }
  .topsearch .init {
    width: 100%;
    display: inline-block;
    padding: 0 15px 5px 20px; }
    .topsearch .init .search_text {
      display: inline;
      font-size: 25px;
      line-height: 35px; }
      .topsearch .init .search_text .bold {
        font-weight: 400; }
    .topsearch .init .buttons {
      position: relative;
      display: inline-block;
      float: right; }
  .topsearch .elements {
    display: none; }

.page_step_1_air_small {
  margin-top: -15px; }

.overlay {
  opacity: 0.3;
  filter: alpha(opacity=30);
  pointer-events: none; }

.clicked_hotel {
  margin-top: 10px; }

div.fancyclose a.fancybox-close,
div.fancybox-type-image a.fancybox-close {
  display: inherit; }

.filter ul li span.filter_value {
  padding-left: 0px; }

.map_frame {
  width: 77%; }

#map_canvas {
  height: 600px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  border: 1px solid #949494; }

.map_info {
  width: 300px;
  height: 40px;
  line-height: 1.35;
  overflow: hidden;
  white-space: nowrap; }

.map_info .image {
  float: left;
  padding-right: 5px; }

.map_info .image img {
  max-width: 60px;
  max-height: 60px; }

.map_info .stars img {
  width: 10px;
  height: 10px;
  float: left; }

.map_info .name {
  white-space: nowrap;
  font-weight: bold; }

.map_info .price {
  float: left;
  padding-left: 10px;
  line-height: 11px; }

.blue-title {
  float: left;
  width: 100%;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  line-height: normal;
  font-size: 30px;
  margin: 0 0 10px 0;
  padding: 10px 0 15px 0; }

.blue-title .small {
  float: left;
  width: 100%;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  line-height: normal;
  font-size: 18px;
  margin: 0 0 10px 0;
  padding: 5px 0 0px 0; }

.yellow-title .small {
  float: left;
  width: 100%;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  line-height: normal;
  font-size: 18px;
  margin: 0 0 10px 0;
  padding: 5px 0 0px 0; }

.blue-title .depart {
  float: left;
  padding: 0 0 0 40px; }

.blue-title .return {
  float: right;
  padding: 0 0 0 40px; }

.right_banner {
  float: right;
  padding-bottom: 15px; }
  .right_banner img {
    border-radius: 5px; }

.missing_meta {
  text-align: center;
  font-weight: 500;
  font-size: 18px;
  line-height: 30px;
  color: #000; }

.fly-schedule-remove {
  opacity: 0.2;
  display: none; }

.fly-schedule-removed_reason {
  background-color: bisque;
  text-align: center;
  font-weight: 400;
  font-size: 15px;
  padding: 4px;
  float: left;
  width: 100%;
  display: none; }

.fly-schedule-meta {
  border: 1px solid #241EAF; }

.fly-schedule .flight-message {
  background-color: #241EAF;
  padding: 10px 10px 10px 14px;
  color: #ffffff;
  font-size: 25px;
  font-weight: bold; }

div.more-search-options {
  float: left;
  width: 45%;
  text-align: right;
  margin-top: 2px; }

div.more-search-options a {
  color: #000000;
  text-decoration: underline; }

.time-technical-stop {
  color: #241EAF; }

.top-field {
  display: inline-block;
  width: 100%; }

.tripadvisor .tripadvisor_image {
  float: left; }

.tripadvisor .tripadvisor_rating {
  float: right;
  font-weight: bold;
  font-size: 14px;
  padding-top: 1px; }

.tripadvisor .tripadvisor_reviews {
  font-size: 13px;
  clear: both; }

.tripadvisor .tripadvisor_reviews a {
  color: #000000;
  text-decoration: underline; }

.price-box-hotel .total-price {
  float: left; }

.price-box-hotel .pax-price {
  display: none;
  float: left;
  padding-right: 30px;
  margin-top: 5px; }

.price-box-hotel .pax-price .price {
  font-size: 20px; }

.price-box-hotel .pax-price .smallprice {
  font-size: 12px;
  margin-top: -8px; }

.departure-title {
  padding: 0;
  float: left; }

.arrival-title {
  padding: 0; }

div.fastest {
  color: #000000; }

.time-radio {
  float: left;
  margin-right: 5px;
  margin-top: 2px; }

.time-extra_info {
  color: #939393;
  font-size: 12px;
  float: left;
  padding-top: 4px; }

.long_connection_icon {
  width: 10px;
  margin-left: 2px; }

.departure-airline {
  color: #000000;
  font-size: 14px;
  float: left;
  padding-left: 10px;
  padding-top: 2px; }

.your_pick {
  color: #ffffff;
  font-weight: bold;
  font-size: 25px;
  padding-top: 5px; }

.bag-text {
  color: #212121;
  font-size: 14px;
  float: left;
  padding-left: 15px;
  margin-top: 8px;
  width: 50%; }

.bag-text-bottom {
  color: #212121;
  font-size: 14px;
  float: left;
  padding-left: 15px;
  margin-top: 0px;
  width: 65%; }

.selected_flight {
  background-color: #fcf8e6; }

div.baggage_text {
  float: left;
  font-weight: 400;
  padding-bottom: 5px; }

div.fly-schedule-wide div.baggage div.passenger div.leg {
  width: 280px; }

#flight_rules_loading {
  text-align: center;
  margin-top: 100px;
  background-repeat: no-repeat;
  height: 125px;
  font-size: 20px; }

a.rules-close-btn {
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  font-size: 18px;
  line-height: 40px;
  padding: 0 10px;
  float: left;
  position: absolute;
  top: 1px;
  right: 20px; }

.price-cal {
  font-size: 18px;
  color: #000;
  font-weight: 400; }

.price-cal ul {
  float: left;
  width: 100%; }

.price-cal ul li {
  float: left;
  text-align: right;
  padding: 8px 10px; }

.price-cal ul.price-cal-title {
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  padding: 3px 0;
  margin: 0 0 5px 0; }

.price-cal ul li.travelers {
  width: 35%;
  text-align: left; }

.price-cal ul li.number {
  width: 10%; }

.price-cal ul li.flights-price {
  width: 15%; }

.price-cal ul li.tax {
  width: 20%; }

.price-cal ul li.subtotal {
  width: 20%; }

.price-cal-wrap .hr {
  margin: 10px 0; }

.price-cal-wrap .total-text {
  float: left;
  font-size: 24px;
  font-weight: 400;
  color: #000; }

.price-cal-wrap .total {
  float: right;
  font-size: 24px;
  font-weight: 400;
  color: #000; }

.price-cal-wrap a.close-btn {
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  font-size: 18px;
  line-height: 40px;
  padding: 0 10px;
  float: right;
  margin: 15px 0 0 0; }

.flight-rules-wrap {
  font-family: Arial, Verdana, Helvetica, sans-serif;
  font-size: 12px;
  color: #666666; }

.flight-rules-wrap strong {
  font-weight: 600; }

.flight-rules-wrap .rules_box {
  border: 1px solid #CCC;
  width: 500px;
  display: table;
  margin-bottom: 15px;
  padding: 5px; }

.flight-rules-wrap h1 {
  font-size: 24px;
  font-weight: bold;
  color: #0f1f24; }

.flight-rules-wrap p {
  font-size: 14px;
  font-weight: normal;
  color: #0f1f24; }

.flight-rules-wrap a.close-btn {
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  font-size: 18px;
  line-height: 40px;
  padding: 0 10px;
  float: right;
  margin: 15px 0 0 0; }

#error_screen {
  width: auto; }

#error_screen a.close-btn {
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  font-size: 18px;
  line-height: 40px;
  padding: 0 10px;
  float: right;
  margin: 15px 0 0 0; }

.spinner {
  padding: 4px 0; }

.seat-info {
  color: #dc0000;
  font-weight: bold; }

.price-calculation {
  font-size: 18px;
  color: #000;
  font-weight: 400; }

.price-calculation ul {
  float: left;
  width: 100%; }

.price-calculation ul li {
  float: left;
  margin: 0 20px;
  text-align: right;
  padding: 8px 0; }

.price-calculation ul.price-cal-title {
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  padding: 3px 0;
  margin: 0 0 5px 0; }

.price-calculation ul li.number {
  width: 25px;
  text-align: left; }

.price-calculation ul li.travelers {
  text-align: left;
  width: 140px; }

.price-calculation ul li.flights-dec {
  text-align: left;
  width: 535px; }

.price-calculation ul li.subtotal {
  width: 90px;
  float: right;
  text-align: right; }

.price-calculation-wrap .hr {
  margin: 10px 0; }

.price-calculation-wrap .total-text {
  float: left;
  font-size: 24px;
  font-weight: 400;
  color: #000; }

.price-calculation-wrap .total {
  float: right;
  font-size: 24px;
  font-weight: 400;
  color: #000; }

.price-calculation-wrap a.close-btn {
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  font-size: 18px;
  line-height: 40px;
  padding: 0 10px;
  float: right;
  margin: 15px 0 0 0; }

.ui-slider {
  height: 12px;
  position: relative;
  text-align: left;
  border-radius: 10px; }

.ui-slider-handle {
  cursor: pointer;
  width: 20px;
  height: 20px;
  position: absolute;
  z-index: 2;
  border-radius: 25px;
  top: -4px;
  margin-left: -5px; }

.ui-slider-range {
  position: absolute;
  border: 0;
  top: 0;
  height: 100%;
  border-radius: 25px;
  width: 100%; }

.switchfilter {
  border-radius: 0 0 3px 3px; }

.switchfilter ul li {
  float: left;
  margin: 0 0px 10px 0;
  width: 100%; }

.switchfilter ul li .caption {
  display: block;
  margin: 0 10px 0 0;
  width: 160px;
  float: left;
  padding: 2px 0; }

.sponsor {
  float: left;
  padding-right: 5px;
  padding-bottom: 15px; }

.ui-slider {
  width: 93%;
  margin-left: 3px; }

.airlinefilter {
  padding-bottom: 30px; }

.airlinefilter ul {
  width: 100%;
  float: left; }

.airlinefilter ul li {
  float: left;
  font-size: 16px;
  margin: 7px 0 0;
  width: 100%; }

.airlinefilter ul li span {
  padding-left: 10px; }

.connectionfilter {
  padding-bottom: 30px; }

.connectionfilter ul {
  width: 100%;
  float: left; }

.connectionfilter ul li {
  float: left;
  font-size: 16px;
  margin: 7px 0 0;
  width: 100%; }

.connectionfilter ul li span {
  padding-left: 10px; }

.location_name {
  display: inline-block;
  padding-right: 3px;
  max-width: 79%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding-left: 10px;
  line-height: 14px;
  position: relative;
  top: 4px;
  line-height: 18px; }

.location_count {
  display: inline; }

/*=== Dropdown Menu */
a.paxmenu {
  font-size: 18px;
  border-radius: 3px;
  line-height: normal;
  padding: 0 10px;
  width: 100%;
  height: 38px;
  float: left;
  cursor: pointer;
  line-height: 36px;
  text-transform: uppercase;
  font-weight: 400; }

a.paxmenu img {
  float: right;
  margin: 10px 0 0 0; }

ul.paxsection {
  position: absolute;
  top: 40px;
  right: 15px;
  width: 120%;
  z-index: 1;
  display: none; }

ul.paxsection li {
  border-radius: 3px;
  padding: 5px 10px;
  float: left;
  width: 100%;
  height: 38px;
  line-height: 25px;
  font-size: 18px;
  font-weight: 400;
  text-transform: uppercase; }

ul.paxsection li img {
  float: left;
  margin: 0 15px 0 0; }

ul.paxsection li .countersec img {
  cursor: pointer;
  float: left;
  margin: 3px 0; }

ul.paxsection li span {
  float: left; }

ul.paxsection li .countersec {
  float: right; }

ul.paxsection li span {
  width: 30px;
  text-align: center; }

/*=== Dropdown Menu */
.view-buttons {
  clear: left; }

.y-img-button a.list_view {
  padding: 0 35px 0 0; }

.y-img-button a.gallery_view {
  padding: 0 35px 0 0; }

.y-img-button a.map_view {
  padding: 0 35px 0 0; }

.y-img-button {
  cursor: pointer;
  padding: 0 15px;
  border-radius: 3px;
  cursor: pointer;
  display: inline-block;
  border: 1px solid #949494;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  margin-bottom: 10px;
  margin-right: 5px; }

.y-img-button a {
  float: left;
  height: 40px;
  line-height: 40px;
  font-size: 18px;
  font-weight: 400;
  color: #2311b2; }

.sponsor {
  float: left;
  padding-right: 5px;
  line-height: 40px;
  padding-bottom: 15px;
  font-weight: 400; }

.sort_by_group .sort_by li {
  float: left;
  width: 33.33%;
  border: 1px solid #949494;
  border-right: none;
  padding: 5px;
  text-align: center;
  margin-bottom: 10px;
  font-weight: 300;
  line-height: 20px;
  cursor: pointer; }
  .sort_by_group .sort_by li.ui-btn-active {
    font-weight: 400; }
  .sort_by_group .sort_by li:first-of-type {
    -webkit-border-right-radius: 3px 0px 0px 3px;
    -moz-border-right-radius: 3px 0px 0px 3px;
    border-radius: 3px 0px 0px 3px; }
  .sort_by_group .sort_by li:last-of-type {
    border-right: 1px solid #949494;
    -webkit-border-right-radius: 0px 3px 3px 0px;
    -moz-border-right-radius: 0px 3px 3px 0px;
    border-radius: 0px 3px 3px 0px; }

.sort_by_group .sort_by .small_text {
  font-size: 10px;
  line-height: 10px; }

.sort_by_group .sort_by li:nth-last-child(1),
.sort_by_group .sort_by li:nth-last-child(1) ~ li {
  width: 100%; }

.sort_by_group .sort_by li:nth-last-child(2),
.sort_by_group .sort_by li:nth-last-child(2) ~ li {
  width: 50%; }

.sort_by_group .sort_by li:nth-last-child(3),
.sort_by_group .sort_by li:nth-last-child(3) ~ li {
  width: 33.33333%; }

.sort_by_group .sort_by li:nth-last-child(4),
.sort_by_group .sort_by li:nth-last-child(4) ~ li {
  width: 25%; }

.sort_by_group .sort_by li:nth-last-child(5),
.sort_by_group .sort_by li:nth-last-child(5) ~ li {
  width: 20%; }

.sort_by_group .sort_by li:nth-last-child(6),
.sort_by_group .sort_by li:nth-last-child(6) ~ li {
  width: 16.66667%; }

.fake-sort_by_group-container {
  height: 55px;
  margin-bottom: 20px; }

.fake-sort_by_group-container .sort_by_group .sort_by li {
  height: 62px;
  cursor: default; }

.fake-sort_by_group-container .sort_by_group .sort_by li:hover {
  background-color: #E2E2E6; }

.fake-sort_by_group-container .sort_by_group .sort_by li div {
  margin: auto;
  width: 100px;
  height: 24px;
  animation: fakeSortButtonBgKeyFrame 1s infinite; }

@keyframes fakeSortButtonBgKeyFrame {
  0% {
    background-color: #d9d9de; }
  50% {
    background-color: #d8d8de; }
  100% {
    background-color: #d9d9de; } }

.step_2_form_old #trav_0_birthday_day {
  width: 50%;
  margin: 0 5px; }

.step_2_form_old #trav_0_birthday_month {
  width: 50%;
  margin: 0 5px; }

.step_2_form_old #trav_0_birthday_year {
  width: 50%;
  margin: 0 5px; }

.step_2_form_old #trav_0_birthday_day-menu > .ui-menu-item {
  background: #e4e4e4;
  color: #2311b2;
  font-size: 14px;
  border-bottom: 1px solid #b9b9b9;
  font-family: 'Roboto Condensed', sans-serif; }

.step_2_form_old #trav_0_birthday_month-menu > .ui-menu-item {
  background: #e4e4e4;
  color: #2311b2;
  font-size: 14px;
  border-bottom: 1px solid #b9b9b9;
  font-family: 'Roboto Condensed', sans-serif; }

.step_2_form_old #trav_0_birthday_year-menu > .ui-menu-item {
  background: #e4e4e4;
  color: #2311b2;
  font-size: 14px;
  border-bottom: 1px solid #b9b9b9;
  font-family: 'Roboto Condensed', sans-serif; }

.step_2_form_old #trav_0_birthday_month-menu {
  height: 242.333px;
  width: auto;
  padding: 0;
  margin: 0;
  list-style: none;
  position: relative;
  overflow: auto;
  overflow-y: auto;
  overflow-x: hidden; }

.step_2_form_old #trav_0_birthday_year-menu {
  height: 242.333px;
  width: auto;
  padding: 0;
  margin: 0;
  list-style: none;
  position: relative;
  overflow: auto;
  overflow-y: auto;
  overflow-x: hidden; }

.step_2_form_old #trav_0_birthday_day-menu {
  height: 242.333px;
  width: auto;
  padding: 0;
  margin: 0;
  list-style: none;
  position: relative;
  overflow: auto;
  overflow-y: auto;
  overflow-x: hidden; }

.step_2_form_old #trav_1_birthday_day {
  width: 50%;
  margin: 0 5px; }

.step_2_form_old #trav_1_birthday_month {
  width: 50%;
  margin: 0 5px; }

.step_2_form_old #trav_1_birthday_year {
  width: 50%;
  margin: 0 5px; }

.step_2_form_old #trav_1_birthday_day-menu > .ui-menu-item {
  background: #e4e4e4;
  color: #2311b2;
  font-size: 14px;
  border-bottom: 1px solid #b9b9b9;
  font-family: 'Roboto Condensed', sans-serif; }

.step_2_form_old #trav_1_birthday_month-menu > .ui-menu-item {
  background: #e4e4e4;
  color: #2311b2;
  font-size: 14px;
  border-bottom: 1px solid #b9b9b9;
  font-family: 'Roboto Condensed', sans-serif; }

.step_2_form_old #trav_1_birthday_year-menu > .ui-menu-item {
  background: #e4e4e4;
  color: #2311b2;
  font-size: 14px;
  border-bottom: 1px solid #b9b9b9;
  font-family: 'Roboto Condensed', sans-serif; }

.step_2_form_old #trav_1_birthday_month-menu {
  height: 242.333px;
  width: auto;
  padding: 0;
  margin: 0;
  list-style: none;
  position: relative;
  overflow: auto;
  overflow-y: auto;
  overflow-x: hidden; }

.step_2_form_old #trav_1_birthday_year-menu {
  height: 242.333px;
  width: auto;
  padding: 0;
  margin: 0;
  list-style: none;
  position: relative;
  overflow: auto;
  overflow-y: auto;
  overflow-x: hidden; }

.step_2_form_old #trav_1_birthday_day-menu {
  height: 242.333px;
  width: auto;
  padding: 0;
  margin: 0;
  list-style: none;
  position: relative;
  overflow: auto;
  overflow-y: auto;
  overflow-x: hidden; }

.step_2_form_old #trav_2_birthday_day {
  width: 50%;
  margin: 0 5px; }

.step_2_form_old #trav_2_birthday_month {
  width: 50%;
  margin: 0 5px; }

.step_2_form_old #trav_2_birthday_year {
  width: 50%;
  margin: 0 5px; }

.step_2_form_old #trav_2_birthday_day-menu > .ui-menu-item {
  background: #e4e4e4;
  color: #2311b2;
  font-size: 14px;
  border-bottom: 1px solid #b9b9b9;
  font-family: 'Roboto Condensed', sans-serif; }

.step_2_form_old #trav_2_birthday_month-menu > .ui-menu-item {
  background: #e4e4e4;
  color: #2311b2;
  font-size: 14px;
  border-bottom: 1px solid #b9b9b9;
  font-family: 'Roboto Condensed', sans-serif; }

.step_2_form_old #trav_2_birthday_year-menu > .ui-menu-item {
  background: #e4e4e4;
  color: #2311b2;
  font-size: 14px;
  border-bottom: 1px solid #b9b9b9;
  font-family: 'Roboto Condensed', sans-serif; }

.step_2_form_old #trav_2_birthday_month-menu {
  height: 242.333px;
  width: auto;
  padding: 0;
  margin: 0;
  list-style: none;
  position: relative;
  overflow: auto;
  overflow-y: auto;
  overflow-x: hidden; }

.step_2_form_old #trav_2_birthday_year-menu {
  height: 242.333px;
  width: auto;
  padding: 0;
  margin: 0;
  list-style: none;
  position: relative;
  overflow: auto;
  overflow-y: auto;
  overflow-x: hidden; }

.step_2_form_old #trav_2_birthday_day-menu {
  height: 242.333px;
  width: auto;
  padding: 0;
  margin: 0;
  list-style: none;
  position: relative;
  overflow: auto;
  overflow-y: auto;
  overflow-x: hidden; }

.step_2_form_old #trav_3_birthday_day {
  width: 50%;
  margin: 0 5px; }

.step_2_form_old #trav_3_birthday_month {
  width: 50%;
  margin: 0 5px; }

.step_2_form_old #trav_3_birthday_year {
  width: 50%;
  margin: 0 5px; }

.step_2_form_old #trav_3_birthday_day-menu > .ui-menu-item {
  background: #e4e4e4;
  color: #2311b2;
  font-size: 14px;
  border-bottom: 1px solid #b9b9b9;
  font-family: 'Roboto Condensed', sans-serif; }

.step_2_form_old #trav_3_birthday_month-menu > .ui-menu-item {
  background: #e4e4e4;
  color: #2311b2;
  font-size: 14px;
  border-bottom: 1px solid #b9b9b9;
  font-family: 'Roboto Condensed', sans-serif; }

.step_2_form_old #trav_3_birthday_year-menu > .ui-menu-item {
  background: #e4e4e4;
  color: #2311b2;
  font-size: 14px;
  border-bottom: 1px solid #b9b9b9;
  font-family: 'Roboto Condensed', sans-serif; }

.step_2_form_old #trav_3_birthday_month-menu {
  height: 242.333px;
  width: auto;
  padding: 0;
  margin: 0;
  list-style: none;
  position: relative;
  overflow: auto;
  overflow-y: auto;
  overflow-x: hidden; }

.step_2_form_old #trav_3_birthday_year-menu {
  height: 242.333px;
  width: auto;
  padding: 0;
  margin: 0;
  list-style: none;
  position: relative;
  overflow: auto;
  overflow-y: auto;
  overflow-x: hidden; }

.step_2_form_old #trav_3_birthday_day-menu {
  height: 242.333px;
  width: auto;
  padding: 0;
  margin: 0;
  list-style: none;
  position: relative;
  overflow: auto;
  overflow-y: auto;
  overflow-x: hidden; }

.step_2_form_old #trav_4_birthday_day {
  width: 50%;
  margin: 0 5px; }

.step_2_form_old #trav_4_birthday_month {
  width: 50%;
  margin: 0 5px; }

.step_2_form_old #trav_4_birthday_year {
  width: 50%;
  margin: 0 5px; }

.step_2_form_old #trav_4_birthday_day-menu > .ui-menu-item {
  background: #e4e4e4;
  color: #2311b2;
  font-size: 14px;
  border-bottom: 1px solid #b9b9b9;
  font-family: 'Roboto Condensed', sans-serif; }

.step_2_form_old #trav_4_birthday_month-menu > .ui-menu-item {
  background: #e4e4e4;
  color: #2311b2;
  font-size: 14px;
  border-bottom: 1px solid #b9b9b9;
  font-family: 'Roboto Condensed', sans-serif; }

.step_2_form_old #trav_4_birthday_year-menu > .ui-menu-item {
  background: #e4e4e4;
  color: #2311b2;
  font-size: 14px;
  border-bottom: 1px solid #b9b9b9;
  font-family: 'Roboto Condensed', sans-serif; }

.step_2_form_old #trav_4_birthday_month-menu {
  height: 242.333px;
  width: auto;
  padding: 0;
  margin: 0;
  list-style: none;
  position: relative;
  overflow: auto;
  overflow-y: auto;
  overflow-x: hidden; }

.step_2_form_old #trav_4_birthday_year-menu {
  height: 242.333px;
  width: auto;
  padding: 0;
  margin: 0;
  list-style: none;
  position: relative;
  overflow: auto;
  overflow-y: auto;
  overflow-x: hidden; }

.step_2_form_old #trav_4_birthday_day-menu {
  height: 242.333px;
  width: auto;
  padding: 0;
  margin: 0;
  list-style: none;
  position: relative;
  overflow: auto;
  overflow-y: auto;
  overflow-x: hidden; }

.step_2_form_old #trav_5_birthday_day {
  width: 50%;
  margin: 0 5px; }

.step_2_form_old #trav_5_birthday_month {
  width: 50%;
  margin: 0 5px; }

.step_2_form_old #trav_5_birthday_year {
  width: 50%;
  margin: 0 5px; }

.step_2_form_old #trav_5_birthday_day-menu > .ui-menu-item {
  background: #e4e4e4;
  color: #2311b2;
  font-size: 14px;
  border-bottom: 1px solid #b9b9b9;
  font-family: 'Roboto Condensed', sans-serif; }

.step_2_form_old #trav_5_birthday_month-menu > .ui-menu-item {
  background: #e4e4e4;
  color: #2311b2;
  font-size: 14px;
  border-bottom: 1px solid #b9b9b9;
  font-family: 'Roboto Condensed', sans-serif; }

.step_2_form_old #trav_5_birthday_year-menu > .ui-menu-item {
  background: #e4e4e4;
  color: #2311b2;
  font-size: 14px;
  border-bottom: 1px solid #b9b9b9;
  font-family: 'Roboto Condensed', sans-serif; }

.step_2_form_old #trav_5_birthday_month-menu {
  height: 242.333px;
  width: auto;
  padding: 0;
  margin: 0;
  list-style: none;
  position: relative;
  overflow: auto;
  overflow-y: auto;
  overflow-x: hidden; }

.step_2_form_old #trav_5_birthday_year-menu {
  height: 242.333px;
  width: auto;
  padding: 0;
  margin: 0;
  list-style: none;
  position: relative;
  overflow: auto;
  overflow-y: auto;
  overflow-x: hidden; }

.step_2_form_old #trav_5_birthday_day-menu {
  height: 242.333px;
  width: auto;
  padding: 0;
  margin: 0;
  list-style: none;
  position: relative;
  overflow: auto;
  overflow-y: auto;
  overflow-x: hidden; }

.step_2_form_old #trav_6_birthday_day {
  width: 50%;
  margin: 0 5px; }

.step_2_form_old #trav_6_birthday_month {
  width: 50%;
  margin: 0 5px; }

.step_2_form_old #trav_6_birthday_year {
  width: 50%;
  margin: 0 5px; }

.step_2_form_old #trav_6_birthday_day-menu > .ui-menu-item {
  background: #e4e4e4;
  color: #2311b2;
  font-size: 14px;
  border-bottom: 1px solid #b9b9b9;
  font-family: 'Roboto Condensed', sans-serif; }

.step_2_form_old #trav_6_birthday_month-menu > .ui-menu-item {
  background: #e4e4e4;
  color: #2311b2;
  font-size: 14px;
  border-bottom: 1px solid #b9b9b9;
  font-family: 'Roboto Condensed', sans-serif; }

.step_2_form_old #trav_6_birthday_year-menu > .ui-menu-item {
  background: #e4e4e4;
  color: #2311b2;
  font-size: 14px;
  border-bottom: 1px solid #b9b9b9;
  font-family: 'Roboto Condensed', sans-serif; }

.step_2_form_old #trav_6_birthday_month-menu {
  height: 242.333px;
  width: auto;
  padding: 0;
  margin: 0;
  list-style: none;
  position: relative;
  overflow: auto;
  overflow-y: auto;
  overflow-x: hidden; }

.step_2_form_old #trav_6_birthday_year-menu {
  height: 242.333px;
  width: auto;
  padding: 0;
  margin: 0;
  list-style: none;
  position: relative;
  overflow: auto;
  overflow-y: auto;
  overflow-x: hidden; }

.step_2_form_old #trav_6_birthday_day-menu {
  height: 242.333px;
  width: auto;
  padding: 0;
  margin: 0;
  list-style: none;
  position: relative;
  overflow: auto;
  overflow-y: auto;
  overflow-x: hidden; }

.step_2_form_old #trav_7_birthday_day {
  width: 50%;
  margin: 0 5px; }

.step_2_form_old #trav_7_birthday_month {
  width: 50%;
  margin: 0 5px; }

.step_2_form_old #trav_7_birthday_year {
  width: 50%;
  margin: 0 5px; }

.step_2_form_old #trav_7_birthday_day-menu > .ui-menu-item {
  background: #e4e4e4;
  color: #2311b2;
  font-size: 14px;
  border-bottom: 1px solid #b9b9b9;
  font-family: 'Roboto Condensed', sans-serif; }

.step_2_form_old #trav_7_birthday_month-menu > .ui-menu-item {
  background: #e4e4e4;
  color: #2311b2;
  font-size: 14px;
  border-bottom: 1px solid #b9b9b9;
  font-family: 'Roboto Condensed', sans-serif; }

.step_2_form_old #trav_7_birthday_year-menu > .ui-menu-item {
  background: #e4e4e4;
  color: #2311b2;
  font-size: 14px;
  border-bottom: 1px solid #b9b9b9;
  font-family: 'Roboto Condensed', sans-serif; }

.step_2_form_old #trav_7_birthday_month-menu {
  height: 242.333px;
  width: auto;
  padding: 0;
  margin: 0;
  list-style: none;
  position: relative;
  overflow: auto;
  overflow-y: auto;
  overflow-x: hidden; }

.step_2_form_old #trav_7_birthday_year-menu {
  height: 242.333px;
  width: auto;
  padding: 0;
  margin: 0;
  list-style: none;
  position: relative;
  overflow: auto;
  overflow-y: auto;
  overflow-x: hidden; }

.step_2_form_old #trav_7_birthday_day-menu {
  height: 242.333px;
  width: auto;
  padding: 0;
  margin: 0;
  list-style: none;
  position: relative;
  overflow: auto;
  overflow-y: auto;
  overflow-x: hidden; }

.step_2_form_old #trav_8_birthday_day {
  width: 50%;
  margin: 0 5px; }

.step_2_form_old #trav_8_birthday_month {
  width: 50%;
  margin: 0 5px; }

.step_2_form_old #trav_8_birthday_year {
  width: 50%;
  margin: 0 5px; }

.step_2_form_old #trav_8_birthday_day-menu > .ui-menu-item {
  background: #e4e4e4;
  color: #2311b2;
  font-size: 14px;
  border-bottom: 1px solid #b9b9b9;
  font-family: 'Roboto Condensed', sans-serif; }

.step_2_form_old #trav_8_birthday_month-menu > .ui-menu-item {
  background: #e4e4e4;
  color: #2311b2;
  font-size: 14px;
  border-bottom: 1px solid #b9b9b9;
  font-family: 'Roboto Condensed', sans-serif; }

.step_2_form_old #trav_8_birthday_year-menu > .ui-menu-item {
  background: #e4e4e4;
  color: #2311b2;
  font-size: 14px;
  border-bottom: 1px solid #b9b9b9;
  font-family: 'Roboto Condensed', sans-serif; }

.step_2_form_old #trav_8_birthday_month-menu {
  height: 242.333px;
  width: auto;
  padding: 0;
  margin: 0;
  list-style: none;
  position: relative;
  overflow: auto;
  overflow-y: auto;
  overflow-x: hidden; }

.step_2_form_old #trav_8_birthday_year-menu {
  height: 242.333px;
  width: auto;
  padding: 0;
  margin: 0;
  list-style: none;
  position: relative;
  overflow: auto;
  overflow-y: auto;
  overflow-x: hidden; }

.step_2_form_old #trav_8_birthday_day-menu {
  height: 242.333px;
  width: auto;
  padding: 0;
  margin: 0;
  list-style: none;
  position: relative;
  overflow: auto;
  overflow-y: auto;
  overflow-x: hidden; }

.step_2_form_old #trav_9_birthday_day {
  width: 50%;
  margin: 0 5px; }

.step_2_form_old #trav_9_birthday_month {
  width: 50%;
  margin: 0 5px; }

.step_2_form_old #trav_9_birthday_year {
  width: 50%;
  margin: 0 5px; }

.step_2_form_old #trav_9_birthday_day-menu > .ui-menu-item {
  background: #e4e4e4;
  color: #2311b2;
  font-size: 14px;
  border-bottom: 1px solid #b9b9b9;
  font-family: 'Roboto Condensed', sans-serif; }

.step_2_form_old #trav_9_birthday_month-menu > .ui-menu-item {
  background: #e4e4e4;
  color: #2311b2;
  font-size: 14px;
  border-bottom: 1px solid #b9b9b9;
  font-family: 'Roboto Condensed', sans-serif; }

.step_2_form_old #trav_9_birthday_year-menu > .ui-menu-item {
  background: #e4e4e4;
  color: #2311b2;
  font-size: 14px;
  border-bottom: 1px solid #b9b9b9;
  font-family: 'Roboto Condensed', sans-serif; }

.step_2_form_old #trav_9_birthday_month-menu {
  height: 242.333px;
  width: auto;
  padding: 0;
  margin: 0;
  list-style: none;
  position: relative;
  overflow: auto;
  overflow-y: auto;
  overflow-x: hidden; }

.step_2_form_old #trav_9_birthday_year-menu {
  height: 242.333px;
  width: auto;
  padding: 0;
  margin: 0;
  list-style: none;
  position: relative;
  overflow: auto;
  overflow-y: auto;
  overflow-x: hidden; }

.step_2_form_old #trav_9_birthday_day-menu {
  height: 242.333px;
  width: auto;
  padding: 0;
  margin: 0;
  list-style: none;
  position: relative;
  overflow: auto;
  overflow-y: auto;
  overflow-x: hidden; }

.step_2_form_old span.bottom-newsletter-text {
  float: left;
  line-height: 40px;
  font-size: 18px;
  margin: 0 0px 0 20px;
  text-decoration: none; }

.step_2_form_old .toggle_bonuscard_link {
  color: #000;
  text-decoration: underline;
  padding-top: 5px;
  display: inline-block;
  padding-left: 110px; }

.step_2_form_old #toggle_seating_link {
  color: #000;
  text-decoration: underline;
  padding-top: 5px;
  display: inline-block;
  padding-left: 110px; }

.step_2_form_old .bottom-condition {
  float: right;
  margin: 20px 0 0 0; }

.step_2_form_old .bottom-condition label {
  float: left;
  margin: 0 0 0 50px; }

.step_2_form_old .bottom-condition span {
  float: left;
  line-height: 40px;
  font-size: 18px;
  margin: 0 0px 0 20px; }

.step_2_form_old .bottom-condition-text a {
  color: #000;
  text-decoration: underline !important; }

.step_2_form_old .birthday_info {
  width: 400px !important;
  display: flex; }

.session_timer {
  width: 100%;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  background: #FFF;
  font-size: 22px;
  color: #000;
  margin: 0 0 15px;
  border: 1px solid #949494;
  padding: 10px 20px 10px 20px; }

.session_timer .time_left {
  color: #972422;
  font-weight: bold;
  font-size: 25px; }

.session_timeout {
  display: none; }

.custom-checkbox-conditions,
.custom-checkbox-newsletter,
.custom-checkbox-integritetspolicy,
.custom-checkbox-danish {
  width: 40px;
  height: 40px;
  display: inline-block;
  position: relative;
  z-index: 1;
  top: 3px;
  cursor: pointer; }

.custom-checkbox-conditions input[type="checkbox"],
.custom-checkbox-error input[type="checkbox"],
.custom-checkbox-newsletter input[type="checkbox"],
.custom-checkbox-integritetspolicy input[type="checkbox"],
.custom-checkbox-danish input[type="checkbox"] {
  margin: 0;
  position: absolute;
  z-index: 2;
  cursor: pointer;
  outline: none;
  opacity: 0;
  /* CSS hacks for older browsers */
  _noFocusLine: expression(this.hideFocus=true);
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  -khtml-opacity: 0;
  -moz-opacity: 0; }

.custom-checkbox-conditions,
.custom-checkbox-newsletter,
.custom-checkbox-integritetspolicy,
.custom-checkbox-danish {
  background: url("./images/site/checkbox.png") no-repeat; }
  .custom-checkbox-conditions:hover,
  .custom-checkbox-newsletter:hover,
  .custom-checkbox-integritetspolicy:hover,
  .custom-checkbox-danish:hover {
    background: url("./images/site/checkbox-hover.png") no-repeat; }
  .custom-checkbox-conditions.selected,
  .custom-checkbox-newsletter.selected,
  .custom-checkbox-integritetspolicy.selected,
  .custom-checkbox-danish.selected {
    background: url("./images/site/checkbox-selected.png") no-repeat; }

.custom-checkbox-error {
  background: url("./images/site/checkbox-red.png") no-repeat; }
  .custom-checkbox-error:hover {
    background: url("./images/site/checkbox-hover.png") no-repeat; }
  .custom-checkbox-error.selected {
    background: url("./images/site/checkbox-selected.png") no-repeat; }

.ui-selectmenu-button {
  margin-left: 5px; }

.ui-state-active,
.ui-widget-content .ui-state-active,
.ui-widget-header .ui-state-active,
a.ui-button:active,
.ui-button:active,
.ui-state-active.ui-button:hover {
  background: #cdcdcd;
  color: #2311b2;
  border: none;
  font-family: 'Roboto Condensed', sans-serif;
  font-weight: 300;
  padding: 5px 10px; }

.ui-menu .ui-menu-item-wrapper {
  line-height: normal;
  padding: 5px 10px;
  font-size: 14px; }

.ui-menu-item-wrapper.ui-state-active {
  margin: 0; }

.ui-selectmenu-menu.ui-selectmenu-open {
  max-height: 300px;
  overflow-y: scroll; }

.passenger td {
  padding-right: 10px;
  font-size: 12px; }
  .passenger td.selected-bag {
    font-weight: 400; }

.booking-mform .profile {
  width: 38px; }
  .booking-mform .profile .ui-selectmenu-button.ui-button {
    padding: 0; }
    .booking-mform .profile .ui-selectmenu-button.ui-button .ui-selectmenu-text {
      display: none; }
    .booking-mform .profile .ui-selectmenu-button.ui-button .ui-icon {
      right: 8px; }
  .booking-mform .profile.active .ui-selectmenu-button.ui-button .ui-icon {
    transform: rotate(-90deg); }

.select-box {
  position: relative;
  width: 252px;
  max-height: 38px;
  margin-top: 5px;
  display: inline-block;
  border-radius: 3px;
  border: 1px solid var(--border); }

.select-box input {
  width: 100%;
  overflow: hidden;
  font-size: 1.1rem;
  height: 36px;
  border: .1rem solid transparent;
  outline: none; }

.select-box ol {
  max-height: 23rem;
  width: 100%;
  list-style: none; }

.select-box ol::-webkit-scrollbar {
  width: 0.4rem;
  height: 3rem; }

.select-box ol li {
  display: flex;
  cursor: pointer;
  width: 300px !important;
  margin: 0px 0px 0px 5px !important;
  height: 40px;
  align-items: center; }

.select-box ol li.hide {
  display: none; }

.select-box ol li:hover {
  background-color: var(--select); }

.select-box ol li .country-name {
  width: 100%;
  display: inline;
  text-align: center; }

.select-box ol li .flag {
  display: inline-block;
  margin-left: 10px; }

.selected-option {
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: lightgrey; }

.selected-option div {
  width: 5rem;
  position: relative;
  padding: 0 2.3rem 0 .5rem;
  text-align: center;
  cursor: pointer; }

.selected-option input[type=text] {
  height: 36px !important;
  border: 0px !important;
  outline: 0px !important; }

.selected-option div::after {
  display: inline-block;
  position: absolute;
  right: .6rem;
  content: url("https://res.cloudinary.com/dzwdseno3/image/asset/v1502446105/selectlist-icon-5c110111ba7bf967f73337f69da0d4b7.png");
  top: 30%; }

.selected-option div.active::after {
  transform: translateY(-50%) rotate(180deg); }

.select-box .options {
  position: absolute;
  width: 624px;
  background-color: #fff;
  border-radius: 0 0 .5rem .5rem;
  z-index: 2;
  overflow-y: scroll;
  height: 300px;
  display: none; }

.select-box .options.active {
  display: block;
  border: 1px solid var(--border);
  padding-right: 0px; }

.select-box .options.active ol input {
  border-bottom: 1px solid var(--border) !important;
  border-top: 0px !important;
  border-left: 0px !important;
  border-right: 1px solid var(--border) !important;
  border-radius: 0px; }

.select-box .options::before {
  position: absolute;
  left: 1rem;
  top: -1.2rem;
  content: "";
  width: 0;
  height: 0;
  border: .6rem solid transparent; }

#step_2_submit_btn {
  font-size: 18px;
  height: 40px;
  border: 0;
  cursor: pointer; }

.select-box ol li:not(:last-child) {
  border-bottom: .1rem solid #eee; }

#profile-menu .ui-menu .ui-menu-item-wrapper {
  margin: 0; }
  #profile-menu .ui-menu .ui-menu-item-wrapper.ui-state-active {
    font-weight: bold; }

#trav_1_profile-menu.ui-menu .ui-menu-item-wrapper {
  font-family: 'Roboto Condensed', sans-serif; }

#trav_2_profile-menu.ui-menu .ui-menu-item-wrapper {
  font-family: 'Roboto Condensed', sans-serif; }

#trav_3_profile-menu.ui-menu .ui-menu-item-wrapper {
  font-family: 'Roboto Condensed', sans-serif; }

#trav_4_profile-menu.ui-menu .ui-menu-item-wrapper {
  font-family: 'Roboto Condensed', sans-serif; }

#trav_5_profile-menu.ui-menu .ui-menu-item-wrapper {
  font-family: 'Roboto Condensed', sans-serif; }

#trav_6_profile-menu.ui-menu .ui-menu-item-wrapper {
  font-family: 'Roboto Condensed', sans-serif; }

#trav_7_profile-menu.ui-menu .ui-menu-item-wrapper {
  font-family: 'Roboto Condensed', sans-serif; }

#trav_8_profile-menu.ui-menu .ui-menu-item-wrapper {
  font-family: 'Roboto Condensed', sans-serif; }

#trav_9_profile-menu.ui-menu .ui-menu-item-wrapper {
  font-family: 'Roboto Condensed', sans-serif; }

#ltd_div {
  display: none;
  padding-top: 15px;
  font-size: 13px;
  font-weight: bold; }

#ltd_value {
  font-weight: normal; }

.step_2_spinner {
  background: #FFF;
  width: 100%;
  border-radius: 3px;
  margin: 2px 0 0 0;
  padding: 40px;
  text-align: center;
  border: 1px solid #949494; }
  .step_2_spinner .title {
    color: #000;
    font-size: 30px;
    font-weight: 400;
    line-height: 35px;
    padding: 0 0 30px 0; }
    .step_2_spinner .title span {
      font-size: 22px;
      display: block;
      padding: 10px 0 0 0; }

#step_3_form.locale_de-DE {
  background-color: white;
  padding: 10px;
  border-radius: 5px; }

.package-container {
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  clear: left;
  width: 100%;
  height: auto;
  display: table;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 10px;
  text-align: left; }
  .package-container .package-packages .service-item-button {
    margin: 0px 20%; }
    .package-container .package-packages .service-item-button input[type="radio"] {
      display: none; }
      .package-container .package-packages .service-item-button input[type="radio"]:not(:disabled) ~ label {
        cursor: pointer; }
      .package-container .package-packages .service-item-button input[type="radio"]:disabled ~ label {
        color: #bcc2bf;
        border-color: #bcc2bf;
        box-shadow: none;
        cursor: not-allowed; }
    .package-container .package-packages .service-item-button label {
      height: 100%;
      display: block;
      background: white;
      border: 2px solid #20df80;
      border-radius: 20px;
      padding: 1rem;
      margin-bottom: 1rem;
      text-align: center;
      /*box-shadow: 0px 3px 10px -2px hsla(150, 5%, 65%, 0.5);*/
      position: relative; }
    .package-container .package-packages .service-item-button input[type="radio"]:checked + label {
      background: #20df80;
      color: white;
      /*box-shadow: 0px 0px 20px hsla(150, 100%, 50%, 0.75);*/ }
    .package-container .package-packages .service-item-button input[type="radio"]#control_05:checked + label {
      background: red;
      border-color: red; }
  .package-container .package-addons, .package-container .package-item {
    display: inline-block;
    vertical-align: top;
    float: left; }
  .package-container .package-addons-item,
  .package-container .package-item-included, .package-container .package-item-excluded {
    height: 50px; }
  .package-container .package-addons {
    width: 40%;
    font-size: 16px; }
    .package-container .package-addons .package-addons-header {
      background-color: #fff;
      font-weight: 500;
      padding: 10px;
      margin-top: 19px;
      border-radius: 10px 0px 0px 0px;
      border: 1px solid #949494;
      border-right: 0px; }
    .package-container .package-addons .package-addons-item {
      border-left: 1px solid #949494;
      background-color: #fff;
      padding: 10px; }
      .package-container .package-addons .package-addons-item .package-addons-item-icon {
        height: 26px;
        width: 32px;
        padding: 5px;
        margin-right: 5px;
        display: inline-block; }
        .package-container .package-addons .package-addons-item .package-addons-item-icon img {
          width: 24px; }
      .package-container .package-addons .package-addons-item .package-addons-item-info-icon {
        width: 35px;
        display: inline-block;
        float: right;
        text-align: right;
        padding-top: 5px;
        padding-bottom: 5px;
        color: #d2d2d2; }
        .package-container .package-addons .package-addons-item .package-addons-item-info-icon img {
          width: 24px; }
      .package-container .package-addons .package-addons-item:nth-child(even) {
        background-color: #fff; }
      .package-container .package-addons .package-addons-item:nth-child(odd) {
        background-color: #efefef; }
      .package-container .package-addons .package-addons-item.package-addons-item-last {
        border-bottom: 1px solid #949494;
        border-radius: 0px 0px 0px 10px; }
    .package-container .package-addons .package-addons-comments {
      height: 125px;
      padding: 5px;
      padding-top: 15px; }
      .package-container .package-addons .package-addons-comments .package-addons-text {
        height: 40px; }
      .package-container .package-addons .package-addons-comments .package-addons-comments-field {
        padding: 5px; }
        .package-container .package-addons .package-addons-comments .package-addons-comments-field #comment {
          min-height: 50px;
          height: 50px;
          resize: vertical; }
  .package-container .packages-2 .package-item {
    width: 50% !important; }
  .package-container .packages-3 .package-item {
    width: 33.33333% !important; }
  .package-container .packages-4 .package-item {
    width: 25% !important; }
  .package-container .packages-5 .package-item {
    width: 20% !important; }
  .package-container .packages-6 .package-item {
    width: 16.66667% !important; }
  .package-container .package-item {
    width: 33%;
    border: 1px solid #949494;
    border-right: 0px; }
    .package-container .package-item .package-item-header {
      background-color: #efefef;
      text-align: center;
      font-size: 24px;
      position: relative;
      padding: 20px; }
    .package-container .package-item .package_stamp {
      height: 80px;
      width: 80px;
      background: url("./images/site/addon_package_highlight.png") 50px no-repeat;
      background-position: center;
      background-position: center;
      position: absolute;
      top: -25px;
      right: -20px;
      background-size: cover;
      z-index: 1; }
    .package-container .package-item .package-item-included:nth-child(even),
    .package-container .package-item .package-item-excluded:nth-child(even) {
      background-color: #fff; }
    .package-container .package-item .package-item-included:nth-child(odd),
    .package-container .package-item .package-item-excluded:nth-child(odd) {
      background-color: #efefef; }
    .package-container .package-item .package-item-included {
      background: url("./images/site/good.png") 20px no-repeat #ffffff;
      background-position: center; }
    .package-container .package-item .package-item-excluded {
      background: url("./images/site/bad.png") 20px no-repeat #ffffff;
      background-position: center; }
    .package-container .package-item .package-item-last {
      border-bottom: 1px solid #949494; }
    .package-container .package-item .package-item-action {
      background-color: #fff;
      text-align: center;
      padding: 10px 0px;
      padding-top: 20px;
      height: 125px; }
      .package-container .package-item .package-item-action .price {
        font-size: 24px;
        font-weight: 500; }
      .package-container .package-item .package-item-action .person {
        font-size: 12px; }
  .package-container .package-packages .package-item:first-child {
    border-radius: 10px 0px 0px 10px; }
    .package-container .package-packages .package-item:first-child .package-item-header {
      border-radius: 10px 0px 0px 0px; }
    .package-container .package-packages .package-item:first-child .package-item-action {
      border-radius: 0px 0px 0px 10px; }
  .package-container .package-packages .package-item:last-child {
    border-right: 1px solid #949494;
    border-radius: 0px 10px 10px 0px; }
    .package-container .package-packages .package-item:last-child .package-item-header {
      border-radius: 0px 10px 0px 0px; }
    .package-container .package-packages .package-item:last-child .package-item-action {
      border-radius: 0px 0px 10px 0px; }

.service {
  margin: 0;
  padding: 15px 0 0 0;
  float: left;
  width: 100%;
  text-align: center; }
  .service .service-packages {
    border: 0px; }
    .service .service-packages h2 {
      text-align: left;
      font-size: 28px; }
  .service li {
    vertical-align: top;
    padding: 1%;
    width: 100%;
    background: #FFF;
    border-radius: 3px;
    border: 1px solid #949494;
    margin-bottom: 10px;
    margin-right: auto;
    margin-left: auto;
    display: table; }
  .service .service-image {
    width: 20%;
    height: 150px;
    display: inline-block;
    float: left; }
    .service .service-image img {
      height: 100%;
      border-radius: 10px; }
  .service .service-item-content .service-item-top {
    text-align: left;
    height: 80px; }
    .service .service-item-content .service-item-top .service-item-text {
      display: inline-block;
      width: 70%; }
      .service .service-item-content .service-item-top .service-item-text .service-item-header {
        padding: 8px 0px;
        padding-bottom: 14px;
        line-height: 24px;
        text-align: left;
        font-size: 120%;
        font-weight: 500; }
    .service .service-item-content .service-item-top .service-item-price {
      font-size: 29px;
      color: #000;
      float: right;
      font-weight: 400;
      line-height: normal; }
      .service .service-item-content .service-item-top .service-item-price .smalltext {
        font-size: 14px;
        padding-left: 3px; }
  .service .service-item-content .service-item-bottom {
    clear: none;
    margin-top: 0px; }
    .service .service-item-content .service-item-bottom .service-item-readmore {
      display: inline-block;
      float: left;
      margin: 10px 0px;
      padding: 12px 0px; }
    .service .service-item-content .service-item-bottom .service-item-personal-number {
      display: inline-block;
      vertical-align: top;
      clear: left;
      font-size: 20px;
      color: #000;
      text-align: left;
      padding: 18px 0px; }
      .service .service-item-content .service-item-bottom .service-item-personal-number .service-item-personal-number-header {
        display: inline-block;
        font-size: 16px; }
      .service .service-item-content .service-item-bottom .service-item-personal-number .service-item-personal-number-input {
        display: inline-block; }
        .service .service-item-content .service-item-bottom .service-item-personal-number .service-item-personal-number-input input {
          border: 1px solid #a1a1a1;
          font-size: 16px;
          padding: 5px; }
      .service .service-item-content .service-item-bottom .service-item-personal-number .service-item-personal-number-info-icon {
        display: inline-block; }
    .service .service-item-content .service-item-bottom .service-item-address {
      display: inline-block;
      vertical-align: top;
      clear: left;
      font-size: 20px;
      color: #000;
      text-align: left;
      padding: 18px 0px; }
      .service .service-item-content .service-item-bottom .service-item-address .service-item-address-header {
        display: inline-block;
        font-size: 16px; }
      .service .service-item-content .service-item-bottom .service-item-address .service-item-address-input {
        display: inline-block; }
        .service .service-item-content .service-item-bottom .service-item-address .service-item-address-input input {
          border: 1px solid #a1a1a1;
          font-size: 16px;
          padding: 5px; }
      .service .service-item-content .service-item-bottom .service-item-address .service-item-postal-code-input {
        display: inline-block; }
        .service .service-item-content .service-item-bottom .service-item-address .service-item-postal-code-input input {
          border: 1px solid #a1a1a1;
          font-size: 16px;
          padding: 5px; }
      .service .service-item-content .service-item-bottom .service-item-address .service-item-city-input {
        display: inline-block; }
        .service .service-item-content .service-item-bottom .service-item-address .service-item-city-input input {
          border: 1px solid #a1a1a1;
          font-size: 16px;
          padding: 5px; }
      .service .service-item-content .service-item-bottom .service-item-address .service-item-country-code-input {
        display: inline-block; }
        .service .service-item-content .service-item-bottom .service-item-address .service-item-country-code-input input {
          border: 1px solid #a1a1a1;
          font-size: 16px;
          padding: 5px; }
      .service .service-item-content .service-item-bottom .service-item-address .service-item-address-info-icon {
        display: inline-block; }
    .service .service-item-content .service-item-bottom .radio-section {
      float: right; }

.service-item-button123123 {
  padding: 0 5px; }
  .service-item-button123123.service-item-button-yes input {
    background: #59e027;
    width: 120px; }

.ResursBank table.priceTable {
  width: 100%;
  margin: 20px 0px;
  border-collapse: collapse; }
  .ResursBank table.priceTable th,
  .ResursBank table.priceTable td {
    border: 1px solid #ccc;
    padding: 10px; }

.ResursBank p {
  margin: 10px 0px; }

.ResursBank {
  width: 800px !important; }
  .ResursBank p {
    font-size: 14px;
    padding: 5px 0px; }
  .ResursBank span.headText {
    font-weight: bold; }
  .ResursBank table.priceTable {
    width: 100%;
    background-color: #ececec;
    border: 1px solid #d6d6d6; }
    .ResursBank table.priceTable tr td.header {
      padding: 5px; }
    .ResursBank table.priceTable tr td.content {
      padding: 5px;
      text-align: right; }
    .ResursBank table.priceTable tr.evenRow {
      background-color: #e0e0e0; }

.payment-prodetail {
  float: left;
  width: 100%;
  color: #000000; }
  .payment-prodetail a {
    text-decoration: underline; }
  .payment-prodetail ul {
    float: left;
    width: 65%; }
  .payment-prodetail table {
    float: right;
    width: 30%; }
    .payment-prodetail table tr td:first-child {
      color: #000; }
    .payment-prodetail table tr td {
      padding: 5px;
      text-align: right; }

.payment-prodetail ul li {
  float: left;
  line-height: 22px; }

.payment-prodetail ul li span {
  color: #000; }

.payment-prodetail ul li.dec {
  width: 60%; }

.payment-prodetail ul li.dec_bottom {
  width: 100%;
  font-size: 12px;
  color: #000000;
  padding-top: 10px; }

.payment-prodetail ul li.dec_bottom a {
  color: #000000;
  text-decoration: underline; }

.payment-prodetail ul li.price {
  width: 20%;
  text-align: right;
  padding: 0 2%; }

.payment-prodetail ul li.total {
  width: 20%;
  text-align: right; }

.payment-flow-container {
  width: 40%;
  margin: 0 auto; }
  .payment-flow-container .card-body {
    text-align: center; }
  .payment-flow-container .payment-logo {
    width: 50%;
    margin-top: 20px; }
  .payment-flow-container .payment-loader {
    padding-top: 40px; }
  .payment-flow-container .payment-text {
    padding: 30px 50px;
    line-height: 24px; }
  .payment-flow-container .payment-back {
    width: 50%; }

::-webkit-scrollbar {
  -webkit-appearance: none; }

::-webkit-scrollbar:vertical {
  width: 12px; }

::-webkit-scrollbar:horizontal {
  height: 12px; }

::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  border: 2px solid #ffffff; }

::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: #ffffff; }

.payment-wrap {
  float: left;
  width: 100%; }

.payment-wrap > ul {
  float: left;
  width: 100%; }

.payment-wrap > ul > li {
  float: left;
  width: 100%;
  background: #FFF;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  font-size: 18px;
  font-weight: 400;
  margin: 0 0 1px 0;
  display: table;
  height: 100%; }

.payment-wrap > ul > li form {
  display: table-row; }

.payment-msec {
  display: table-cell;
  padding: 2%;
  width: 60%;
  vertical-align: top; }

.payment-msec .title {
  font-size: 30px;
  float: left;
  width: 100%;
  color: #000;
  font-weight: 300;
  margin: 0 0 15px 0; }

.payment-msec .title img {
  float: left;
  max-width: 140px; }

.payment-msec .title span {
  float: left;
  padding: 3px 0 0 15px; }

.payment-option {
  padding: 2%;
  border-left: 1px solid #efcb01;
  color: #000;
  display: inline-table;
  height: 100%;
  display: table-cell;
  vertical-align: top; }

.payment-option div.space {
  padding: 15px 0;
  float: left; }

.payment-option div.text {
  display: inline;
  padding-left: 5px;
  position: relative;
  top: 15px; }

.payment-option label {
  float: left;
  width: 100%;
  margin: 0 0 10px 0; }

.payment-option img {
  margin: 0 0 0 10px;
  float: left;
  max-height: 40px; }

.payment-option input[type="text"] {
  background: #e5e5e5;
  color: #000;
  font-size: 18px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  padding: 5px 10px;
  margin: 10px 0 0 0;
  float: left;
  width: 180px; }

.payment {
  -webkit-border-radius: 0 3px 3px 0;
  -moz-border-radius: 0 3px 3px 0;
  border-radius: 0 3px 3px 0;
  width: 15%;
  display: table-cell;
  padding: 0 10px;
  height: 100%;
  text-align: center;
  vertical-align: top; }

.payment .y-button {
  margin: 15px 0; }

.payment .y-button a.fly {
  padding: 0 58px 0 0; }

.page_step_4_air .custom-radio-payment_type {
  float: left;
  margin-top: 10px; }

.custom-radio-payment_type {
  width: 40px;
  height: 40px;
  display: inline-block;
  position: relative;
  z-index: 1;
  /*top: 25px;*/
  float: left;
  margin: 0 0 0 5px;
  cursor: pointer; }

.custom-radio-payment_type input[type="radio"] {
  margin: 1px;
  position: absolute;
  z-index: 2;
  cursor: pointer;
  outline: none;
  opacity: 0;
  /* CSS hacks for older browsers */
  _noFocusLine: expression(this.hideFocus=true);
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  -khtml-opacity: 0;
  -moz-opacity: 0; }

.custom-radio-payment_type {
  background: url("./images/site/radio2.png") no-repeat; }

.custom-radio-payment_type:hover {
  background: url("./images/site/radio1-hover.png") no-repeat; }

.custom-radio-payment_type.selected {
  background: url("./images/site/radio1-selected.png") no-repeat; }

.payment_failure_alert {
  padding-top: 36px;
  padding-bottom: 36px; }

.klarna-container {
  padding: 10px;
  background-color: whitesmoke;
  border-radius: 5px; }

.top-box {
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  border: 1px solid #949494;
  background-color: #ffffff;
  width: 100%;
  margin-bottom: 10px;
  height: 100px;
  padding: 20px;
  display: table; }

.top-box div {
  float: left;
  width: 240px; }

.top-box .header {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 12px; }

.top-box .subheader {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 5px; }

.booking__cta_payment {
  background-color: #dc0000;
  min-height: 70px;
  margin-bottom: 10px;
  margin-top: 0;
  display: inline-block;
  padding: 15px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  border: 1px solid #6c6c6c;
  font-size: 23px;
  line-height: 1em;
  width: 100%; }
  .booking__cta_payment span {
    color: #ffffff;
    font-size: 27px;
    display: inline-block;
    margin-top: 11px; }
  .booking__cta_payment--processing {
    background-color: #fff3cd; }
    .booking__cta_payment--processing span {
      color: #000000; }

.spacer {
  clear: both;
  margin-top: 20px; }

.booking-product + .booking-product {
  margin-top: 10px; }

.booking-product .header {
  float: left;
  font-size: 18px;
  font-weight: bold;
  padding-bottom: 10px; }

.booking-product .payment-status {
  float: right;
  font-size: 18px;
  font-weight: bold; }

.booking-product .payment-status a.not-paid {
  text-decoration: underline; }

.booking-product .payment-status .not-paid {
  color: #ed1d1d; }

.booking-product .payment-status .paid {
  color: #1cc13b; }

.payment_success_str {
  width: 100%;
  display: inline-block;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  border: 1px solid #6c6c6c;
  margin-bottom: 10px; }

.upgrade-to-gold {
  height: 100%;
  background-color: #e7f0e8;
  margin-bottom: 10px;
  margin-top: 0;
  display: inline-block;
  padding: 15px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  border: 1px solid #6c6c6c; }

.upgrade-to-gold .header {
  font: bold 22px "Helvetica Neue", Arial, Helvetica, Geneva, sans-serif;
  padding-bottom: 10px;
  color: #1E23B0; }

.upgrade-to-gold ul li {
  padding-left: 28px;
  font: 15px "Helvetica Neue", Arial, Helvetica, Geneva, sans-serif;
  line-height: 27px;
  width: 50%;
  float: left;
  background: url("./images/site/good.png") 0 5px no-repeat; }

.upgrade-to-gold .price {
  font-weight: bold;
  font: 30px "Helvetica Neue", Arial, Helvetica, Geneva, sans-serif;
  float: left;
  margin-top: 17px;
  margin-left: 25px; }

.upgrade-to-gold .price .smalltext {
  font-size: 14px;
  padding-left: 12px; }

.upgrade-to-gold .upgrade_yes {
  clear: left; }

.upgrade-to-gold .button {
  padding: 0 15px;
  background: #16b501;
  border-radius: 3px;
  cursor: pointer;
  border: 1px solid #129e00;
  float: left;
  margin-top: 15px; }

.upgrade-to-gold .button:hover {
  background-color: #108900; }

.upgrade-to-gold .button a {
  float: left;
  height: 40px;
  line-height: 40px;
  font-size: 18px;
  font-weight: 400;
  color: #FFFFFF; }

.hotel_info {
  width: 800px;
  font-family: "Helvetica Neue", Arial, Helvetica, Geneva, sans-serif;
  padding-right: 5px !important;
  padding-left: 5px !important; }

div.resize-and-crop {
  overflow: hidden !important;
  display: -moz-inline-block;
  display: inline-block;
  position: relative; }

.hotel-widget {
  background-color: #ffffff;
  margin-bottom: 10px;
  padding: 15px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  border: 1px solid #6c6c6c;
  display: inline-block;
  width: 100%; }

.hotel-widget .banner {
  float: left;
  width: 165px; }

.hotel-widget .banner img {
  width: 165px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  margin-left: 3px; }

.hotel-widget .banner .dates {
  margin-top: 20px;
  text-align: center;
  font-weight: bold; }

.hotel-widget .banner .hotel_count {
  font-size: 25px;
  text-align: center;
  margin-top: 30px; }

.hotel-widget .banner .hotel_count span {
  font-size: 40px; }

.hotel-widget .banner .see_all {
  text-align: center;
  margin-top: 29px; }

.hotel-widget .banner .see_all a {
  color: #000000;
  text-decoration: underline;
  font-size: 100%; }

.hotel-widget .recommended_hotels_list {
  float: right;
  padding-left: 12px; }

.hotel-widget .hotel_img {
  border-radius: 3px 3px 0 0;
  position: relative;
  overflow: hidden;
  height: 90px; }

.hotel-widget .hotel_review_score {
  position: absolute;
  bottom: 10px;
  right: 10px;
  color: #fff;
  padding: 4px 4px 3px 4px;
  border-radius: 3px;
  z-index: 9;
  text-align: center;
  font-size: 16px;
  display: block;
  min-width: 43px;
  text-decoration: none;
  width: 75px;
  line-height: 14px; }

.hotel-widget .hotel_review_score {
  background: #1E23B0; }

.hotel-widget .hotel_review_score_word {
  font-size: 12px; }

.hotel-widget .hotel_list_hotel_name {
  color: #000000 !important;
  font-size: 90%;
  display: block;
  height: 29px;
  line-height: 1.1em;
  float: left; }

.hotel-widget .hotel_list_hotel_name h2 {
  float: left;
  font-size: 85%;
  color: #000000;
  max-width: 190px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap; }

.hotel-widget .hotel_info {
  margin-top: 0;
  margin-bottom: 0;
  width: 96%;
  height: 50px; }

.hotel-widget .hotel_info_img {
  float: left;
  margin-top: 8px;
  margin-left: 5px; }

.hotel-widget div.hotel_price_section {
  clear: left; }

.hotel-widget div.hotel_room_type {
  font-size: 12px;
  float: left; }

.hotel-widget .max_occ_icon {
  background-image: url("//q.bstatic.com/static/affiliate_base/img/aff_hotel_list_max_occ/3e5bac6eefc5e7f7a7d50d1b6ec6210c7bd26a5c.gif");
  background-repeat: no-repeat;
  display: inline-block;
  height: 15px;
  width: 22px;
  margin-left: 3px; }

.hotel-widget .max_occ_type_single {
  background-position: 0 5px; }

.hotel-widget .max_occ_type_double {
  background-position: 0 -16px; }

.hotel-widget .max_occ_type_triple {
  background-position: 0 -35px; }

.hotel-widget div.hotel_price_from {
  font-size: 18px;
  float: left;
  padding-top: 1px;
  padding-left: 15px; }

.hotel-widget a.b_submitButton.book_btn_position {
  color: #000000;
  text-decoration: underline;
  float: right;
  margin-top: 1px; }

.car-widget {
  background-color: #ffffff;
  margin-bottom: 10px;
  padding: 15px 15px 5px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  border: 1px solid #6c6c6c;
  display: inline-block;
  width: 100%; }

.car-widget .wrapper {
  width: 33%;
  float: left;
  margin-left: 4px; }

.car-widget .wrapper img.carimage {
  float: left;
  margin-right: 10px;
  width: 150px; }

.car-widget .wrapper .make {
  font-weight: bold; }

.car-widget .wrapper .type {
  font-size: 11px; }

.car-widget .wrapper .icons > div {
  display: inline-block;
  padding: 0 2px 0 22px;
  line-height: 20px;
  margin: 2px 0 0 0;
  -o-text-overflow: ellipsis;
  -ms-text-overflow: ellipsis;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  min-width: 12%;
  background-repeat: no-repeat;
  cursor: default;
  font-size: 10px;
  font-weight: normal; }

.car-widget .wrapper .seats {
  background: url(https://secure.rentalcars.com/widget/affiliate_rc/public/images/widgetGlyphs.png) left -63px no-repeat; }

.car-widget .wrapper .doors {
  background: url(https://secure.rentalcars.com/widget/affiliate_rc/public/images/widgetGlyphs.png) left -129px no-repeat; }

.car-widget .wrapper .aircon {
  background: url(https://secure.rentalcars.com/widget/affiliate_rc/public/images/widgetGlyphs.png) left 2px no-repeat; }

.car-widget .wrapper .transmission {
  background: url(https://secure.rentalcars.com/widget/affiliate_rc/public/images/widgetGlyphs.png) left -192px no-repeat; }

.car-widget .wrapper .was {
  color: #ee301a;
  margin-top: 7px;
  font-size: 12px;
  text-decoration: line-through;
  float: left; }

.car-widget .wrapper .now, .now2 {
  color: inherit;
  font-size: 18px;
  float: left;
  margin-left: 18px;
  margin-top: 7px; }

.car-widget .wrapper .searchNowButton {
  text-decoration: underline;
  cursor: pointer;
  margin-top: 7px;
  float: right;
  margin-right: 33px; }

.order-message {
  width: 100%;
  margin-bottom: 10px;
  margin-top: 0;
  padding: 15px;
  background-color: #fff3cd;
  border: 1px solid #856404;
  color: #856404;
  border-radius: 3px; }
  .order-message .header {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 12px; }
  .order-message p {
    font: 15px "Helvetica Neue", Arial, Helvetica, Geneva, sans-serif; }

.addon-button {
  padding: 0 15px;
  background: #16b501;
  color: #FFFFFF;
  border-radius: 3px;
  cursor: pointer;
  border: 1px solid #129e00;
  float: left;
  margin-top: 15px; }

.addon-button:hover {
  background: #108900; }

.addon-button input[type=submit] {
  background-color: #16b501;
  color: #FFFFFF; }

.addon-button input[type=submit]:hover {
  background-color: #108900; }

.addon-price {
  font-weight: bold;
  font: 18px "Helvetica Neue", Arial, Helvetica, Geneva, sans-serif;
  float: left;
  margin-top: 25px;
  margin-left: 25px; }

.addon-header {
  font: bold 22px "Helvetica Neue", Arial, Helvetica, Geneva, sans-serif;
  padding-bottom: 10px;
  color: #1E23B0; }

.upgrade-to-gold .upgrade_yes_multi {
  margin-right: 16px; }

.seating-container {
  display: flex;
  margin-left: 20px; }

.left-column {
  margin-right: 80px !important; }

.seatprice {
  text-align: center;
  font-size: 14px; }

.seating:first-child {
  margin-right: 20px; }

.seatclass {
  color: white;
  font-weight: 500;
  width: 60px !important; }

.seatclass0 {
  background-color: #0095B7; }

.seatclass1 {
  background-color: #C32148; }

.seatclass2 {
  background-color: #FCD667; }

.seatclass3 {
  background-color: #8359A3; }

.seatclass4 {
  background-color: #fc8367; }

.seatclass5 {
  background-color: #926F5B; }

.seatclass6 {
  background-color: #bbe6b8; }

.seatclass7 {
  background-color: #fcba03; }

.disabledSeat {
  background-color: #e6e6e6; }

.occupiedSeat {
  background-color: #e6e6e6; }

.selected-seat {
  background-color: #51cf5d !important; }

/* show booking page */
.page_show_booking {
  background: #FFF; }

.show_booking_div {
  text-align: center; }

.show_booking_div > ul {
  font-size: 0;
  margin: 0 15px; }

.show_booking_div > ul > li {
  display: inline-block;
  vertical-align: top;
  border-radius: 3px;
  border: 1px dashed #0A5AA6;
  font-size: 14px;
  color: #000000;
  line-height: 18px;
  text-align: left;
  width: 400px;
  padding: 10px;
  margin: 0 1% 15px 0; }

.show_booking_div div.title {
  border-radius: 3px;
  background: #0A5AA6;
  color: #FFF;
  text-align: center;
  padding: 10px 5px;
  font-size: 20px;
  margin: 0 0 15px 0; }

.show_booking_div .topM10 {
  margin-top: 10px; }

.show_booking_div div.inputs ul {
  margin-bottom: 5px;
  margin-top: 5px; }

.show_booking_div input[type="text"] {
  border-radius: 3px;
  border: 1px solid #0A5AA6;
  width: 100%;
  height: 38px;
  line-height: 38px;
  color: #0A5AA6;
  font-size: 18px;
  padding: 0 10px;
  float: left;
  margin-top: 10px; }

.page_cars #container #content {
  display: inline-block; }

.page_cars .cars .cars-content iframe {
  width: 900px;
  height: 900px; }

.top_gate_access .main {
  width: 85%;
  margin-top: 100px;
  margin-left: auto;
  margin-right: auto;
  float: none; }

.login-field {
  width: 400px;
  font-size: 20px;
  padding: 20px;
  margin-right: 20px; }

#login-submit:hover {
  background-color: #444445 !important;
  color: #fff; }

#login-submit {
  width: 225px;
  border: 1px solid #444445;
  padding-right: 0 !important;
  height: 64px !important;
  float: right; }

.login_error {
  padding-top: 20px;
  color: #FE3C33;
  font-weight: 400;
  font-size: 16px; }

.page-login .page-single {
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 16px 0;
  padding: 1rem 0; }

.col-login {
  width: 24rem; }

.col-login .card-title {
  font-size: 18px;
  font-size: 1.125rem;
  line-height: 1.2;
  font-weight: 400;
  margin-bottom: 24px;
  margin-bottom: 1.5rem; }

.col-login .form-control {
  display: block;
  width: 100%;
  height: 38px;
  height: 2.375rem;
  padding: 6px 12px;
  padding: .375rem .75rem;
  font-size: 15px;
  font-size: .9375rem;
  font-weight: 400;
  line-height: 1.6;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 40, 100, 0.12);
  border-radius: 3px;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out; }

.col-login .form-group,
.col-login .form-label {
  display: block; }

.col-login .form-group {
  margin-bottom: 16px;
  margin-bottom: 1rem; }

.page_index,
.page_cars,
.page_about,
.page_conditions,
.page-static {
  margin-bottom: 15px;
  margin-top: 15px; }

#map-canvas-all {
  width: 800px;
  height: 500px;
  margin: 0px;
  padding: 0px; }

#map-canvas-inner {
  width: 740px;
  height: 440px;
  margin: 30px 30px 30px 30px; }

#map-canvas-hotel {
  width: 800px;
  height: 500px;
  margin: 0px;
  padding: 0px; }

.show-booking {
  float: right;
  width: 29%;
  border-radius: 3px;
  border: 1px dashed #000000;
  padding: 1%;
  margin: 1%; }
  .show-booking div.title {
    border-radius: 3px;
    background: #000000;
    color: #FFF;
    text-align: center;
    padding: 10px 5px;
    font-size: 20px;
    margin: 0 0 15px 0; }
  .show-booking ul {
    margin-bottom: 5px;
    margin-top: 5px; }
  .show-booking .topM10 {
    margin-top: 10px; }
  .show-booking input[type="text"] {
    border-radius: 3px;
    border: 1px solid #000000;
    width: 100%;
    height: 38px;
    line-height: 38px;
    color: #000000;
    font-size: 18px;
    padding: 0 10px;
    float: left;
    margin-top: 10px; }

#alert-message {
  min-width: 200px;
  min-height: 100px; }

.ls-wrapper .ls-nav-right-arrow {
  background: url("./images/site/liquid-slider/arrow.png") no-repeat top right; }

.ls-wrapper .ls-nav-left-arrow {
  background: url("./images/site/liquid-slider/arrow.png") no-repeat top left; }

.ls-wrapper .ls-nav-left-arrow:hover {
  background: url("./images/site/liquid-slider/arrow.png") no-repeat bottom left; }

.ls-wrapper .ls-nav-right-arrow:hover {
  background: url("./images/site/liquid-slider/arrow.png") no-repeat bottom right; }

.button-close,
.button-previous,
.button-next {
  position: absolute;
  width: 44px;
  height: 44px;
  background: #000;
  text-align: center;
  line-height: 44px;
  color: #fff;
  text-decoration: none;
  border-radius: 50%;
  font-size: 16px; }

.button-previous,
.button-next {
  top: 50%;
  margin-top: -22px;
  line-height: 42px; }

.button-previous {
  left: -50px; }

.button-next {
  right: -50px; }

.button-close {
  top: -44px;
  right: -44px;
  font-size: 22px;
  color: rgba(255, 255, 255, 0.8); }

.active-header {
  background-repeat: no-repeat;
  background-position: right 50%;
  font-size: 18px;
  background: #ebebeb;
  margin: 5px 0 0 0;
  padding: 10px 55px 10px 15px;
  cursor: pointer;
  color: #FFFFFF;
  line-height: 22px;
  font-weight: 300;
  border-radius: 3px;
  float: left;
  width: 100%;
  box-sizing: border-box; }

.inactive-header {
  background-repeat: no-repeat;
  background-position: right 50%;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px; }

#accordion-container {
  font-size: 13px;
  display: none;
  padding: 15px;
  font-size: 16px;
  color: #252525;
  line-height: 22px;
  float: left;
  width: 100%;
  box-sizing: border-box; }

.accordion-header {
  font-size: 18px;
  background: #ebebeb;
  margin: 5px 0 0 0;
  padding: 10px 55px 10px 15px;
  cursor: pointer;
  color: #FFFFFF;
  line-height: 22px;
  font-weight: 300;
  border-radius: 3px;
  float: left;
  width: 100%;
  box-sizing: border-box; }

body.scroll {
  overflow-y: scroll; }

.w-2 {
  width: 2%;
  display: inline-block; }

.continue-btn-group {
  width: 100%;
  font-size: 0; }

.continue-btn {
  width: 49%;
  margin: 5px 0; }

.continue-btn {
  cursor: pointer;
  display: inline-block;
  padding: 0;
  border: 1px solid #949494;
  background: #efcb01;
  border-radius: 3px; }

input[type="submit"].continue-btn:hover {
  background-color: #daba01 !important; }
