.session_timer {
  width: 100%;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  background: #FFF;
  font-size: 22px;
  color: #000;
  margin: 0 0 15px;
  border: 1px solid #949494;
  padding: 10px 20px 10px 20px;
}

.session_timer .time_left {
  color: #972422;
  font-weight: bold;
  font-size: 25px;
}

.session_timeout {
  display: none;
}
