* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

body {
  min-width: 1260px;
  line-height: 18px;
  font-family: 'Roboto Condensed', sans-serif;
  font-weight: 300;
  color: #000;
  height: 100%;
  font-size: 16px;
}

.clear {
  margin: 0;
  padding: 0;
  clear: both;
}

.last {
  margin-right: 0 !important;
}

a {
  text-decoration: none;
}

sub {
  vertical-align: text-bottom;
  font-size: 60%;
}

sup {
  vertical-align: text-top;
  font-size: 60%;
}

strong,
b {
  font-weight: 400;
}

.left {
  float: left
}

.right {
  float: right
}

.wd {
  width: 100%
}

.pd {
  padding: 10px;
}

.mrR20 {
  margin-right: 20px;
}

.mrL20 {
  margin-left: 20px;
}

.pdL10 {
  padding-left: 10px;
}

.pdR10 {
  padding-right: 10px;
}

.pdL20 {
  padding-left: 20px;
}

.pdR20 {
  padding-right: 20px;
}

.position-relative {
  position: relative;
}

.sectionspace {
  padding-top: 40px;
}

.mr0 {
  margin: 0 !important
}

::-ms-clear {
  display: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 400;
  line-height: 30px;
}

input,
select {
  border: 0;
  border-radius: 3px;
  font-family: 'Roboto Condensed', sans-serif;
  font-weight: 300;
}

input:focus,
input:hover {
  background-color: #fdf5ce !important
}

input[type="submit"]:focus,
input[type="submit"]:hover {
  background-color: transparent !important
}

.fancysupport .fancybox-close {
  display: inline;
}

.fancyconditions .fancybox-close {
  display: inline;
}

.show_as_mobile {
  text-align: center;
  padding: 15px 10px 10px 10px;
}

.show_as_mobile img {
  position: relative;
  top: 7px;
  padding-right: 5px;
}

.show_as_mobile a {
  color: #000;
  font-size: 20px;
  font-weight: 400;
}

.hr {
  height: 1px;
  margin: 0px 1% 0px 1%;
}

.back_btn {
  padding: 0 5px 0 0;
  border-radius: 3px;
  cursor: pointer;
  display: inline-block;
  margin-top: -10px;
}

.button-end { // TODO: REMOVE
  float: right;
  width: 100%;
  margin: 30px 0 0 0;
}

.yd-button {
  padding: 0 15px;
  border-radius: 3px;
  cursor: pointer;
  display: inline-block;
}

.yd-button a {
  float: left;
  height: 40px;
  line-height: 40px;
  font-size: 18px;
  font-weight: 400;
  color: #FFFFFF;
}

.yd-button .back {
  padding: 0 30px 0 0;
}

.yd-button .fly {
  padding: 0 40px 0 0;
  color: #FFFFFF;
}

.yd-button-pop {
  padding: 0 15px;
  border-radius: 3px;
  cursor: pointer;
  display: inline-block;
}

.yd-button-pop a {
  float: left;
  height: 40px;
  line-height: 40px;
  font-size: 18px;
  font-weight: 400;
  color: #FFFFFF;
}

.yd-button-pop .back {
  padding: 0 30px 0 0;
}

.yd-button-pop .fly {
  padding: 0 0px 0 0;
  color: #FFFFFF;
}

.yd-button-pop .hotel {
  padding: 0 40px 0 0;
  color: #FFFFFF;
}

/*========== Custom ==============*/

.layout {
  max-width: 1260px;
  width: 98%;
  margin: 0 auto;
}

/*========== Navigation ==============*/

nav.mainnav {
  position: relative;
  background-color: #fdf5ce !important;
  top: 20px
}

ul.topnav {
  float: right;
}

ul.topnav li {
  float: left;
  margin: 0 0 0 5px;
}

ul.topnav li a {
  font-size: 16px;
  text-transform: uppercase;
  padding: 5px 10px;
  border-radius: 3px;
}

ul.topnav li a:hover,
ul.topnav li a.active {
  color: #FFF;
}

/*========== Navigation ==============*/

#wrap {
  float: left;
  width: 100%;
}

.top20 {
  padding-top: 20px;
}

.main {
  float: left;
  width: 100%;
  border-radius: 3px;
  padding: 15px 0px 5px 0px; // margin-bottom: 50px;
}

.white-bg {
  background: #FFF;
}

.service-package-wrap {
  float: left;
  width: 100%;
}

.service-package {
  padding: 0 15px
}

.service-package label {
  float: left;
  margin: 0 15px 15px 0px;
  cursor: pointer
}

.service-package label span {
  float: left;
  line-height: 40px;
  padding: 0 0 0 5px;
  font-size: 22px;
  color: #000;
}

.custom-radio-search_type {
  width: 40px;
  height: 40px;
  display: inline-block;
  position: relative;
  z-index: 1;
  /*top: 25px;*/
  margin: 0 0 0 5px;
  cursor: pointer;
  float: left;
}

.custom-radio-search_type input[type="radio"] {
  margin: 1px;
  position: absolute;
  z-index: 2;
  cursor: pointer;
  outline: none;
  opacity: 0;
  /* CSS hacks for older browsers */
  _noFocusLine: expression(this.hideFocus=true);
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  -khtml-opacity: 0;
  -moz-opacity: 0;
}

.booking_information {
  padding: 10px;
  //display: none;
}

.booking_information img {
  float: right;
}

.booking_information .text {
  font-size: 15px; // width: 550px;
}

.inline-picker {
  width: 100%;
}

.search_box {
  position: relative;
  // max-height: 700px;
  overflow: hidden;
}

.search_box_small {
  max-height: 200px;
  overflow: hidden;
}

.search_box_overlay {
  height: 100%;
  width: 100%;
  top: 0;
  left: 0px;
  position: absolute;
  opacity: 0.9;
  filter: alpha(opacity=90);
  z-index: 1000;
  background-color: #FFF;
  display: none;
}

.search_box_overlay_top {
  height: 100%;
  width: 100%;
  top: 0;
  left: 0px;
  position: absolute;
  z-index: 1001;
  margin-top: 15px;
  font-weight: 400;
  display: none;
}

.search_box_overlay_top .title {
  color: #000;
  font-size: 30px;
  line-height: 35px;
  padding: 0 0 30px 0;
}

.search_box_overlay_top .title span {
  font-size: 22px;
  display: block;
  padding: 10px 0 0 0;
}

.search_box_overlay_top .package {
  display: none;
}

.search_box_overlay_top .hotel {
  display: none;
}

.search_box_overlay_top .air {
  display: none;
}

.tooltipster-red .tooltipster-box {
  background: #FC575E !important;
  border: 2px solid #444 !important;
  border-radius: 6px;
  box-shadow: 2px 2px 2px 0 rgba(0, 0, 0, 0.4);
}

.tooltipster-red .tooltipster-content {
  color: #FFF !important;
  padding: 8px;
}

.tooltipster-red.tooltipster-top .tooltipster-arrow-background {
  border-top-color: #FC575E;
}

.tooltipster-red.tooltipster-left .tooltipster-arrow-background {
  border-left-color: #FC575E;
}

.tooltipster-content sub {
  bottom: -0.25em;
  position: relative;
  vertical-align: baseline;
}

.main_btn_wide {
  width: 311px;
}

.custom-checkbox-search_only_direct_flights,
.custom-checkbox-search_advanced_hotel_search,
.custom-checkbox-search_only_with_bags,
.custom-checkbox-search_max_one_stop,
.custom-checkbox-search_only_private_fares,
.custom-checkbox-search_only_public_fares,
.custom-checkbox-search_no_ndc,
.custom-checkbox-search_only_rebookable {
  width: 40px;
  height: 40px;
  display: inline-block;
  position: relative;
  z-index: 1;
  top: 3px;
  cursor: pointer;
}

.custom-checkbox-search_only_direct_flights input[type="checkbox"],
.custom-checkbox-search_advanced_hotel_search input[type="checkbox"],
.custom-checkbox-search_only_with_bags input[type="checkbox"],
.custom-checkbox-search_max_one_stop input[type="checkbox"],
.custom-checkbox-search_only_private_fares input[type="checkbox"],
.custom-checkbox-search_only_public_fares input[type="checkbox"],
.custom-checkbox-search_no_ndc input[type="checkbox"],
.custom-checkbox-search_only_rebookable input[type="checkbox"] {
  margin: 0;
  position: absolute;
  z-index: 2;
  cursor: pointer;
  outline: none;
  opacity: 0;
  /* CSS hacks for older browsers */
  _noFocusLine: expression(this.hideFocus=true);
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  -khtml-opacity: 0;
  -moz-opacity: 0;
}

.fly-form ul li.direct-flight span {
  float: left;
  line-height: 40px;
  padding: 0 0 0 5px;
  cursor: pointer;
}

button[type="submit"], input[type="submit"] {
  height: 40px;
  font-size: 18px;
  cursor: pointer;
  font-weight: 400;
}

.disable {
  opacity: 0.5;
}

div.yellow-submit {
  padding: 0 10px;
  border-radius: 3px;
  border: 1px solid #999;
}

.f-scroll-wrap {
  border-radius: 3px;
  float: right;
  width: 24%;
  padding: 0 0 10px 0;
  margin: 0 15px 15px 0;
}

.f-scroll-title {
  border-radius: 3px 3px 0 0;
  font-size: 20px;
  text-transform: uppercase;
  font-weight: 400;
  display: block;
  padding: 10px;
  margin: 0 0 10px 0;
}

.f-scroll-title span {
  font-size: 14px;
  font-weight: 300;
  text-transform: none;
  display: block;
}

.f-scroll {
  width: 100%;
  float: left;
}

.f-scroll ul {
  padding: 0 6px;
}

.f-scroll ul li {
  list-type: none;
  margin: 0;
  float: left;
  width: 100%;
}

.f-scroll ul li a {
  border-radius: 3px;
  margin: 5px 0;
  float: left;
  width: 100%;
}

.f-scroll ul li div {
  font-size: 16px;
  padding: 8px 10px;
}

.f-scroll ul li div strong {
  font-weight: 400;
}

.f-scroll ul li div span {
  color: #c2c2c2;
  font-size: 12px;
  display: block;
}

.f-scroll ul li div.left {
  width: 60%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.f-scroll ul li div.right {
  width: 40%;
  text-align: right;
  padding-top: 10px;
}

.youth-age {
  width: 100%;
  text-align: center;
  padding-top: 6px;
  font-size: 14px;
}

#youth-tickets-info {
  width: 100%;
  padding: 6px 0 8px 4px;
  font-size: 16px;
  display: none;
}

.covid-container {
  display: block;
  font-size: 16px;
  margin: 0 30px;

  .bottom-text {
    text-align: right;
  }

  .top-paragraph,
  .bottom-text {
    margin-top: 20px;
  }
}

.destination-container {
  display: block;
  font-size: 16px;
  margin: 0 30px;

  p {
    margin-bottom: 10px;
  }

  margin-bottom: 60px;
}

.about-sec {
  float: left;
  width: 69%;
  padding: 20px 0;
}

.about-sec .image {
  float: left;
  margin: 0 20px 0 0;
  padding: 0 0 0 15px;
  width: 30%;
}

.about-sec img {
  border-radius: 3px;
  width: 100%;
}

.about-sec div.con {
  font-size: 16px;
  padding: 0 15px 0 0;
  float: right;
  width: 66%;
}

.about-sec div.con .title,
.covid-container .title {
  color: #000;
  font-size: 30px;
  padding: 0 0 30px 0;
}

.about-sec div.con .title span {
  font-size: 22px;
  display: block;
  padding: 10px 0 0 0;
}

.shortcon {
  float: left;
  width: 100%;
}

.shortcon ul {
  font-size: 0;
  text-align: center;
  margin: 20px 15px 0 15px;
}

.shortcon ul li {
  display: inline-block;
  vertical-align: top;
  border-radius: 3px;
  font-size: 14px;
  color: #000000;
  line-height: 18px;
  text-align: left;
  width: 24.2%;
  padding: 1%;
  /* padding percentage height doesnt work in firefox.... */
  padding-top: 10px;
  padding-bottom: 10px;
  margin: 0 1% 20px 0;
  min-height: 125px;
}

.shortcon ul li+li+li+li {
  margin-right: 0;
}

.shortcon ul li div.title {
  font-size: 18px;
  font-weight: 400;
  float: left;
  width: 100%;
  margin: 0 0 10px 0;
}

.mainbanner {
  float: left;
  width: 100%;
  text-align: center;
}

.mainbanner div.space {
  padding: 0 15px;
}

.mainbanner img {
  border-radius: 3px;
  width: 100%;
}

.cheapest-destinations {
  float: left;
  width: 100%;
  margin: 20px 0 0 0;
}

.cheapest-destinations>ul {
  font-size: 0;
  text-align: center;
  margin: 0 15px
}

.cheapest-destinations>ul>li {
  display: inline-block;
  vertical-align: top;
  border-radius: 3px;
  font-size: 14px;
  color: #000000;
  line-height: 18px;
  text-align: left;
  width: 24.2%;
  padding: 1%;
  margin: 0 1% 15px 0;
  min-height: 395px
}

.cheapest-destinations>ul>li+li+li+li {
  margin-right: 0;
}

.cheapest-destinations>ul>li div.title {
  border-radius: 3px;
  color: #FFF;
  text-align: center;
  padding: 10px 5px;
  font-size: 20px;
  margin: 0 0 15px 0;
}

ul.cd-list {
  float: left;
  width: 100%;
}

ul.cd-list li {
  float: left;
  width: 100%;
  border-bottom: 1px dashed #d8f2ff;
}

ul.cd-list li:last-child {
  border: 0;
}

ul.cd-list li a {
  display: block;
  color: #000;
  padding: 1px 0;
}

ul.cd-list li a:hover {
  background: #f4f4f4;
}

ul.cd-list li span {
  text-align: right;
  float: right;
}

.footer-logo {
  display: block;
  padding: 15px;

  ul {
    border-radius: 3px;
    text-align: center;

    li {
      display: inline-block;
      margin: 10px 15px;
    }
  }
}

.footer-bottom {
  float: left;
  width: 100%;
  font-size: 12px;
  font-weight: 300 !important;
  color: #000;
  text-transform: uppercase;
}

ul.footer-menu {
  float: left;
  margin: 0 0 0 15px;
}

ul.footer-menu li {
  float: left;
  margin: 0 5px 0 0;
  padding: 0 5px 0 0;
}

ul.footer-menu li:last-child {
  background: none;
  margin: 0;
  padding: 0;
}

ul.footer-menu li a {
  color: #000;
}

ul.footer-menu li a:hover {
  text-decoration: underline;
}

div.sidebyside {
  display: inline-block;
  width: 135px;
}

.copyright {
  float: right;
  margin: 0 15px 0 0;
}

#searching {
  margin-top: 15px;
  border: 1px solid #949494;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}

#searching .title {
  color: #000;
  font-size: 30px;
  line-height: 35px;
  padding: 0 0 30px 0;
}

#searching .title span {
  font-size: 22px;
  display: block;
  padding: 10px 0 0 0;
}

.flight {
  text-align: center;
}

.fly-form ul li label.room-icon span {
  height: 44px;
  line-height: 44px;
  padding: 0 0 0 45px;
  margin-bottom: 12px;
  display: block;
}

.fly-form ul.smallrow li {
  width: 21.2%;
}

.fly-form ul.smallrow li.xsmall {
  width: 12.7%;
}

.fly-form ul.smallrow li.xsmall-first {
  width: 12.7%;
}

.fly-form ul.smallrow li.xmed {
  min-width: 155px;
}

.fly-form ul.smallrow li.xbig {
  min-width: 206px;
}

.fly-form ul.smallrow li.roomlist {
  width: 13%;
  margin-right: 2%;
}

.fly-form ul.smallrow li.roomlist select {
  width: 30%;
  float: left;
}

.fly-form ul.smallrow li.roomlist label {
  float: left;
}

.fly-form ul.smallrow li+li+li {
  margin: 0 5% 0 0;
}

.fly-form ul.smallrow li+li+li+li {
  margin-right: 0;
}

.room-number {
  padding: 10px;
  display: block;
}

.clr:before,
.clr:after {
  content: "";
  display: table;
}

.clr:after {
  clear: both;
}

/* Contact Page
--------------------------------------*/

#container {
  padding: 0 15px;
}

#content {
  width: 944px;
}

#sidebar {
  width: 256px;
  float: right;
}

#content h1 {
  font-size: 36px;
  margin-bottom: 20px;
}

#content h4 {
  font-size: 18px;
  color: #000;
  text-decoration: underline;
}

#content p {
  margin-bottom: 20px;
  line-height: 22px;
}

#content .addressmap {
  height:494px;
  width: 100%;
  float: left;
  margin: 0;
}

#content ul.list {
  margin-bottom: 20px;
  margin-left: 15px;
  line-height: 22px;
}

#content ul.list {
  list-style-type: disc;
}

#content ul.list ul.sublist {
  padding-left:10px;
  list-style-type: circle
}

ul.condition {}

ul.condition>li {
  border-bottom: 2px solid #f7f7f7;
  margin-bottom: 20px
}

ul.condition>li:last-child {
  border: none;
  margin-bottom: 0
}

.ui-state-focus {
  background: #d4d4d4
}

/*-------------------------------------- Sidebar */

#sidebar .searchflyform {
  border-radius: 3px;
  background: #f1f1f1;
  padding: 12px;
  width: 100%;
  float: left;
}

#sidebar .title {
  border-radius: 3px;
  color: #FFF;
  font-size: 18px;
  padding: 10px;
}

#sidebar .searchflyform ul {}

#sidebar .searchflyform ul li {
  width: 100%;
  margin: 12px 0 0 0;
  float: left;
}

#sidebar .searchflyform input[type="text"] {
  width: 100%;
  height: 38px;
  float: left;
  background: #FFF;
  font-size: 18px;
  text-transform: uppercase;
  padding: 0 15px 0 40px;
  line-height: 38px;
}

#sidebar .searchflyform a {
  font-size: 18px;
  text-decoration: underline;
}

#sidebar .searchflyform a:hover {
  text-decoration: none;
}

#sidebar .ui-selectmenu-menu li a,
#sidebar .ui-selectmenu-status {
  padding-left: 40px
}

#sidebar .addressbox {
  width: 100%;
  color: #fff;
  padding: 10px 15px 5px 15px;
  float: right;
  font-size: 18px;
  margin: 20px 0 0 0;
  border-radius: 3px;
}

#sidebar .addressbox p {
  margin-bottom: 10px;
  padding: 0 0 0 26px;
  line-height: normal;
  color: #FFF
}

#sidebar .addressbox p.email a {
  color: #fff;
}

#sidebar .addressbox p.phone a[href^=tel] {
  color: inherit;
  text-decoration: none;
}

#sidebar .addressbox b {
  color: #eccc00;
}

.ui-selectmenu-menu .select-airline {
  width: 302px !important;
}

.select-airline .ui-selectmenu-status {
  background: url('./images/site/blue-fly.png') #FFF no-repeat 10px center;
}

/*-------------------------------------- beforetrip */

.secureuserimg {
  float: left;
  width: 10%;
}

.secureusercontent {
  float: right;
  width: 85%;
}

.aeimg {
  float: left;
  width: 30%;
}

.aeimg img {
  margin-bottom: 25px;
}

.aecontent {
  float: right;
  width: 65%;
}

/*-------------------------------------- Warning MSG */

.warningmsg {
  text-align: center;
  float: left;
  width: 100%;
  border: 4px solid #CCC;
  padding: 40px 20px;
  background: #FFF
}

.warningmsg h1 {
  font-size: 36px;
  margin: 20px 0;
}

.warningmsg h3 {
  font-size: 20px;
  color: #666
}

.warningmsg ul {
  width: 100%;
  display: block;
  font-size: 0;
  margin: 50px 0 0 0;
}

.warningmsg ul li {
  display: inline-block;
  vertical-align: top;
  padding: 0 30px;
  font-size: 20px;
  font-weight: 400;
  line-height: 20px;
  border-right: 1px solid #ececec
}

.warningmsg ul li:last-child {
  border: none;
}

.warningmsg ul li .version {
  color: #666;
  font-weight: 300;
  font-size: 14px;
}

/*-------------------------------------- Top Search */

.ui-autocomplete {
  z-index: 11;
}

// .btn.btn-xs.btn-primary {
//   background: #0A5AA6;
//   color: #FFF;
//   padding: 0 10px;
//   border-radius: 3px;
//   height: 40px;
//   cursor: pointer;
// }

.message-panel {
  z-index: 9999;
  position: fixed;
  background-color: rgba(255, 0, 0, 0.75);
  bottom: 0;
  left: 0;
  text-align: center;
  color: white;
  padding: 10px;
  height: 125px;
  overflow-wrap: break-word;
  width: 400px;
  line-height: 24px;
  border-radius: 0 10px 0 0;
  -webkit-transition: opacity 0.5s ease-in-out;
  -moz-transition: opacity 0.5s ease-in-out;
  transition: opacity 0.5s ease-in-out;
}

//Search ------------------------------------------------//
.autocomplete-container.ui-menu-item-wrapper {
  padding: 5px 10px;
}
.ui-menu .ui-widget .ui-widget-content .ui-autocomplete .ui-front {
  height: auto;
}

.autocomplete-container.ui-menu-item-wrapper.ui-state-active {
  color: #000000;
  margin: 0;
}

.searchfield .container {
  font-family: 'Roboto Condensed', sans-serif; //padding: 5px 10px 5px 10px;
  display: inline-block;
}

.searchfield .name {
  font-family: 'Roboto Condensed', sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
}

.searchfield .country {
  font-family: 'Roboto Condensed', sans-serif;
  display: block;
  font-size: 14px;
  line-height: 18px;
  font-weight: 300;
}

.searchfield .iata {
  font-family: 'Roboto Condensed', sans-serif;
  float: right;
  padding-right: 10px;
  padding-top: 10px;
  padding-bottom: 14px;
  vertical-align: middle;
  font-size: 16px;
  line-height: 18px;
  font-weight: 300;
  font-style: normal;
}

.searchfield.cityairport .container {
  padding-left: 20px !important;
}

.noselect {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}

.inline-block {
  width: 100%;
  display: inline-block;
}

.inline-block-no-width {
  display: inline-block;
}
