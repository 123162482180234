:root {
  --border: #2311b2;
  --select: #E0FFFF;
}

body {
  background: $color1;
}

.hr {
  border-bottom: 1px solid $color1;
}
.rating-title{
  //color: #1866aa;
  font-size:14px;
  float: left;
}
#map-canvas {
  width: 800px;
  height: 500px;
  margin: 0px;
  padding: 0px
}

.y-button {
  background: $color8;
  a {
    color: $color7;
  }
  a.back {
    background: url('../general/desktop/images/site/back.png') no-repeat right 9px;
  }
}
/*========== Pop-Up ==============*/
.map-card__wrapper{
  height:180px;
  width:300px;
}
.map-card__image-container {
  position: absolute;
  width: 45%;
  top: 0;
  right: 65%;
  bottom: 0;
  left: 0;
  border-radius: 3px 0 0 3px;
  overflow: hidden;
  background: #fff;
}
.map-card__image {
  position: absolute;
    top: 10px;
    right: 0;
    bottom: 10px;
    left: 10px;
    background-repeat: no-repeat !important;
    background-size: 100% 100% !important;
}
.map-card__content-container {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  position: relative;
  margin: 0 0 0 45%;
  padding: 10px 5px 10px 10px;
  background: #fff;
  border-radius: 0 3px 3px 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  min-height: 130px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.map-card__title {
  position: relative;
  margin-bottom: 10px;
  font-size: 16px;
    line-height: 24px;
    font-weight: 600;
    color: #07c;
    text-decoration: none;
    cursor: pointer;
}
.map-card__review{
  position: relative;
  margin-bottom: 10px;
}
.map-card__price-container {
  position: relative;
  color: #383838;
  margin-top: auto;
  font-size: 14px;
  margin-bottom: 10px;
}
.map-card__address{
  font-weight: 600;
  font-size:14px;
}
.star-image{
  content: url('../general/desktop/images/icons/star.png')
}


/*========== Pop-Up-End ==============*/
.back_btn {
  background: $color8;
}

.y-button {
  a.fly,
  input.fly,
  input.hotel {
    background: $color10;
    min-width: 150px;
    white-space: nowrap;
  }
  .back {
    background: url('../general/desktop/images/site/back-white.png') no-repeat right 9px;
  }
  .fly {
    //background: url('shared/fly-white.png') no-repeat right 9px;
  }
}

.yd-button {
  background: $color11;
  .back {
    background: url('../general/desktop/images/site/back-white.png') no-repeat right 9px;
  }
  .fly {
    //background: url('shared/fly-white.png') no-repeat right 9px;
  }
}

.yd-button-pop {
  background: $color2;
  .back {
    background: url('../general/desktop/images/site/back-white.png') no-repeat right 9px;
  }
  .fly {
    background: $color3;
  }
  .hotel {
    background: url('../general/desktop/images/site/white-house.png') no-repeat right 7px;
  }
}

/*========== Custom ==============*/

header {
  padding-bottom: 60px;
}
#header {
  background: $color4;
  height: 60px;
  float: left;
  width: 100%;
  margin: 0 0 80px 0;
  position: fixed;
  z-index: 1002;
  display: block;
  border-bottom: 1px solid $color1
}
.logo-wrap {
  width: 299px;
  height: 98px;
  // background: url('flygcity_responsive/logo-bg.png', $quality: "auto", $fetch_format: "auto") no-repeat left top;
  margin: 0 0 0 -70px;
}
.logo {
  position: absolute;
  top: 12px;
}
ul.topnav li a {
  color: $color2;
}
#cookie_message_popup{
  height: 581px !important;
	width: 648px !important;
	border: 0px none rgb(0, 0, 0) !important;
	border-top: 0px none rgb(0, 0, 0)!important;
	border-right: 0px none rgb(0, 0, 0)!important;
	border-bottom: 0px none rgb(0, 0, 0)!important;
	border-left: 0px none rgb(0, 0, 0)!important;
	margin: 0px 0px 6px!important;
	padding: 24px!important;
	max-height: none!important;
	min-height: 0px!important;
	max-width: 100%!important;
	min-width: 0px!important;
}
ul.topnav li a:hover, ul.topnav li a.active {
  background: $color2;
}
.custom-radio-search_type {
  background: url('../general/desktop/images/site/radio.png') no-repeat;
}
.custom-radio-search_type:hover {
  background: url('../general/desktop/images/site/radio1-hover.png') no-repeat;
}
.custom-radio-search_type.selected {
  background: url('../general/desktop/images/site/radio-selected.png') no-repeat;
}

.fly-form {
  color: $color2;
}
.fly-form .title {
  background: $color2;
  border: 1px solid $color2;
}
.fly-form input[type="text"] {
  border: 1px solid $color2;
  color: $color2;
}
div.yellow-submit {
  background: $color8;
}
div.yellow-submit input[type="submit"] {
  color: $color2;
  background: $color8;
}
input.yellow-submit-hotel[type="submit"] {
  background: url('../general/desktop/images/icons/room-icon.png') no-repeat right 8px !important;
  background-size: 25px !important;
}

.fancy-button,
.fancy-button:hover,
.fancy-button:focus {
  font-size: 18px;
  cursor: pointer;
  font-weight: 400;
  color: $color4;
  background: $color6 !important;
  padding: 10px;
  text-align: center;
  border-radius: 5px;

  a {
    color: $color4;
  }
  &.upgrade-service {
    margin-bottom: 16px;
  }
}

.about-sec div.con .title {
  padding: 0;
}

.f-scroll-wrap {
  background: $color2;
}

.f-scroll-title {
  background: $color1;
  color: $color7;
}

.f-scroll {
  ul li a {
    border: 1px dashed $color4;
    &:hover {
      border: 1px dashed $color1;
    }
  }
}

.f-scroll ul li a
.f-scroll ul li div {
  color: $color4;
}

.about-sec div.con {
  color: $color2;
}
.shortcon ul li {
  border: 1px dashed $color2;
}
.shortcon ul li div.title {
  color: $color2;
}
.cheapest-destinations > ul > li {
  border: 1px dashed $color2;
}
.cheapest-destinations > ul > li div.title {
  background: $color2;
}

.footer-logo ul {
  background: $color11;
  padding: 0 10%;
}

ul.footer-menu li {
  background: url('../general/desktop/images/site/nav-divider.jpg') no-repeat right 4px;
}
.fly-form ul li label.room-icon span {
  background: url('../general/desktop/images/icons/room-icon.png') no-repeat 0px center;
}

#content {
  color: $color2;

  h1 {
    color: $color2;
  }
}

#sidebar {
  .searchflyform {
    border: 1px solid $color2;
  }
  .ui-selectmenu-text{
    padding-left: 40px;
  }
  .title {
    background: $color2;
  }
  .searchflyform input[type="text"] {
    border: 1px solid $color2;
    color: $color2;
  }
  .searchflyform input[type="text"].field-1 {
    background: url('../general/desktop/images/site/fly-icon.png') $color4 no-repeat 10px center;
  }
  .searchflyform input[type="text"].field-2 {
    background: url('../general/desktop/images/site/fly-back-icon.png') $color4 no-repeat 10px center;
  }
  .searchflyform input[type="text"].field-3 {
    background: url('../general/desktop/images/site/destination-icon.png') $color4 no-repeat 10px center;
  }
  .searchflyform a {
    color: $color2;
  }
  .addressbox {
    background: $color2;
    a {
      color: $color4;
    }
    p.address {
      background: url('../general/desktop/images/site/icon-address.png') no-repeat 0 6px;
    }
    p.email {
      background: url('../general/desktop/images/site/icon-email.png') no-repeat 0 6px;
    }
    p.email a:hover {
      color: $color1;
    }
    p.phone {
      background: url('../general/desktop/images/site/icon-phone.png') no-repeat 0 6px;
    }
    p.info {
      //background: url('shared/site/icon-info.png') no-repeat 0 6px;
    }
  }
  #search_adults-button {
    background: url('../general/desktop/images/site/adults-small-icon.png') $color4 no-repeat 8px center;
  }
  #search_children-button {
    background: url('../general/desktop/images/site/child-small-icon.png') $color4 no-repeat 9px center;
  }
  #search_infants-button {
    background: url('../general/desktop/images/site/baby-small-icon.png') $color4 no-repeat 8px center;
  }
}

.warningmsg h1 {
  color: $color2;
}


// Select fields and dropdowns

.ui-selectmenu-button{
  color: #2311b2;
  padding: 0 35px 0 10px;
  height: 38px;
  border: 1px solid #2311b2;
  width:100% !important;
  font-family: 'Roboto Condensed', sans-serif;
    font-size: 18px;
    font-weight: 300;
    background: #FFF;
    line-height: 38px;
    overflow: hidden;
}

.ui-selectmenu-button:hover{
  background:#fdf5ce;
  color: #2311b2;
  border: 1px solid #2311b2;
  font-family: 'Roboto Condensed', sans-serif;
    font-size: 18px;
    font-weight: 300;
}
.ui-button:focus{
  color: #2311b2;
  padding: 0 35px 0 10px;
  height: 38px;
  border: 1px solid #2311b2;
  width:100% !important;
  font-family: 'Roboto Condensed', sans-serif;
    font-size: 18px;
    font-weight: 300;
    background: #FFF;
    line-height: 38px;
    overflow: hidden;
}
.ui-selectmenu-button.ui-button .ui-icon{
  position: absolute;
  margin-top: -8px;
  top: 50%;
  width: 18px;
  height: 16px;
  right: 10px;
  background: url('../general/desktop/images/site/selectlist-icon.png') no-repeat;
}
.ui-state-active, .ui-widget-content .ui-state-active, .ui-widget-header .ui-state-active, a.ui-button:active, .ui-button:active, .ui-state-active.ui-button:hover
{
  background: #cdcdcd;
  color: #2311b2;
  border: none;
  font-family: 'Roboto Condensed', sans-serif;
    font-weight: 300;
    padding: 5px 10px;
  //height: auto;
}


.ui-menu .ui-menu-item-wrapper{
  line-height: normal;
    padding: 5px 10px;
    font-size: 14px;
}

.ui-menu-item-wrapper.ui-state-active{
  margin:0;
}


// TODO: Look at this
.show-booking {
  float: right;
  width: 29%;
  border-radius: 3px;
  border: 1px dashed #2311b2;
  padding: 1%;
  margin: 1%;
}

.show-booking div.title {
  border-radius: 3px;
  background: #2311b2;
  color: #FFF;
  text-align: center;
  padding: 10px 5px;
  font-size: 20px;
  margin: 0 0 15px 0;
}

.show-booking ul {
  margin-bottom: 5px;
  margin-top: 5px;
}

.show-booking .topM10 {
  margin-top: 10px;
}

.show-booking input[type="text"] {
  border-radius: 3px;
  border: 1px solid #2311b2;
  width: 100%;
  height: 38px;
  line-height: 38px;
  color: #2311b2;
  font-size: 18px;
  padding: 0 10px;
  float: left;
  margin-top: 10px;
}
.session_timer .time_left {
  color: #210FB5;
}
.yellow-title {
  color: #2311b2;
}

.with_upgrade_choosen .header {
  color: #1E23B0;
}

ul.eservice li p {
  color: #2311b2;
}

ul.eservice li div.radio-section {
  color: #2311b2;
}

ul.eservice li div.readmore {
  background: #2311b2;
}

.service-popup .title {
  color: #2311b2;
}

.service-popup .title_blue {
  color: #2311b2;
}

.service-popup .hr {
  border-color: #2311b2;
}
.payment-wrap>ul>li {
  color: #2311b2;
}

.payment {
  background: #2311b2;
}

.booking-confirm {
  color: #2311b2;
}

.booking-confirm div.hr {
  border-color: #2311b2;
}

.back-home a {
  color: #2311b2;
}
