div.fly-schedule div.baggage {
  float: left;
  clear: left;
}

div.fly-schedule div.baggage div.headline {
  font-weight: bold;
  font-size: 13px;
  float: left;
}

div.fly-schedule div.baggage div.passengers {
  margin-left: 15px;
  display: inline-block;
}

div.fly-schedule div.baggage div.passenger {
  padding-bottom: 2px;
  clear: left;
}

div.fly-schedule div.baggage div.passenger_number {
  float: left;
  font-size: 12px;
  width: 95px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

div.fly-schedule div.baggage div.passenger div.leg {
  font-size: 12px;
  float: left;
  white-space: nowrap;
  text-wrap: balance;
  margin-right: 10px;
}

div.fly-schedule div.baggage div.passenger div.leg label {
  white-space: nowrap;
}

div.fly-schedule div.baggage div.passenger div.leg div.ancillary_item {
  display: block;
}

// Disable user select on the baggage text, found this very annoying when developing
.fly-schedule .baggage .passenger .leg {
  user-select: none;
}
