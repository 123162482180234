@import "styles/variables";
@import "styles/layout/bundle";

@import "styles/layout/index";
@import "styles/common/index";

// Index pages
@import "styles/index/about";
@import "styles/index/contact";

@import "styles/step1/index";
@import "styles/step2/index";
@import "styles/step3/index";
@import "styles/step4/index";
@import "styles/booking/index";
@import "styles/front/index";

@import "styles/style";

body.scroll {
  overflow-y: scroll;
}

.w-2 {
  width: 2%;
  display: inline-block;
}

.continue-btn-group {
  width: 100%;
  font-size: 0;
}

.continue-btn {
  width: 49%;
  margin: 5px 0;
}

.continue-btn {
  cursor: pointer;
  display: inline-block;
  padding: 0;

  border: 1px solid #949494;
  background: #efcb01;
  border-radius: 3px;
}

input[type="submit"].continue-btn:hover {
  background-color: #daba01 !important;
}
