.banners_horizontal {
  padding-bottom: 15px;
}

.banners_horizontal img {
  border-radius: 5px;
}

.right_banner {
  float: right;
  padding-bottom: 15px;

  img {
    border-radius: 5px;
  }
}

.filter_banner {
  background: url("./images/site/filter__sv-SE.png") no-repeat;
  width: 278px;
  height: 358px;
  clear: both;
  border-radius: 3px;

  &.sv-SE {
    background: url("./images/site/filter__sv-SE.png") no-repeat;
  }

  &.da-DK {
    background: url("./images/site/filter__da-DK.png") no-repeat;
  }

  &.nb-NO {
    background: url("./images/site/filter__nb-NO.png") no-repeat;
  }
}

.sponsor {
  float: left;
  padding-right: 5px;
  padding-bottom: 15px;
  line-height: 40px;
  font-weight: 400;
}
