
.service {
  margin: 0;
  padding: 15px 0 0 0;
  float: left;
  width: 100%;
  text-align: center;
  .service-packages {
    border: 0px;
    h2 {
      text-align: left;
      font-size: 28px;
    }
  }
  li {
    vertical-align: top;
    padding: 1%;
    width: 100%;
    background: #FFF;
    border-radius: 3px;
    border: 1px solid #949494;
    margin-bottom: 10px;
    margin-right: auto;
    margin-left: auto;
    display: table;
  }
  .service-image {
    width: 20%;
    height: 150px;
    display: inline-block;
    float: left;
    img {
      height: 100%;
      border-radius: 10px;
    }
  }
  .service-item-content {
    .service-item-top {
      text-align: left;
      height: 80px;
      .service-item-text {
        display: inline-block;
        width: 70%;
        .service-item-header {
          padding: 8px 0px;
          padding-bottom: 14px;
          line-height: 24px;
          text-align: left;
          font-size: 120%;
          font-weight: 500;
        }
        p {
        }
      }
      .service-item-price {
        font-size: 29px;
        color: #000;
        float: right;
        font-weight: 400;
        line-height: normal;
        .smalltext {
          font-size: 14px;
          padding-left: 3px;
        }
      }
    }
    .service-item-bottom {
      clear: none;
      margin-top: 0px;
      .service-item-readmore {
        display: inline-block;
        float: left;
        margin: 10px 0px;
        padding: 12px 0px;
      }
      .service-item-personal-number {
        display: inline-block;
        vertical-align: top;
        clear: left;
        font-size: 20px;
        color: #000;
        text-align: left;
        padding: 18px 0px;
        .service-item-personal-number-header {
          display: inline-block;
          font-size: 16px;
        }
        .service-item-personal-number-input {
          display: inline-block;
          input {
            border: 1px solid #a1a1a1;
            font-size: 16px;
            padding: 5px;
          }
        }
        .service-item-personal-number-info-icon {
          display: inline-block;
        }
      }
      .service-item-address {
        display: inline-block;
        vertical-align: top;
        clear: left;
        font-size: 20px;
        color: #000;
        text-align: left;
        padding: 18px 0px;
        .service-item-address-header {
          display: inline-block;
          font-size: 16px;
        }
        .service-item-address-input {
          display: inline-block;
          input {
            border: 1px solid #a1a1a1;
            font-size: 16px;
            padding: 5px;
          }
        }
        .service-item-postal-code-input {
          display: inline-block;
          input {
            border: 1px solid #a1a1a1;
            font-size: 16px;
            padding: 5px;
          }
        }
        .service-item-city-input{
          display: inline-block;
          input {
            border: 1px solid #a1a1a1;
            font-size: 16px;
            padding: 5px;
          }
        }
        .service-item-country-code-input {
          display: inline-block;
          input {
            border: 1px solid #a1a1a1;
            font-size: 16px;
            padding: 5px;
          }
        }
        .service-item-address-info-icon {
          display: inline-block;
        }
      }
      .radio-section {
        float: right;
      }
    }
  }
}

.service-item-button123123 {
  padding: 0 5px;
  &.service-item-button-yes input {
    background: #59e027;
    width: 120px;
  }
}
