
.custom-checkbox-conditions,
.custom-checkbox-newsletter,
.custom-checkbox-integritetspolicy,
.custom-checkbox-danish {
  width: 40px;
  height: 40px;
  display: inline-block;
  position: relative;
  z-index: 1;
  top: 3px;
  cursor: pointer;
}

.custom-checkbox-conditions,
.custom-checkbox-error,
.custom-checkbox-newsletter,
.custom-checkbox-integritetspolicy,
.custom-checkbox-danish {
  input[type="checkbox"] {
    margin: 0;
    position: absolute;
    z-index: 2;
    cursor: pointer;
    outline: none;
    opacity: 0;
    /* CSS hacks for older browsers */
    _noFocusLine: expression(this.hideFocus=true);
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
    -khtml-opacity: 0;
    -moz-opacity: 0;
  }
}

.custom-checkbox-conditions,
.custom-checkbox-newsletter,
.custom-checkbox-integritetspolicy,
.custom-checkbox-danish {
  background: url('./images/site/checkbox.png') no-repeat;
  &:hover {
    background: url('./images/site/checkbox-hover.png') no-repeat;
  }
  &.selected {
    background: url('./images/site/checkbox-selected.png') no-repeat;
  }
}

.custom-checkbox-error {
  background: url('./images/site/checkbox-red.png') no-repeat;
  &:hover {
    background: url('./images/site/checkbox-hover.png') no-repeat;
  }
  &.selected {
    background: url('./images/site/checkbox-selected.png') no-repeat;
  }
}

.ui-selectmenu-button {
  margin-left:5px;
}

.ui-state-active,
.ui-widget-content .ui-state-active,
.ui-widget-header .ui-state-active,
a.ui-button:active,
.ui-button:active,
.ui-state-active.ui-button:hover {
  background: #cdcdcd;
  color: #2311b2;
  border: none;
  font-family: 'Roboto Condensed', sans-serif;
  font-weight: 300;
  padding: 5px 10px;
  // height: auto;
}

.ui-menu .ui-menu-item-wrapper {
  line-height: normal;
  padding: 5px 10px;
  font-size: 14px;
}

.ui-menu-item-wrapper.ui-state-active {
  margin: 0;
}

.ui-selectmenu-menu.ui-selectmenu-open {
  max-height: 300px;
  overflow-y: scroll;
}

.passenger td {
  padding-right: 10px;
  font-size: 12px;

  &.selected-bag {
    font-weight: 400;
  }
}



.booking-mform {
  .profile {
    width: 38px;

    .ui-selectmenu-button.ui-button {
      padding: 0;

      .ui-selectmenu-text { display: none; }
      .ui-icon { right: 8px; }
    }

    &.active {
      .ui-selectmenu-button.ui-button {
        .ui-icon {
          transform: rotate(-90deg);
        }
      }
    }
  }
}

.select-box {
  position: relative;
  width: 252px;
  max-height: 38px;
  margin-top: 5px;
  display: inline-block;
  border-radius: 3px;
  border: 1px solid var(--border);
}

.select-box input {
  width: 100%;
  overflow: hidden;
  font-size: 1.1rem;
  height: 36px;
  border: .1rem solid transparent;
  outline: none;
}

.select-box ol {
  max-height: 23rem;
  width: 100%;
  list-style: none;
}

.select-box ol::-webkit-scrollbar {
  width: 0.4rem;
  height: 3rem;
}

.select-box ol li {
  display: flex;
  cursor: pointer;
  width: 300px !important;
  margin: 0px 0px 0px 5px !important;
  height: 40px;
  align-items: center;
}

.select-box ol li.hide {
  display: none;
}

.select-box ol li:hover {
  background-color: var(--select);
}

.select-box ol li .country-name {
  width: 100%;
  display: inline;
  text-align: center;
}

.select-box ol li .flag {
  display: inline-block;
  margin-left: 10px;
}

.selected-option {
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: lightgrey;
}

.selected-option div{
  width: 5rem;
  position: relative;
  padding: 0 2.3rem 0 .5rem;
  text-align: center;
  cursor: pointer;
}

.selected-option input[type=text]{
  height: 36px !important;
  border: 0px !important;
  outline: 0px !important;
}

.selected-option div::after{
    display: inline-block;
    position: absolute;
    right: .6rem;
    content: url('https://res.cloudinary.com/dzwdseno3/image/asset/v1502446105/selectlist-icon-5c110111ba7bf967f73337f69da0d4b7.png');
    top: 30%;
  }

.selected-option div.active::after {
  transform: translateY(-50%) rotate(180deg);
}

.select-box .options{
  position: absolute;
  width: 624px;
  background-color: #fff;
  border-radius: 0 0 .5rem .5rem;
  z-index: 2;
  overflow-y: scroll;
  height: 300px;
  display: none;
}

.select-box .options.active{
  display: block;
  border: 1px solid var(--border);
  padding-right: 0px;
}

.select-box .options.active ol input {
  border-bottom: 1px solid var(--border) !important;
  border-top: 0px !important;
  border-left: 0px !important;
  border-right: 1px solid var(--border) !important;
  border-radius: 0px;
}

.select-box .options::before{
  position: absolute;
  left: 1rem;
  top: -1.2rem;

  content: "";
  width: 0;
  height: 0;
  border: .6rem solid transparent;
}

#step_2_submit_btn {
  font-size: 18px;
  height: 40px;
  border: 0;
  cursor: pointer;
}

.select-box ol li:not(:last-child) {
  border-bottom: .1rem solid #eee;
}

#profile-menu {
  .ui-menu .ui-menu-item-wrapper {
    margin: 0;

    &.ui-state-active {
      font-weight: bold;
    }
  }
}

@for $i from 1 through 9 {
  #trav_#{$i}_profile-menu.ui-menu .ui-menu-item-wrapper {
    font-family: 'Roboto Condensed', sans-serif;
  }
}

#ltd_div {
  display: none;
  padding-top: 15px;
  font-size: 13px;
  font-weight: bold;
}

#ltd_value {
  font-weight: normal;
}

.step_2_spinner {
  background: #FFF;
  width: 100%;
  border-radius: 3px;
  margin: 2px 0 0 0;
  padding: 40px;
  text-align: center;
  border: 1px solid #949494;

  .title {
    color: #000;
    font-size: 30px;
    font-weight: 400;
    line-height: 35px;
    padding: 0 0 30px 0;

    span {
      font-size: 22px;
      display: block;
      padding: 10px 0 0 0;
    }
  }
}
