@import "shared/title";
@import "step1/filter";
@import "step1/hotel";
@import "step1/itinerary";
@import "step1/price_calculation";

div.fancyclose a.fancybox-close, div.fancybox-type-image a.fancybox-close {
  display: inherit;
}

#error_screen {
  width: auto;
}

#error_screen a.close-btn {
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  font-size: 18px;
  line-height: 40px;
  padding: 0 10px;
  float: right;
  margin: 15px 0 0 0;
}

.ui-slider-handle,
.ui-slider-range {
  background: #ECCC00 !important;
}

.timer-button {
  color: #2311B2;
  background-color: #EFCB01;
  border: 1px solid #949494;
  border-radius: 3px;
}

.timeout-spinner {
  display: none;
  margin-top: 10px;
  margin-left: auto;
  margin-right: auto;
}
