//
// This is used on step 5
//

.ResursBank {
  table.priceTable {
    width: 100%;
    margin: 20px 0px;
    border-collapse: collapse;
    th,
    td {
      border: 1px solid #ccc;
      padding: 10px;
    }
  }
  p {
    margin: 10px 0px;
  }
}

// TODO: This is the price popup
.ResursBank {
  width: 800px !important;
  p {
    font-size: 14px;
    padding: 5px 0px;
  }
  span.headText {
    font-weight: bold;
  }
  table.priceTable {
    width: 100%;
    background-color: #ececec;
    border: 1px solid #d6d6d6;
    tr {
      td.header {
        padding: 5px;
      }
      td.content {
        padding: 5px;
        text-align: right;
      }
    }
    tr.evenRow {
      background-color: #e0e0e0;
    }
    tr.oddRow {}
  }
  span.tailText {}
}
