.ui-datepicker {
  width: 240px;
  padding: 0 2.5px;
  display: none;
  background: #FFFFFF;
  border: 1px solid #a2a2a2;
  font-size: 18px;
  color: #000;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  font-weight: 400;
  z-index: 100 !important;
}
.ui-datepicker-inline {
  width: 100%;
}
.ui-datepicker .ui-datepicker-header {
  position: relative;
  padding: .2em 0;
}
.ui-datepicker .ui-datepicker-prev, .ui-datepicker .ui-datepicker-next {
  position: absolute;
  width: 30px;
  height: 30px;
  cursor: pointer;
}
.ui-datepicker .ui-datepicker-prev-hover, .ui-datepicker .ui-datepicker-next-hover {
  background: #d4d4d4;
}
.ui-datepicker .ui-datepicker-prev {
  left: 2px;
  -webkit-border-radius: 3px 0 0 3px;
  -moz-border-radius: 3px 0 0 3px;
  border-radius: 3px 0 0 3px;
}
.ui-datepicker .ui-datepicker-next {
  right: 2px;
  -webkit-border-radius: 0px 3px 3px 0px;
  -moz-border-radius: 0px 3px 3px 0px;
  border-radius: 0px 3px 3px 0px;
}
.ui-datepicker .ui-datepicker-prev-hover {}
.ui-datepicker .ui-datepicker-next-hover {}
.ui-datepicker .ui-datepicker-prev span, .ui-datepicker .ui-datepicker-next span {
  display: block;
  position: absolute;
  text-indent: -99999px;
  width: 8px;
  height: 15px;
}
.ui-datepicker .ui-datepicker-prev span {
  margin: 8px 0px 0 10px
}
.ui-datepicker .ui-datepicker-next span {
  margin: 8px 0px 0 12px
}
.ui-datepicker .ui-datepicker-title {
  margin: 0 2em;
  line-height: 1.8em;
  text-align: center;
}
.ui-datepicker .ui-datepicker-title select {}
.ui-datepicker .ui-datepicker-title select {
  background: transparent;
  width: 60px !important;
  padding: 4px;
  font-size: 16px;
  line-height: normal;
  font-weight: 400;
  border: 0;
  border-radius: 0;
  height: 30px;
  -webkit-appearance: none;
}
.ui-datepicker .ui-datepicker-title div {
  width: 74px !important;
  height: 30px;
  overflow: hidden;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  float: left;
  margin: 0 3px;
}
.ui-datepicker select.ui-datepicker-month-year {
  width: 100%;
}
.ui-datepicker select.ui-datepicker-month, .ui-datepicker select.ui-datepicker-year {
  width: 49%;
}
.ui-datepicker table {
  width: 100%;
  border-collapse: collapse;
  margin: 0 0 2.5px 0;
}
.ui-datepicker th {
  padding: .2em .3em;
  text-align: center;
  border: 0;
}
.ui-datepicker td {
  border: 0;
  padding: 2.5px;
}
.ui-datepicker-trigger {
  position: absolute;
  margin: 10px 0 0 -30px
}
.ui-datepicker td span, .ui-datepicker td a {
  display: block;
  padding: .2em;
  text-align: center;
  text-decoration: none;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}
.ui-datepicker td span, .ui-datepicker td a:hover {
  background: #d4d4d4;
}
.ui-datepicker td span, .ui-datepicker td a.ui-state-highlight {
  color: #FFF;
}
.ui-datepicker .ui-datepicker-unselectable .ui-state-default {
  background: #c3c3c3;
}
.ui-datepicker .ui-datepicker-buttonpane {
  background-image: none;
  margin: .7em 0 0 0;
  padding: 0 .2em;
  border-left: 0;
  border-right: 0;
  border-bottom: 0;
}
.ui-datepicker .ui-datepicker-buttonpane button {
  float: right;
  margin: .5em .2em .4em;
  cursor: pointer;
  padding: .2em .6em .3em .6em;
  width: auto;
  overflow: visible;
}
.ui-datepicker .ui-datepicker-buttonpane button.ui-datepicker-current {
  float: left;
}

/* with multiple calendars */

.ui-datepicker.ui-datepicker-multi {
  width: auto;
}
.ui-datepicker-multi .ui-datepicker-group {
  float: left;
}
.ui-datepicker-multi .ui-datepicker-group table {
  width: 95%;
  margin: 0 auto .4em;
}
.ui-datepicker-multi-2 .ui-datepicker-group {
  width: 50%;
}
.ui-datepicker-multi-3 .ui-datepicker-group {
  width: 33.3%;
}
.ui-datepicker-multi-4 .ui-datepicker-group {
  width: 25%;
}
.ui-datepicker-multi .ui-datepicker-group-last .ui-datepicker-header {
  border-left-width: 0;
}
.ui-datepicker-multi .ui-datepicker-group-middle .ui-datepicker-header {
  border-left-width: 0;
}
.ui-datepicker-multi .ui-datepicker-buttonpane {
  clear: left;
}
.ui-datepicker-row-break {
  clear: both;
  width: 100%;
  font-size: 0em;
}

/* RTL support */

.ui-datepicker-rtl {
  direction: rtl;
}
.ui-datepicker-rtl .ui-datepicker-prev {
  right: 2px;
  left: auto;
}
.ui-datepicker-rtl .ui-datepicker-next {
  left: 2px;
  right: auto;
}
.ui-datepicker-rtl .ui-datepicker-prev:hover {
  right: 1px;
  left: auto;
}
.ui-datepicker-rtl .ui-datepicker-next:hover {
  left: 1px;
  right: auto;
}
.ui-datepicker-rtl .ui-datepicker-buttonpane {
  clear: right;
}
.ui-datepicker-rtl .ui-datepicker-buttonpane button {
  float: left;
}
.ui-datepicker-rtl .ui-datepicker-buttonpane button.ui-datepicker-current {
  float: right;
}
.ui-datepicker-rtl .ui-datepicker-group {
  float: right;
}
.ui-datepicker-rtl .ui-datepicker-group-last .ui-datepicker-header {
  border-right-width: 0;
  border-left-width: 1px;
}
.ui-datepicker-rtl .ui-datepicker-group-middle .ui-datepicker-header {
  border-right-width: 0;
  border-left-width: 1px;
}

/* IE6 IFRAME FIX (taken from datepicker 1.5.3 */

.ui-datepicker-cover {
  display: none;
  /*sorry for IE5*/
  display/**/
  : block;
  /*sorry for IE5*/
  position: absolute;
  /*must have*/
  z-index: -1;
  /*must have*/
  filter: mask();
  /*must have*/
  top: -4px;
  /*must have*/
  left: -4px;
  /*must have*/
  width: 200px;
  /*must have*/
  height: 200px;
  /*must have*/
}
.datepicker1 {
  cursor: pointer
}
